import styled from "styled-components";
import Container from "react-bootstrap/Container";


export const StyledMenu = styled(Container)`
  background: #f7f9fc;

  .navbar {
    padding: 0;
  }
  ul li {
    padding: 2px 10px;
    cursor: pointer;
    font-size: 16px;
  }
  ul li a {
    color: rgba(53, 64, 82, 0.6) !important;
  }
  .isOn {
    background: #ff3370;
    color: #9da9bd !important;
  }
  .active {
    background: #ff3370;
    font-weight: bold;
  }
  .navbar-text a {
    color: #9da9bd !important;
    padding: 0;
    margin-right: 35px;
  }
  button {
    border: none !important;
    background: transparent !important;
    &:active {
      color: green !important;
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

