import React from "react";
import { connect } from "react-redux";
import { StyledContainerTT } from "./styled";
import google from "src/assets/img/google.svg";

function GoogleTrends(props) {
  return (
    <StyledContainerTT>
      <div className="wrapper">
        <h1>
         
           <img src={google} width="22" alt="logo-google" /> 
          {props.data.name}
        </h1>
        <p className="items" >
          {props.data.links?.map((el, index) => (
            <a href={el.url} target="_blank" key={index}>
              <span style={{display: "block"}}>{el.text}</span>
            </a> 
            )
          ).slice(0, 10)}
        </p>
      </div>
    </StyledContainerTT>
  );
}

const mapStateToProps = (state) => {
  return {
    monitoreo: state.monitoreo,
    monitoreo_posta: state.monitoreo_posta,
    loading: state.loading,
    login: state.login,
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleTrends);
