import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// Redux
import { connect } from "react-redux";

// Components
import LoadingCard from "src/components/LoadingCard";
import NoEncontrado from "src/components/no-encontrado";
import Publication from "../Publication";

// Styles
import { StyledContainerCard } from "./styled";
import "./style.css";

function CardPublicationsList(props) {

    const [data, setData] = useState({});
    const cardId = useParams().id;

    useEffect(() => {
        if (
            props.id_registro_data.results &&
            props.id_registro_data.results.length >= 0
        ) {
            setData(props.id_registro_data);
        } else {
            setData({});
        }
    }, [props.id_registro_data]);

     const publicationsToRender = props.sortedPublications ? props.sortedPublications : data.results;
    return (
        <div>
            <StyledContainerCard>
                {
                    // If no data yet, show loading
                    cardId === props.cardSelected?.id || publicationsToRender == null || publicationsToRender == undefined || props.loading ?
                    (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <LoadingCard />
                        </div>
                    ) : // If no results, show corresponding message
                    publicationsToRender.length === 0 ?
                    (
                        <NoEncontrado />
                    ) : (
                        // Else, show cards
                        publicationsToRender.map((el, index) => (
                            <Publication el={el} index={index} key={index} isSaved={true} cardId={cardId}/>
                        ))
                    )
                }
            </StyledContainerCard>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        monitoreo: state.monitoreo,
        loading: state.loading,
        id_registro: state.id_registro,
        id_registro_data: state.id_registro_data,
        cardSelected: state.monitore__card_selected
    };
};


export default connect(mapStateToProps)(CardPublicationsList);
