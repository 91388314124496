import React, { useEffect, useState } from 'react';
import TwitterTrends from "../TwitterTrends/index";
import GoogleTrends from "../GoogleTrends/index";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { getMonitoreoTrendings } from "src/store";

function WrapperTrends(props){

  // Trendings
  useEffect(() => {
    if (!props.trendings || props.trendings.length === 0)
      props.getMonitoreoTrendings();
  }, [props.login]);

  return (
    <>
   {props.trendings?.map((el, index) => {
      if (el.name === "Twitter trends") {
        return <Col lg={5} key={index}><TwitterTrends data={el} /></Col>;
      } else if (el.name === "Google trends") {
        return <Col lg={{ span: 5, offset: 1 }} key={index}><GoogleTrends data={el} /></Col>;
      } else {
        return null;
      }
    })}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    trendings: state.trendings
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    getMonitoreoTrendings: () => dispatch(getMonitoreoTrendings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WrapperTrends);