import React, { useEffect, useState } from "react";

// Redux
import { connect } from "react-redux";
import {
    monitoreoToggleForm,
    monitoreoCreateNewcard,
    resetRegistroStatus,
    monitoreoSetLoadPublicationCardSelectedID,
    patchEditar,
    postMonitoreoNuevoEvento,
} from "src/store";

// Bootstrap and styles
import { Row, Col, Form } from "react-bootstrap";
import {
    StyledForm,
    StyledFixedSidePanelBackground,
    StyledFixedSidePanel,
} from "../styled";
import "../style.css";
import { MdClose } from "react-icons/md";

// Components
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import FormErrorMessage from "../FormErrorMessage";
import FormSuccessMessage from "../FormSuccessMessage";

const FormEditCard = (props) => {
    const [updatingCard, setUpdatingCard] = useState(false);
    const cardId = useParams().id;

    // Form handling
    const defaultInputs = { topics: [], subtopics: {} };
    const [inputs, setInputs] = useState(defaultInputs);
    const [formMessages, setFormMessages] = useState({});

    // Execute on init to load base inputs
    useEffect(() => {
        if (props.cardData.id == cardId) {
            var topics = [];
            var topic_keys_added = [];
            var subtopics = {};
            
            // Parse subtopics and topics to load data properly
            props.cardData.subtopics.forEach((subtopic) => {
                var topic = subtopic.topics[0];
                if (!topic_keys_added.includes(topic.id)) {
                    topics.push(topic);
                    topic_keys_added.push(topic.id);
                    subtopics[topic.id] = [];
                }
                subtopics[topic.id].push(subtopic);
            });

            var selectedEvent = props.availableEvents.find(event => event.value === props.cardData.event);

            const newInputs = {
                title: props.cardData.name,
                description: props.cardData.description,
                topics: topics,
                subtopics: subtopics,
                event: selectedEvent,
                status: props.cardData.status,
                article_link: props.cardData.article_link,
                article_title: props.cardData.article_title,
                extra_info: props.cardData.extra_info,
                refers_to_another_country: props.cardData.refers_to_another_country,
                is_public_discourse_or_phrase: props.cardData.is_public_discourse_or_phrase,
            };

            setInputs({...newInputs});
        }
    }, [props.cardData]);

    // When normal fields change, update Inputs state
    function handleFormChange(event) {
        var target_name = event.target.name;
        var value = event.target.value;

        if (event.target.type == "checkbox")
            value = event.target.checked;

        setInputs((values) => ({ ...values,  [target_name]: value }));

        // Let parent know there was a change
        props.onChangeInForm(true);
    }

    // For select fields we run a special version,
    // as the name comes in a separate parameter
    function handleSelectChange(event, action) {
        var target_name = action.name;
        var value = event;
        setInputs((values) => ({ ...values, [target_name]: value }));

        // Let parent know there was a change
        props.onChangeInForm(true);

        if (target_name == "topics"){
            // Check that all subtopics that don't have a topic are deleted
            let subtopics = { ...inputs.subtopics }; //create a new copy
            for (var subtopic in subtopics){
                if (subtopic != inputs.topics[0]?.id && subtopic != inputs.topics[1]?.id){
                    delete subtopics[subtopic];
                }    
            }

            // Update subtopics
            setInputs(values => ({ ...values, ["subtopics"]: subtopics }));
        }
    }

    // Special version for subtopics
    function handleSubtopicsSelectChange(event, action) {
        var topic_id = action.name;

        // Create new "bar" object, cloning existing bar into new bar 
        // and updating test key with value "123"
        let subtopics = { ...inputs.subtopics }; //create a new copy
        subtopics[topic_id] = event;
        
        setInputs(values => ({ ...values, ["subtopics"]: subtopics }))

        // Let parent know there was a change
        props.onChangeInForm(true);
    }

    // Handling topics and subtopics, to show subtopics only when proper topic
    const [selectedTopic1, setSelectedTopic1] = React.useState();
    const [selectedTopic2, setSelectedTopic2] = React.useState();

    const statusOptions = [
        { value: "pending", label: "Falta chequear" },
        { value: "in_progress", label: "En proceso de chequeo" },
        { value: "done", label: "Chequeado" },
        { value: "cancelled", label: "No se chequea" }
    ];

    // According to the selected topic, 
    // render corresponding subtopic select
    useEffect(() => {
        if (inputs.topics) {
            setSelectedTopic1(null);
            setSelectedTopic2(null);

            if (inputs.topics.length >= 1)
                setSelectedTopic1(inputs.topics[0]);

            if (inputs.topics.length === 2)
                setSelectedTopic2(inputs.topics[1]);

        }
    }, [inputs.topics]);

    // Subtopics dinamic component
    function SubtopicsSelect(topic) {
        if (topic !== null && topic !== undefined) {
            // Create empty list if it doesn't exist in inputs
            if (!inputs.subtopics[topic.id]) inputs.subtopics[topic.id] = [];
            
            return (
                <div className="subtopics-select">
                    <Form.Label>
                        Subtemas - {topic.name}
                        <span className="mandatory-indicator"> *</span>
                    </Form.Label>
                    <Select
                        className="select-container"
                        classNamePrefix="select-container"
                        placeholder="Seleccioná 1 o 2 submtemas"
                        options={props.availableSubtopics[topic.id]}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        name={topic.id}
                        value={inputs.subtopics[topic.id] || []}
                        onChange={handleSubtopicsSelectChange}
                        isOptionDisabled={() => inputs.subtopics[topic.id]?.length >= 2}
                        isMulti
                        maxMenuHeight="200px"
                    />
                </div>
            );
        }

        return null;
    }

    // Close this panel
    const closeForm = () => {
        props.toggleForm(false, props.id, 0);
    };

    // This function checks if all inputs are correct and form is valid
    function handleValidation() {
        let errors = {};
        let formIsValid = true;
        
        // Title
        if (!inputs["title"] || inputs["title"].length === 0){
            formIsValid = false;
            errors["title"] = "Campo obligatorio.";
        }


        if (inputs.status.value === "done"){
            if (!inputs.article_link || inputs.article_link.length === 0){
                formIsValid = false;
                errors["article_link"] = "Campo obligatorio.";
            }
            
            if (!inputs.article_title || inputs.article_title.length === 0){
                formIsValid = false;
                errors["article_title"] = "Campo obligatorio.";
            }
        }

        // Topics and subtopics
        if (!inputs["topics"]) {
            formIsValid = false;
            errors["topics"] = "Selecciona al menos un Tema.";
        } else if (inputs["topics"].length > 2){
            formIsValid = false;
            errors["topics"] = "El máximo es de dos temas.";
        } else if (inputs["topics"].length === 0){
            formIsValid = false;
            errors["topics"] = "Selecciona al menos un Tema.";
        } else {
            for(var topic in inputs["subtopics"]){
                var subtopic = inputs["subtopics"][topic];

                if (!subtopic || subtopic.length === 0){
                    formIsValid = false;
                    errors["subtopics"] = "Selecciona al menos un Subtema para cada Tema";
                }
            }
        }
        setFormMessages(errors);
        
        return formIsValid;
    }

    // Create new card if form is ok
    const handleCardUpdate = (e) => {
        // Prevent the browser from reloading the page
        e.preventDefault();

        if (handleValidation()) {

            // For subtopics, we need to submit a list of subtopics ID 
            // we don't care about the topic, the back handles that connection
            const subtopics_ids = [];
            for (var topic_id in inputs.topics) {
                var topic = inputs.topics[topic_id].id;
                var subtopics = inputs.subtopics[topic];
                for (var subtopic in subtopics) {
                    subtopics_ids.push(subtopics[subtopic].id);
                }
            }

            let newCard = {
                name: inputs.title,
                description: inputs.description,
                event: inputs.event ? inputs.event.id : null,
                subtopics: subtopics_ids,
                status: inputs.status.value,
                article_link: inputs.article_link,
                article_title: inputs.article_title,
                extra_info : inputs.extra_info,
                refers_to_another_country: inputs.refers_to_another_country,
                is_public_discourse_or_phrase: inputs.is_public_discourse_or_phrase,
            }

            setUpdatingCard(true);
            props.patchEditar(newCard, cardId).then(() => { 
                // Let parent know there was a change
                props.onChangeInForm(false);
                setUpdatingCard(false); 
                setFormMessages(values => ({...values, ["updateSuccess"]: "Tarjeta actualizada."}));
                setTimeout(() => {
                    setFormMessages(values => ({...values, ["updateSuccess"]: null}));
                }, 1500);
            });
        } else {
            /*  alert("Invalid form!");
                console.log(formMessages); */
        }
    };

    const handleCreateEvent = (inputValue) => {
        props.postMonitoreoNuevoEvento(inputValue);
    };

    // If not creating publication, then ALWAYS show
    const showForm = () => {
        return props.openFormId !== "create_publication" || !props.openForm;
    };

    return (
        <StyledFixedSidePanel className={showForm() ? "show-form" : "hide"}>
            <StyledFixedSidePanelBackground className={ updatingCard || props.cardData.id != cardId ? "loading" : "" }>
                <Row>
                    <Col md={7}>
                        <h1>{props.cardTitle}</h1>
                    </Col>
                    <Col md={5}>
                        <button
                            onClick={handleCardUpdate}
                            className="primario"
                            style={{ float: "right" }}
                        >
                            Actualizar Tarjeta
                        </button>
                    </Col>                        
                    <FormSuccessMessage message={formMessages.updateSuccess} align={"right"}/>
                </Row>
                <Row>
                    <p className="mandatory-indicator">* Campos obligatorios</p>
                    <p> {props.cardDescription} </p>
                </Row>
                <StyledForm>
                    <Row>
                        <Form onSubmit={handleCardUpdate}>
                            <Form.Group controlId="createNewTarjetaForm.ControlInput1">
                                <Form.Label>
                                    Título <span className="mandatory-indicator">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Título descriptivo para la nueva Tarjeta"
                                    className="text-field"
                                    name="title"
                                    value={inputs.title || ""}
                                    onChange={handleFormChange}
                                />
                                <FormErrorMessage message={formMessages.title} />
                                
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Descripción del contenido de la tarjeta. Puede incluir el copy de la publicación"
                                    className="text-field"
                                    name="description"
                                    value={inputs.description || ""}
                                    onChange={handleFormChange}
                                />
                                <Form.Label className="categorias-title">
                                    Estado del chequeo
                                </Form.Label>
                                <Select
                                    id="status-select"
                                    className="select-container"
                                    classNamePrefix="select-container"
                                    placeholder="Estado del chequeo"
                                    onChange={handleSelectChange}
                                    value={inputs.status || []}
                                    name="status" // Asegúrate de agregar el nombre del campo
                                    options={statusOptions}
                                    maxMenuHeight="200px"
                                >
                                </Select>
                                {inputs.status?.value == "done" ? (
                                    <Row className="mb-3">
                                        <Form.Group as={Col}>
                                            <Form.Label>
                                                Título del chequeo{" "}
                                                <span className="mandatory-indicator">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Título del chequeo"
                                                className="text-field"
                                                name="article_title"
                                                value={inputs.article_title || ""}
                                                onChange={handleFormChange}
                                            />
                                            <FormErrorMessage message={formMessages.article_title} />

                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>
                                                Url del chequeo{" "}
                                                <span className="mandatory-indicator">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="URL del chequeo"
                                                className="text-field"
                                                name="article_link"
                                                value={inputs.article_link || ""}
                                                onChange={handleFormChange}
                                            />
                                            <FormErrorMessage message={formMessages.article_link} />

                                        </Form.Group>
                                    </Row>
                                ) : null}

                                <Form.Label>
                                    Tema <span className="mandatory-indicator">*</span>
                                </Form.Label>
                                <Select
                                    id="tema-select"
                                    className="select-container"
                                    classNamePrefix="select-container"
                                    placeholder="Seleccioná 1 o 2 temas"
                                    options={props.availableTopics}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    isMulti
                                    isOptionDisabled={() => inputs.topics.length >= 2}
                                    name="topics"
                                    value={inputs.topics || []}
                                    onChange={handleSelectChange}
                                    maxMenuHeight="200px"
                                />
                                <FormErrorMessage message={formMessages.topics} />

                                {SubtopicsSelect(selectedTopic1)}
                                {SubtopicsSelect(selectedTopic2)}
                                <FormErrorMessage message={formMessages.subtopics} />

                                <div key="inline-checkbox" className="my-3">
                                    <Form.Check
                                        inline
                                        label="Corresponde a otro/s país/es"
                                        name="refers_to_another_country"
                                        type="checkbox"
                                        id="inline-checkbox-1"
                                        checked={inputs.refers_to_another_country || false}
                                        onChange={handleFormChange}
                                    />
                                    <Form.Check
                                        inline
                                        label="Es discurso público o frase"
                                        name="is_public_discourse_or_phrase"
                                        type="checkbox"
                                        id="inline-checkbox-2"
                                        checked={inputs.is_public_discourse_or_phrase || false}
                                        onChange={handleFormChange}
                                    />
                                </div>
                                
                                <Form.Label id="event-label">Evento</Form.Label>
                                <CreatableSelect id="event-select"
                                    onCreateOption={handleCreateEvent}
                                    className="select-container"
                                    classNamePrefix="select-container"
                                    placeholder="Seleccioná un event o crea uno nuevo"
                                    options={props.availableEvents}
                                    name="event"
                                    value={inputs.event || ""}
                                    onChange={handleSelectChange}
                                    maxMenuHeight="200px"
                                    isClearable
                                />

                                <Form.Label>Información extra</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Observaciones, links de referencia, fuentes, expertos o cualquier otra información que sirva para profundizar sobre esta tema"
                                    className="text-field"
                                    name="extra_info"
                                    value={inputs.extra_info || ""}
                                    onChange={handleFormChange}
                                />
                            </Form.Group>
                            <button type="submit" className="primario">
                                Actualizar Tarjeta
                            </button>
                            <FormSuccessMessage message={formMessages.updateSuccess} />
                        </Form>
                    </Row>
                </StyledForm>
            </StyledFixedSidePanelBackground>
        </StyledFixedSidePanel>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        monitoreo: state.monitoreo,
        openForm: state.monitoreo__current_form_is_open,
        openFormId: state.monitoreo__current_form_id,
        idOfPublicationToSave: state.monitoreo__current_form_publication_id,
        registro_status: state.registro_status,
        newCardId: state.nuevo_registro,
        cardTitle: ownProps.cardTitle,
        cardDescription: ownProps.cardDescription,
        cardData: state.monitoreo__card_selected,
        availableEvents: state.monitoreo__events,
        availableSubtopics: state.monitoreo__subtopics,
        availableTopics: state.monitoreo__topics,
        id: ownProps.id,
        onChangeInForm: ownProps.onChangeInForm
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleForm: (openForm, openFormId, publication_id) =>
            dispatch(monitoreoToggleForm(openForm, openFormId, publication_id)),
        monitoreoCreateNewcard: (newCardContent) =>
            dispatch(monitoreoCreateNewcard(newCardContent)),
        resetRegistroStatus: () => dispatch(resetRegistroStatus()),
        setLoadPublicationCardSelectedID: (cardSelectId) =>
            dispatch(monitoreoSetLoadPublicationCardSelectedID(cardSelectId)),
        patchEditar: (card, id) => dispatch(patchEditar(card, id)),
        postMonitoreoNuevoEvento: (nuevoEvento) => dispatch(postMonitoreoNuevoEvento(nuevoEvento))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormEditCard);
