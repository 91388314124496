import styled from "styled-components";
import Col from "react-bootstrap/Col";

export const StyleCard = styled.div`
  font-size: 16px;
  border: 1px solid #cedaee;
  padding: 20px;
  min-height: 306px;
  margin-bottom: 10px;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  
  .modal.modal-rel {
    position: absolute;
    z-index: 51;
  }
  .frase {
    min-height: 100px;
    font-weight: bold;
    color: #354052;
  }
  .src {
    color: #354052;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 6px;
  }

  .date {
    color: #354052;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .bajada {
    margin-bottom: 45px;
  }

  .bajada a {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    min-height: 20px;
    color: #63728b;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
  }
  .bajada a:hover {
    text-decoration: none;
  }
  .bajada span {
    font-weight: bold;
    color: #354052;
    font-size: 13px;
  }
  .nombre {
    font-size: 12px;
    color: #ff3370;
    font-weight: bold;
    margin-bottom: 0px;
    @media (max-width: 1199px) {
      margin-bottom: 18px;
    }
  }
  .contexto {
    background: #e6ecf5;
    color: #354052;
    text-align: center;
    font-weight: bold;
    padding: 3px 8px;
    cursor: pointer;
  }
  .contexto p {
    font-size: 12px;
    margin-bottom: 0px;
  }
  .modalCardBtn {
  }
  .modalCard {
    // display: none;
  }
`;


export const StyledColModal = styled(Col)`
.link_nota {
  text-align: left;
  margin-bottom: 20px;
  font-weight: bold;
  color: rgb(255, 51, 112);
  font-size: 24px;
}

.link_nota:hover {
  text-decoration: none;
}

`
