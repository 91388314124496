import React, { useState, useEffect } from "react";
import { getTextos } from "../../store";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getResetPasswordComplete } from "../../store";
import { useHistory } from "react-router-dom";
import { StyledLogin } from "../styled";
import { FormNewCode } from "../styled";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import $ from "jquery";
import VentaChequeabot from "../../components/VentaChequeabot";
import robot from "src/assets/img/Capa_1.png";
import logo from "src/assets/img/logo_solo.png";
import sobre from "src/assets/img/Rectangle 48.png";

function CreatePasswordComplete(props) {
  const [analisis, setanalisis] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  const [queryFirstPass, setFirstPass] = useState("");
  const [queryConfirmPass, setConfirmPass] = useState("");
  

  const queryString = window.location.hash;

  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token')
  const token_valid = urlParams.get('token_valid')
  const uidb64 = urlParams.get('uidb64')


  useEffect(() => {
    $("#header").hide();
    return () => {
      $("#header").show();
    };
  }, []);


  useEffect(() => {
    if (props.first_password_status === "success") {
      history.push("/login");
    } else {
      if (props.first_password_status === "error") {
        setError(true);
      }
    }
  }, [props.first_password_status]);





  const handleSubmitData = (e) => {
    e.preventDefault();
    props.getResetPasswordComplete({
      password: queryFirstPass,
      confirm_password: queryConfirmPass,
      token: token,
      uidb64: uidb64
    });
  };

  const handlePasswordFisrtTime = (e) => {
    e.preventDefault();
    setFirstPass(e.target.value);
  };

  const handlePasswordConfirm = (e) => {
    e.preventDefault();
    setConfirmPass(e.target.value);
  };

  return (
    <Container fluid={true} style={{ background: "#f6f9fc" }}>
      <Row>
        <Col lg={6} style={{ paddingLeft: "0" }}>
          <VentaChequeabot login={true} />
        </Col>
        <Col lg={6}>
          <StyledLogin>
            <div className="mx-auto login-box">
              <Row>
                <Col lg={12} className="text-center">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "64px",
                        height: "64px",
                        borderRadius: "50%",
                        backgroundColor: "#354052", // Color azul
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "15px",
                        marginTop: "-23px",
                      }}
                    >
                      <img src={robot} />
                    </div>
                    <h1
                      style={{
                        fontWeight: "800",
                        fontSize: "16px",
                        lineHeight: "30px",
                      }}
                    >
                      Soporte
                    </h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  className="mx-auto mt-2"
                  lg={12}
                  style={{ color: "#354052" }}
                >
                  <form
                    onSubmit={handleSubmitData}
                    style={{ marginBottom: "16px", marginTop: "16px" }}
                  >
                    <div>
                      <p
                        className="mb-1 font-weight-bold"
                        style={{ fontSize: "12px" }}
                      >
                        Ingrese nueva contraseña
                      </p>
                      <input
                        className="form-control"
                        type="password"
                        name="pass"
                        value={queryFirstPass}
                        required
                        minLength="8"
                        onChange={handlePasswordFisrtTime}
                      />
                    </div>

                    <div style={{ marginTop: "20px" }}>
                      <p
                        className="mb-1 font-weight-bold"
                        style={{ fontSize: "12px" }}
                      >
                        Confirme nueva contraseña
                      </p>
                      <input
                        className="form-control"
                        type="password"
                        name="pass"
                        value={queryConfirmPass}
                        required
                        minLength="8"
                        onChange={handlePasswordConfirm}
                      />
                    </div>
                    {error ? (
                      <Row>
                        <Col lg={12}>
                          <p
                            style={{
                              padding: "26px 5px",
                              color: "red",
                              borderRadius: "5px",
                            }}
                          >
                            La contraseñas ingresadas no son iguales.
                          </p>
                        </Col>
                      </Row>
                    ) : null}

                    <div
                      className="mt-4"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        type="submit"
                        value="Registrar"
                        className="enter"
                        id="login-btn"
                      >
                        Restablecer
                      </button>
                    </div>
                    <hr />
                  </form>

                  <p className="mt-4 text-center">
                    ¿Aún tienes probemas para iniciar seisón?
                    <br />
                    Comunicate a{" "}
                    <a href="mailto:innovacion@chequeado.com">
                      innovacion@chequeado.com
                    </a>
                  </p>
                </Col>
              </Row>
            </div>
            <Row>
              <Col
                lg={12}
                className="text-center small-text"
                style={{ marginTop: "40px" }}
              >
                <p>Chequeabot</p>
                <p> App version V1.7.0 Chequeado</p>
                <img src={logo} style={{ width: "100px", marginTop: "10px" }} />
              </Col>
            </Row>
          </StyledLogin>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    textos: state.textos,
    code_new_user: state.code_new_user,
    first_password_status: state.first_password_status,
    reset_password_complete: state.reset_password_complete,
    reset_password_complete_status: state.reset_password_complete_status
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getResetPasswordComplete: (data) => dispatch(getResetPasswordComplete(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePasswordComplete);
