import React, { useState, useEffect } from "react";

// Router
import { useLocation,useHistory, Redirect, Route, useParams } from "react-router-dom";

// Bootstrap and styles
import { Col, Container, Row, Tab, Nav, TabPane } from "react-bootstrap";
import { StyledBackground } from "../styled";
import { StyledNavTabs, StyledTab } from "./styled";
import "./style.css";

// Components
import GuardadosDetail from "./GuardadosDetail";
import FeedVivo from "./FeedVivo";
import Guardados from "./Guardados";
import Logo from "src/components/Monitoreo/Logo";

// Redux
import { connect } from "react-redux";
import { getMonitoreoAllEvents, getMonitoreoAllTopics } from "src/store";

function HomeMonitoreo(props) {
  const [key, setKey] = useState("Feed en vivo");
  const [querySearch, setquerySearch] = useState("");
  const [data, setData] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const handleTabSelect = (tabIndex) => {
    setActiveTab(tabIndex);
    const currentPath = location.pathname;
    if (currentPath.includes("/periscopio/")) {
      const newPath = currentPath.split("/periscopio/")[0] + "/periscopio";
      history.push(newPath);
    }
  };

  useEffect(() => {
    if (props.login){
      props.getMonitoreoAllEvents();
      props.getMonitoreoAllTopics();
    }

  }, [props.login])

  useEffect(() => {
    if (id) {
      setActiveTab(2);
    }
  }, [id]);

  const handleFilterFrase = (e) => {
    e.preventDefault();
  };

  const handleFilterQueryRequest = (e) => {
    e.preventDefault();
    setquerySearch(e.target.value);
  };

  useEffect(() => {
    if (props.isGuardadosActive) {
      setActiveTab(2);
    }
  }, [props.isGuardadosActive]);

  const handleFeedTabClick = () => {
    setActiveTab(2);

    return <Redirect to="/periscopio" />;
  };


  const handleDonwloadFile = () => {
    console.log("hola");
  };




  
  return (
    <StyledBackground fluid>
      <Row>
        <div className="titulo">
          <Logo />
          <p>Periscopio</p>
        </div>
      </Row>
      <Row>
        <Col lg={12}>
          <StyledNavTabs
            id="controlled-tab-example"
            activeKey={activeTab}
            onSelect={handleTabSelect}
            className="mb-3"
          >
            <Tab eventKey={1} title="Feed en vivo">
              <FeedVivo
                handleTabSelect={handleTabSelect}
                activeTab={activeTab}
              />
            </Tab>
           
            {activeTab === 2 && id ? (
              <Tab eventKey={2} title="Guardados">
                <GuardadosDetail
                  id={id}
                  onFeedTabClick={handleFeedTabClick}
                />
              </Tab>
            ) : (
              <Tab eventKey={2} title="Guardados">
                <Guardados />
              </Tab>
            )}
          </StyledNavTabs>
          
        </Col>
      </Row>
    </StyledBackground>
  );
}


const mapStateToProps = (state) => {
  return {
    login: state.login,
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMonitoreoAllEvents: () => dispatch(getMonitoreoAllEvents()),
    getMonitoreoAllTopics: () => dispatch(getMonitoreoAllTopics()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeMonitoreo);