/* Homes */

import styled from "styled-components";
import { Row, Accordion, Container, Button, Card, Col } from "react-bootstrap";
import bguser from "src/assets/img/background-chatbot.png";

export const StyledTitles = styled.h3`
  margin-bottom: 25px;
  font-size: 23px;
  line-height: 33px;
  font-weight: bold;
`;

export const StyledText = styled.p`
  margin-bottom: 50px;
  a {
    color: #e63457;
  }
`;

export const StyledMedio = styled(Col)`
  margin-bottom: 50px;
  a {
    color: #e63457;
  }

  ul li {
    margin-bottom: 10px;
  }
`;

export const StyledMessage = styled.div`
  background: #f7f9fc;

  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px;
  border: 1px solid rgb(206, 212, 218);

  /* .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden'
  } */
  .card-header {
    background-color: white !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
    border-bottom: none !important;
    cursor: pointer;
  }
  p {
    font-size: 16px;
    line-height: 18px;
    color: #354052;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    // margin-top: 10px;
  }

  .contacto-fecha {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .label-check {
      font-size: 12px;
      line-height: 15px;
      color: #354052;
    }
    .form-check-label {
      margin-bottom: 0;
      vertical-align: sub;
    }
  }

  .player audio {
    width: 245px;
  }

  .ver {
    background: #e6ecf5;
    color: #354052;
    border-radius: 4px;
    border: 1px solid #e6ecf5;
    font-size: 12px;
    line-height: 15px;
    padding: 0px;
    width: 70%;
    margin-bottom: 1px;
    font-weight: bold;
    @media (max-width: 1024px) {
      font-size: 11px;
      width: 90%;
    }
  }

  .marcar {
    font-weight: bold;
    background: #e6ecf5;
    color: #ff3370;
    border-radius: 4px;
    border: 1px solid #e6ecf5;
    font-size: 12px;
    line-height: 15px;
    padding: 0px;
    width: 70%;
    @media (max-width: 1024px) {
      font-size: 11px;
      width: 90%;
    }
  }

  .card-body {
    .red {
      color: red;
    }
    .blue {
      color: blue;
    }
  }
`;

export const StyledMenu = styled(Row)`
  margin-top: 15px;
  margin-bottom: 20px;
  .col-message {
    background: #e6ecf5;
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.35px;
      color: #354052;
    }
    button {
      background: #ff3370;
      border: 1px solid #ff3370;
      border-radius: 4px;
      color: white;
      font-size: 12px;
      padding: 8px;
      font-weight: bold;
    }
  }
`;

export const CircleIcon = styled.div`
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #354052;
  padding: 4px 4px;
  margin-right: 8px;
`;

export const StyledBackground = styled(Container)`
  background: #f7f9fc;
  padding: 30px 20px;

  .titulo {
    display: flex;
    p {
      font-size: 16px;
      margin-bottom: 0;
      color: #354052;
    }
    svg {
      fill: #fff;
      margin-right: 5px;
      circle {
        fill: #354052;
      }
    }
  }
  #resultados_container {
    background: #efefef;
    padding: 14px;
    border-radius: 10px;
  }
  .frase_link {
    background: transparent;
  }
  .green {
    background: #b5e4b9;
  }
  .borrar {
    color: #f72d54;
    border: 1px solid #f72d54;
    border-radius: 10px;
    background: transparent;
    font-size: 12px;
    padding: 2px 25px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      background: #f72d54;
      color: #fff;
    }
  }
  .transcripcion {
    justify-content: space-between;
    align-items: center;
    display: flex;
    img {
      margin-right: 8px;
      width: 23px;
    }
    .btn-copy {
      color: #000;
      border: 1px solid #354052;
      border-radius: 20px;
      background: #fff;
      font-size: 12px;
      padding: 2px 25px;
      font-weight: bold;
      cursor: pointer;
      margin-bottom: 8px;
      &:hover {
        background: #354052;
        border: 1px solid #354052;
        color: #fff;
      }
    }
    .select-idioma {
      display: flex;
      justify-content: center;
      align-items: baseline;
      padding-bottom: 20px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #000;
    }
  }
  h5 {
    letter-spacing: 0px;
    display: block;
    a {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #000;
      text-decoration: none;
      &:hover {
        color: #f72d54;
      }
    }
  }
  .btn-reset {
    border: 1px solid #000;
    border-radius: 20px;
    color: #000;
    background: 0 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    padding: 3px 18px;
    svg {
      fill: #000;
      margin-right: 5px;
    }
    &:hover {
      border: 1px solid #fff;
      color: #fff;
      background: #354052;
      svg {
        fill: #fff;
      }
    }
  }
  .cartel-vacio {
    margin-top: 45px;
    text-align: center;
    padding-bottom: 3rem;
    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 0;
      text-align: center;
      color: #7f7f7f;
      margin-top: 27px;
    }
  }
  .file-upload-container {
    padding: 6px 8px;
    background-image: radial-gradient(
        circle at 1px,
        #000 1px,
        rgba(255, 255, 255, 0) 1px
      ),
      radial-gradient(circle, #000 1px, rgba(255, 255, 255, 0) 1px),
      radial-gradient(circle at 1px, #000 1px, rgba(255, 255, 255, 0) 1px),
      radial-gradient(circle, #000 1px, rgba(255, 255, 255, 0) 1px);
    background-position: top, right, bottom, left;
    background-size: 5px 5px, 5px 5px;
    background-repeat: repeat-x, repeat-y;
    border-radius: 10px;
    color: #000;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .file-label .hide {
    display: none;
  }

  .custom-file-button:hover {
    /* Agrega estilos de hover si lo deseas */
  }

  .custom-file-button:focus {
    outline: none; /* Elimina el borde al enfocar el botón */
  }

  .custom-file-button:active {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`;

export const StyledLogin = styled.div`
  p {
    font-size: 10px;
  }

  .small-text p {
    font-size: 10px;
    margin-bottom: 0;
  }

  .login-box {
    width: 370px;
    border: 1px solid #efefef;
    border-radius: 10px;
    padding: 20px 30px;
    padding-top: 0;
    margin-top: 60px;
    background: white;
  }
`;

export const StyledMessageResponse = styled(Row)`
  /*  padding-top: 20px; */
  p {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0px;
    color: #354052;
  }
  .chat-container-response {
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 7px;
    padding-right: 7px;
    background: #e6ecf5;
    border: 1px solid #cedaee;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .chat-container {
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 7px;
    padding-right: 7px;
    border: 1px solid #cedaee;
    box-sizing: border-box;
    border-radius: 4px;
    max-width: 400px;
    width: 400px;

    hr {
      margin-top: 8px;
      margin-bottom: 8px;
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    button {
      background: #e6ecf5;
      border: 1px solid #e6ecf5;
      border-radius: 4px;
      color: #ff3370;
      font-size: 12px;
      padding: 8px;
      font-weight: bold;
    }

    .label-check {
      font-size: 12px;
      line-height: 15px;

      color: #354052;
    }
    .form-check-label {
      vertical-align: sub;
    }
    .player audio {
      width: 260px;
    }
  }
`;

export const StyledUserPanel = styled(Row)`
  .izq-panel {
    max-height: 1000px;
    height: 1000px;
    background: #f3f3f3;
    font-size: 18px;
    .nav-pills .nav-link {
      color: #000 !important;
      background-color: transparent !important;
      font-size: 16px;
    }
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      color: #ff3370 !important;
      background-color: transparent !important;
      text-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
    h3 {
      padding-top: 50px;
      font-size: 16px;
      font-weight: bold;
      padding-left: 20px;
      padding-bottom: 14px;
    }
    .menu {
      margin-bottom: 2px;
      padding-left: 40px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .der-panel {
    /* background-color: white;
    background-image: url(${bguser});
    background-repeat: repeat;
    background-size: cover; */
    padding-top: 14px;
    padding-left: 50px;
    h2 span {
      color: #354052;
      font-size: 20px;
      line-height: 24px;
    }
    label {
      font-size: 16px;
      line-height: 30px;
      color: #354052;
      font-weight: bold;
    }
    input {
      &::placeholder {
        color: #354052 !important;
        font-style: initial;
      }
    }

    .italic {
      &::placeholder {
        color: grey !important;
        font-style: italic;
      }
    }
    h3 {
      font-size: 16px;
      line-height: 20px;
      color: #354052;
      font-weight: bold;
      margin-bottom: 11px;
    }
    ul {
      font-size: 16px;
      line-height: 38px;
      list-style: none;
      padding: 0px;
      li p {
        font-size: 16px;
        line-height: 20px;
        color: #354052;
        margin-bottom: 0px;
      }
      li span {
        font-size: 16px;
        line-height: 20px;
        color: #354052;
        font-weight: bold;
      }
    }
    .notificaciones {
      /* background: #e6ecf5;
      border: 1px solid #354052;
      padding: 20px; */
      color: #354052;
      font-size: 16px;
      margin-bottom: 40px;
      label {
        font-size: 14px;
      }
    }
    .btn-cancelar {
      background: #ffffff;
      border: 1px solid #354052;
      box-sizing: border-box;
      border-radius: 5px;
      color: #354052;
      font-size: 16px;
      line-height: 20px;
      padding: 13px 25px;
    }
    .btn-guardar {
      background: #354052;
      border: 1px solid #354052;
      box-sizing: border-box;
      border-radius: 5px;
      color: #fff;
      font-size: 16px;
      line-height: 20px;
      padding: 13px 25px;
      margin-right: 20px;
    }
  }
`;

export const StyledMessageAccordion = styled(Accordion)`
  .card-header {
    background: transparent !important;
    border-bottom: none !important;
  }
  .resetpass {
    font-size: 16px;
    line-height: 20px;
    color: #354052;
  }
  .form-contact {
    border: 1px solid #dee2e6;
    padding: 20px;
    background: white;
    border-radius: 10px;
    p {
      font-size: 16px;
      line-height: 20px;
      color: #354052;
    }
    label {
      font-size: 16px;
      line-height: 20px;
      color: #354052;
      font-weight: bold;
    }
    .btn-cancelar-form {
      background: #ffffff;
      border: 1px solid #354052;
      box-sizing: border-box;
      border-radius: 5px;
      color: #354052;
      font-size: 16px;
      line-height: 20px;
      padding: 13px 25px;
      @media (max-width: 1059px) {
        margin-right: 0px;
        margin-bottom: 5px;
      }
    }
    .btn-guardar-form {
      background: #354052;
      border-radius: 5px;
      color: white;
      font-size: 16px;
      line-height: 20px;
      padding: 13px 25px;
      margin-left: 20px;
    }
  }
`;

export const StyledDropdown = styled.div`
  .navbar-test {
    overflow: hidden;
    background-color: red;
    font-family: Arial, Helvetica, sans-serif;
  }

  .dropdown {
    overflow: hidden;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 999999999;
  }

  .show {
    display: block;
    list-style: none;
    padding: 0px;
  }

  .dropdown .dropbtn {
    cursor: pointer;
    font-size: 15px;
    border: none;
    outline: none;
    font-weight: bold;
    color: #354052;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }
  .rotate {
    transform: rotate(180deg);
    transition: all 0.2s;
  }
`;

export const FormNewCode = styled(Container)`
  form {
    margin-top: 100px;
    margin-bottom: 25px;
    background: white;
    border: 1px solid #ccc;
    padding: 22px;
    label {
      font-size: 16px;
      line-height: 20px;
      color: #354052;
    }
    .btn-guardar {
      background: #354052;
      border-radius: 5px;
      color: white;
      font-size: 16px;
      line-height: 20px;
      padding: 13px 25px;
    }
  }
`;

export const StyledBtn = styled(Button)`
    color: #9DA9BD ;
    background-color:#ff3370 !important;
    border: none;
    border-radius: 0;
    font-weight: normal;
    font-size: 18px;
    border-radius: 12px;
    cursor: default!important;

  a {
    color:  #FFF ;
    font-weight:bold;
    padding-bottom: 6px;
    font-size: 16px;
    @media (max-width: 1024px) {
      font-size: 13px;
    }
  }
  a:hover {
    text-decoration: none;
    color: #fff;
  }

  &:focus {
    box-shadow: none !important;
  }

  @media (max-width: 1024px) {
    color: #9DA9BD !important;
    background-color: #252C39!important;
    border: 0!important;
    font-size: 14px;
  }
}
`;

export const StyledContainerApp = styled(Container)`
  .appministrador-table {
    display: flex;
    justify-content: space-around;
    padding-top: 30px;
    p {
      display: flex;
      align-items: center;
      background: #e6ecf5;
      border-radius: 4px;
      padding: 5px 20px;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.35px;
      color: #354052;
    }
  }
`;

export const StyleCard = styled(Card)`
  /* height:100px;
  max-height: 100px; */
  border: none !important;
  .resuelto {
    border-left: 5px solid #cedaee;
  }

  .no-resuelto {
    border-left: 5px solid #4eea91;
  }

  .circle-full {
    width: 15px;
    height: 15px;
    line-height: 15px;
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
    text-align: center;
    background: #ff3370;
    margin-bottom: 0px;
    margin-left: 15px;
  }

  .circle-empty {
    width: 15px;
    height: 15px;
    line-height: 15px;
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
    text-align: center;
    background: grey;
    margin-bottom: 0px;
    margin-left: 15px;
  }

  .card-header {
    padding: 0px !important;
    margin: 0px !important;
    background-color: transparent !important;
  }

  .ver-anteriores {
    background: #ff3370;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #ff3370;
    border-radius: 4px;
    margin-bottom: 46px;
    color: white;
    padding: 10px;
    font-weight: bold;
    font-size: 12px;
  }

  .card-body {
    overflow-y: auto;
    height: 800px;
    .red {
      color: red;
    }
    .blue {
      color: blue;
    }
  }
`;

export const StyleCardPedidos = styled(Card)`
  #pruebaurl::placeholder {
    font-style: italic;
    opacity: 0.5;
    color: black;
  }
  #pruebaurl2::placeholder {
    font-style: italic;
    opacity: 0.5;
    color: black;
  }
  margin-bottom: 10px;
  .resuelto {
    border-left: 5px solid #4eea91;
  }

  .similar {
    border-left: 5px solid yellow;
  }

  .no-resuelto {
    border-left: 5px solid #e6ecf5;
  }
  .card-header {
    padding: 0px !important;
    margin: 0px !important;
    background-color: transparent !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .button-efc {
    background: rgb(255, 51, 112);
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    box-shadow: rgb(153 153 153) 0px 4px;
    &:hover {
      background-color: rgb(255, 51, 112);
    }

    &:active {
      background-color: rgb(255, 51, 112) !important;
      box-shadow: 0 5px #666;
      transform: translateY(4px);
    }
  }

  .title {
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledMessagePedidos = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 20px 20px 0px;
  .card-header {
    background-color: white !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
    border-bottom: none !important;
    cursor: pointer;
  }
  .item {
    text-align: center;
  }
  .item p {
    font-size: 12px;
    line-height: 15px;
    color: #354052;
    margin-top: 10px;
    margin-bottom: 0;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    margin-left: 23px;
    margin-top: 10px;
  }
  /* item p {
    font-size: 16px;
    line-height: 18px;
    color: #354052;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    margin-top: 10px;
  } */

  .item-ver {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .item-ver p {
    font-size: 12px;
    line-height: 15px;
    color: #354052;
    margin-top: 10px;
    margin-bottom: 0;
    justify-content: end;
  }

  .estadisticas {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .numberCircle {
      width: 18px;
      line-height: 15px;
      border-radius: 50%;
      text-align: center;
      font-size: 9px;
      border: 2px solid #354052;
      background: #354052;
      color: white;
      font-weight: bold;
    }
    svg {
      color: #ff3370;
      margin-left: 9px;
    }
  }

  .ver {
    background: #e6ecf5;
    color: #354052;
    border-radius: 4px;
    border: 1px solid #e6ecf5;
    font-size: 10px;
    line-height: 15px;
    padding: 8px;
    width: 100%;
    margin-bottom: 8px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .marcar {
    font-weight: bold;
    background: #e6ecf5;
    color: #ff3370;
    border-radius: 4px;
    border: 1px solid #e6ecf5;
    font-size: 10px;
    line-height: 15px;
    padding: 8px;
    width: 100%;
    text-transform: uppercase;
  }

  .card-body {
    .red {
      color: red;
    }
    .blue {
      color: blue;
    }
  }
`;

export const StyledMessageResponsePedidos = styled(Row)`
  p {
    font-size: 12px;
    line-height: 15px;

    color: #354052;
  }
  .chat-container-response {
    text-align: left;
    padding: 15px;
    background: #e6ecf5;
    border: 1px solid #cedaee;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .chat-container {
    text-align: left;
    padding: 15px;
    border: 1px solid #cedaee;
    box-sizing: border-box;
    border-radius: 4px;

    button {
      background: #e6ecf5;
      border: 1px solid #e6ecf5;
      border-radius: 4px;
      color: #ff3370;
      font-size: 12px;
      padding: 8px;
      font-weight: bold;
    }

    .label-check {
      font-size: 12px;
      line-height: 15px;

      color: #354052;
    }
    .form-check-label {
      vertical-align: sub;
    }
  }
`;

export const StyledSimilaresEncontrados = styled(Row)`
  .title {
    display: flex;
    align-items: center;
    background: #e6ecf5;
    border-radius: 4px;
    padding: 5px 20px;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #354052;
    margin-top: 35px;
    padding: 17px;
  }
`;

export const StyledHome = styled(Container)`
  background: #f7f9fc;
`;
