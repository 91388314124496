import React from "react";

export default function Logo({ fillColor, backgroundColor }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
            width="24"
            height="24"
            fill={fillColor}
        >
            <circle cx="100" cy="100" r="100" fill={backgroundColor} />
            <path
                className="cls-1"
                d="m160.6,109.94v-12.15c0-33.46-27.13-60.59-60.59-60.59h0c-33.46,0-60.59,27.13-60.59,60.59v12.14c-6.31,1.22-11.08,6.77-11.08,13.43v16.91c0,7.56,6.13,13.69,13.69,13.69h2.81c2.52,0,4.55-2.04,4.55-4.55v-34.56h.03v-16.22c0-27.7,21.92-50.9,49.61-51.43,28.34-.54,51.57,22.36,51.57,50.58v41.18h.01v10.44c0,2.52,2.04,4.55,4.55,4.55h2.81c7.56,0,13.69-6.13,13.69-13.69v-16.91c0-6.66-4.76-12.21-11.07-13.43Z"
            />
            <path d="m65.27,107.43c-2.86,0-5.17,2.32-5.17,5.17v8.46c0,2.86,2.32,5.17,5.17,5.17s5.17-2.32,5.17-5.17v-8.46c0-2.86-2.32-5.17-5.17-5.17Z" />
            <path d="m88.42,87.81c-2.86,0-5.17,2.32-5.17,5.17v47.7c0,2.86,2.32,5.17,5.17,5.17s5.17-2.32,5.17-5.17v-47.7c0-2.86-2.32-5.17-5.17-5.17Z" />
            <path d="m111.58,103.7c-2.86,0-5.17,2.32-5.17,5.17v15.92c0,2.86,2.32,5.17,5.17,5.17s5.17-2.32,5.17-5.17v-15.92c0-2.86-2.32-5.17-5.17-5.17Z" />
            <path d="m134.73,95.9c-2.86,0-5.17,2.32-5.17,5.17v31.52c0,2.86,2.32,5.17,5.17,5.17s5.17-2.32,5.17-5.17v-31.52c0-2.86-2.32-5.17-5.17-5.17Z" />

        </svg>
    );
}

