
import styled from "styled-components";
import Container from "react-bootstrap/Container";

import bg from "src/assets/img/backnuevo.png";

export const StyledBackground = styled(Container)`
  font-size: 18px;
  background-image: ${(props) =>
    props.$login
      ? ` linear-gradient(to bottom, rgb(53 64 82 / 68%), rgb(53 64 82)), url(${bg});`
      : ` linear-gradient(rgba(53, 64, 82, 0.68), rgb(53, 64, 82)),url(${bg});`};
  background-repeat: repeat;
  background-size: cover;
  color: white;
  min-height: ${(props) => (props.$login ? `100vh` : `inherit` )};
  height: ${(props) => (props.$login ? `100%` : `inherit` )};
  margin-top: ${(props) => (props.$login ? `0` : `50px`)};
  border-radius: ${(props) => (props.$login ? `0` : `15px`)};
  box-shadow: ${(props) => (props.$login ? `none` : `0px 0px 14px 4px #bdbdbd;`)};
  padding: 50px 100px;
  max-width: 800px;

  .contenedor_presentacion {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    h1 {
      font-size: 40px;
      line-height: 46px;
      font-weight: bold;
      margin-top: 17px;
      text-align: left;
      color: white;
    }
    img {
      width: 17%;
      margin-right: 14px;
    }
  }
  .descripcion {
    div {
      display: flex;
      align-items: center;
    }

    .titulo {
      font-size: 12px;
      line-height: 13px;
      margin-bottom: 0px;
      font-weight: bold;
      color: white;
    }
    .bajada {
      font-size: 12px;
      font-weight: 200;
      line-height: 24px;
      margin-bottom: 30px;
      width: 100%;
      color: white;
    }

    svg {
      margin-left: -5px;
    }
  }
`;

