import styled from "styled-components";
import { Container, Form, Tabs, Tab } from "react-bootstrap";

export const StyledContainerFilters = styled(Container)`

.rectangle {
  width: 50px;
  height: 50px;
  background: #354052;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
select{
    background: transparent;
    padding: 4px 0px;
    /* border-radius: 3px; */
    font-size: 13px;
    line-height: 15px;
    border: none;
    color: rgba(53, 64, 82, 0.6);
}
  .container-filter {
    display: flex;
    justify-content: flex-end;
    margin-right: 9px;
    ul {
      padding: 0;
      margin-bottom: 0px;
      display: flex;
      list-style: none;
      cursor: pointer;
      justify-content: flex-end;
      li {
        cursor: pointer;
        margin-right: 2px;
        &:last-child {
          margin-right: 0px;
        }
      }
      svg {
        width: 22px;
      }
    }
  }
    .form-switch {
      font-size: 13px;
      margin-top: 3px;
      display: flex;
  }
  .form-check-input:checked {
    background-color: #73BD82;
    border: 1px solid #73BD82;
  }


  }
  .limpiar-filtros {
    margin-top: 20px;
    display: flex;
    background: transparent;
    border: none;
    font-size: 14px;
    line-height: 15px;
    color: rgba(53, 64, 82, 0.6);
    cursor: pointer;
    svg {    
      margin-right: 5px;
    }
}
`;
export const StyledFormSearch = styled.div`
  margin-bottom: 50px;
`;

export const StyledForm = styled(Form)`
  label {
    margin-bottom: 0px;
    font-size: 14px;
  }

  select {
    margin-bottom: 15px;
  }

  .filterArea p {
    margin: 0;
    background: white;
    padding: 5px;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: min-content;
    align-items: center;
    margin: 0 10px 5px 0px;
  }

  .filterArea p span {
    margin-left: 10px;
    cursor: pointer;
    color: #ff3370;
  }
`;

export const StyledContainerCard = styled(Container)`
  .scroll-container {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
  }

  ::-webkit-scrollbar {
    width: 5px; /* Ancho del scrollbar */
  }

  /* Estilos del thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb */
    border-radius: 5px; /* Radio de borde del thumb */
  }

  /* Estilos del thumb cuando se está arrastrando */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color del thumb al pasar el mouse sobre él */
  }

  /* Estilos del thumb cuando está activo (haciendo clic) */
  ::-webkit-scrollbar-thumb:active {
    background-color: #333; /* Color del thumb al hacer clic en él */
  }

  /*   background: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 10px; */

  .dates {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    cursor: pointer;
    .fecha {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 13px;
      margin-right: 13px;
      color: #354052;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        color: #c4c4c4;
        width: 15px;
        margin-right: 5px;
      }
    }
    .hora {
      margin-bottom: 0;
      font-size: 12px;
      margin-right: 13px;
      line-height: 13px;
      color: #354052;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        color: #c4c4c4;
        width: 15px;
        margin-right: 5px;
      }
    }
    .relevante-rojo {
      color: red;
      margin-bottom: 0;
      font-size: 12px;
      margin-right: 13px;
      line-height: 13px;
      font-weight: bold;
      svg {
        width: 13px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
    .relevante-rojo:hover {
      margin-top: -2px;
    }
    .no-relevante {
      margin-bottom: 0;
      font-size: 12px;
      margin-right: 13px;
      line-height: 13px;
      color: #354052;

      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
    .no-relevante:hover {
      margin-top: -2px;
    }
    .id {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      flex-grow: 1;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
    .usuario {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
    .dia {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        color: #c4c4c4;
        width: 15px;
        margin-right: 5px;
      }
    }
  }

  .texto {
    font-size: 14px;
    line-height: 18px;
    color: #354052;
    text-decoration: none;
    // cursor: pointer;
    word-break: break-word;
    a {
      color: #354052;
      text-decoration: none;
    }
  }
  .redes {
    margin-top: 15px;
    font-size: 11px;
    line-height: 13px;
    color: #354052;
    .contenedor-redes {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      span {
        display: flex;
        align-items: flex-end;
      }
      .fb {
        fill: #3c5a99;
        font-size: 22px;
      }
      .twitter {
        fill: #04c5f9;
        font-size: 22px;
      }
      .instagram {
        fill: #3c5a99;
        font-size: 22px;
      }
      .telegram {
        fill: #32ade2;
        font-size: 22px;
      }
      .whatsapp {
        fill: #25d366;
        font-size: 22px;
      }
      .manual {
        fill: black;
        font-size: 22px;
      }
      .yt {
        fill: red;
        font-size: 22px;
      }
      svg {
        color: #c4c4c4 !important;
        font-size: 15px;
        margin-right: 2px;
        margin-left: 5px;
      }
      .trendig {
        color: white;
        padding: 2px 8px;
        background: #f72d54;
        border-radius: 3px;
        margin-left: 15px;
        svg {
          margin: 0px;
          fill: white;
          padding-right: 1px;
        }
      }
      .tag {
        padding: 2px 18px;
        margin-left: 11px;
        font-size: 10px;
        line-height: 11px;
        color: #354052;
        background: #fafafa;
        border: 1px solid #c4c4c4;
        border-radius: 3px;
      }
      .mas-info {
        font-size: 12px;
        line-height: 11px;
        text-decoration-line: underline;
        margin-right: 16px;
        color: rgba(53, 64, 82, 0.6);
        cursor: pointer;
      }

      .ver-reg {
        a {
          font-size: 10px;
          line-height: 11px;
          margin-right: 16px;
          text-decoration-line: underline;
          color: rgba(53, 64, 82, 0.6);
          &:hover {
            text-decoration-line: underline;
            color: rgba(53, 64, 82, 0.6);
          }
        }
      }
      .btn-no-reg {
        background: #354052;
        border-radius: 17.5px;
        transition: background 0.3s, color 0.3s;
        color: white;
        border: 2px solid;
        padding: 8px 20px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 12px;
        font-size: 12px;
        &:hover {
          background: transparent;
          color: #354052;
        }
      }
      .btn-reg {
        background: #73bd82;
        border-radius: 17px 1px 1px 17px
        transition: background 0.3s, color 0.3s;
        color: white;
        border: 2px solid;
        padding: 8px 20px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 12px;
        font-size: 12px;
        svg {
          fill: #fff;
          font-size: 12px;
          font-weight: bold;
          margin-left: 0;
          margin-right: 5px;
        }
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
  .links {
    font-size: 10px;
    line-height: 11px;
    text-decoration-line: underline;
    color: rgba(53, 64, 82, 0.4);
    display: flex;
    justify-content: left;
    p {
      margin-bottom: 0;
      margin-left: 10px;
      &:first-child {
        margin-left: 0px;
      }
      svg {
        font-size: 10px;
        margin-right: 2px;
      }
    }
  }
  .footer-card {
    margin-top: 20px;
    p {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      font-weight: bold;
    }
    hr {
      margin: 4px 0;
      margin-bottom: 13px;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        border: 0.5px solid #c4c4c4;
        border-radius: 4px;
        font-size: 8px;
        line-height: 9px;
        color: #364151;
        padding: 4px;
        margin-bottom: 4px;
      }
    }
  }
`;

export const StyledContainerCardGuardados = styled(Container)`
::-webkit-scrollbar {
  width: 5px; /* Ancho del scrollbar */
}

/* Estilos del thumb */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color del thumb */
  border-radius: 5px; /* Radio de borde del thumb */
}

/* Estilos del thumb cuando se está arrastrando */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color del thumb al pasar el mouse sobre él */
}

/* Estilos del thumb cuando está activo (haciendo clic) */
::-webkit-scrollbar-thumb:active {
  background-color: #333; /* Color del thumb al hacer clic en él */
}

.card-registro {
  padding: 20px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 20px;
  border: 1px solid rgb(216, 216, 216);
  height: 95%;
  .btn-default-registro {
    border: 2px solid #354052;
    padding: 5px 20px;
    background: #354052;
    border-radius: 17.5px;
    font-size: 12px;
    line-height: 13px;
    /* identical to box height */
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
    color: #FFFFFF;
    font-weight: bold;
    &:hover {
      background: transparent;
      color: #354052;
    }
  }

  .card-editar {
    display: flex;
    margin-bottom: 5px;
    .fecha {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 16px;
      margin-right: 16px;
      color: #354052;
      flex-grow: 1;
      padding-left: 5px;
      padding-top: 1px;
      svg {
        color: rgba(53, 64, 82, 0.6);
        width: 15px;
        margin-right: 5px;
      }
    }
    .lapiz {
      font-size: 15px;
      color: rgba(53, 64, 82, 0.6);
      &:hover {
        color: blue;
      }
    }
    .tacho {
      font-size: 16px;
      color: rgba(53, 64, 82);
      &:hover {
        color: #f72d54;
      }
    }
  }

  .titulo {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    margin-top: 10px;
    margin-bottom: 10px
    color: #354052;
  }
  .bajada {
    font-size: 12px;
    line-height: 15px;
    color: #354052;    
    max-height: 75px;
    min-height: 45px;
    overflow: visible;
    height: inherit;
    cursor: pointer;
  }
  .circulos {
    display: flex;
  }

  .circulo {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .gris {
    background-color: #EFEFEF;
  }

  .verde {
    background-color: #25D366;
  }

  .rojo {
    background-color: #F72D54;
  }

  .yellow {
    background-color: #EBEF0F;
  }

  .gris-oscuro {
    background-color: #C4C4C4;
  }

  .clasificacion {
    margin-top: 10px;
    border-radius: 3px;
    text-align: center;
    padding: 6px;
    p{ 
      margin-bottom: 0;
      font-size: 13px;
      line-height: 11px;
      color: #354052;
    }
  }
  .tags-registros {
    padding: 0px;
    list-style: none;
    display: flex;
    margin-bottom: 0;
    margin-top: 12px;
    li {
         margin-right: 8px;
    border: 1px solid black;
    border-radius: 3px;
    padding: 5px;
    font-size: 12px;
    line-height: 9px;
    color: #364151;
    margin-bottom: 0;
    }
  }

  .contenedor-redes-registro {
    margin-bottom: 40px;
    list-style: none;
    margin-top: 15px;
    font-size: 11px;
    line-height: 13px;
    color: #354052;
    padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: left;
      span {
        display: flex;
        align-items: flex-end;
        margin-top: 3px;
        margin-bottom: 3px;
      }
      .fb {
        fill: #3C5A99;
        font-size: 18px;
      }
      .twitter {
        fill: #04c5f9;
        font-size: 18px;
      }
      .instagram {
        fill: #3c5a99;
        font-size: 18px;
      }
      .telegram {
        fill: #32ade2;
        font-size: 18px;
      }
      .whatsapp {
        fill: #25D366;;
        font-size: 18px;
      }
      .manual {
        fill: black;
        font-size: 18px;
      }
      .yt {
        fill: red;
        font-size: 18px;
      }
      svg {
        color: #c4c4c4 !important;
        font-size: 18px;
        margin-right: 2px;
        margin-left: 5px;
      }
      .trendig {
        color: white;
        padding: 2px 8px;
        background: #f72d54;
        border-radius: 3px;
        margin-left: 15px;
        svg {
          margin: 0px;
          fill: white;
          padding-right: 1px;
        }
      }
      .tag {
        padding: 2px 18px;
        margin-left: 11px;
        font-size: 10px;
        line-height: 11px;
        color: #354052;
        background: #fafafa;
        border: 1px solid #c4c4c4;
        border-radius: 3px;
      }
      .mas-info {
        font-size: 10px;
        line-height: 11px;
        text-decoration-line: underline;
        margin-right: 16px;
        color: rgba(53, 64, 82, 0.6);
        cursor: pointer;
      }
      .ver-reg {
        a {
          font-size: 10px;
          line-height: 11px;
          margin-right: 16px;
          text-decoration-line: underline;
          color: rgba(53, 64, 82, 0.6);
          &:hover {
            text-decoration-line: underline;
            color: rgba(53, 64, 82, 0.6);
          }
        }
      }
      .btn-no-reg {
        background: #354052;
                border-radius: 17.5px;
        transition: background 0.3s, color 0.3s;
        color: white;
        border: 2px solid;
        padding: 8px 20px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 12px;
        font-size: 12px;
          &:hover {
          background: transparent;
          color: #354052;
        }
      }
      .btn-reg {
background: #73bd82;
        border-radius: 17px 1px 1px 17px
        transition: background 0.3s, color 0.3s;
        color: white;
        border: 2px solid;
        padding: 8px 20px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 12px;
        font-size: 12px;
        svg {
          fill: #fff;
          font-size: 12px;
          font-weight: bold;
          margin-left: 0;
          margin-right: 5px;
        }
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
}

/* .scroll-container {
  max-height: 300px;
  overflow-y: auto;
  position: relative;
}
::-webkit-scrollbar {
  width: 5px; /* Ancho del scrollbar */
}

/* Estilos del thumb */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color del thumb */
  border-radius: 5px; /* Radio de borde del thumb */
}

/* Estilos del thumb cuando se está arrastrando */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color del thumb al pasar el mouse sobre él */
}

/* Estilos del thumb cuando está activo (haciendo clic) */
::-webkit-scrollbar-thumb:active {
  background-color: #333; /* Color del thumb al hacer clic en él */
}

  /*   background: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 10px; */

  .dates {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    cursor: pointer;
    .fecha {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 13px;
      margin-right: 13px;
      color: #354052;
      svg {
        color: #c4c4c4;
        width: 15px;
        margin-right: 5px;
      }
    }
    .hora {
      margin-bottom: 0;
      font-size: 12px;
      margin-right: 13px;
      line-height: 13px;
      color: #354052;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
    .id {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      flex-grow: 1;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
    .usuario {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
    .dia {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
  }

  .texto {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #354052;
    cursor: pointer;
  }
  .redes {
    margin-top: 15px;
    font-size: 11px;
    line-height: 13px;
    color: #354052;
    .contenedor-redes {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      span {
        display: flex;
        align-items: flex-end;
      }
      .fb {
        fill: #3C5A99;
        font-size: 30px;
      }
      .twitter {
        fill: #04c5f9;
        font-size: 30px;
       
      }
      .instagram {
        fill: #3C5A99;
        font-size: 30px;
      }
      .telegram {
        fill: #32ade2;
        font-size: 30px;
      }
      .whatsapp {
        fill: #25D366;;
        font-size: 30px;
      }
      .manual {
        fill: black;
        font-size: 30px;
      }
      .yt {
        fill: red;
        font-size: 30px;
      }
      svg {
        color: #c4c4c4 !important;
        font-size: 15px;
        margin-right: 2px;
        margin-left: 5px;
      }
      .trendig {
        color: white;
        padding: 2px 8px;
        background: #f72d54;
        border-radius: 3px;
        margin-left: 15px;
        svg {
          margin: 0px;
          fill: white;
          padding-right: 1px;
        }
      }
      .tag {
        padding: 2px 18px;
        margin-left: 11px;
        font-size: 10px;
        line-height: 11px;
        color: #354052;
        background: #fafafa;
        border: 1px solid #c4c4c4;
        border-radius: 3px;
      }
      .mas-info {
        font-size: 10px;
        line-height: 11px;
        text-decoration-line: underline;
        margin-right: 16px;
        color: rgba(53, 64, 82, 0.6);
        cursor: pointer;
      }
      .ver-reg {
        a {
          font-size: 10px;
          line-height: 11px;
          margin-right: 16px;
          text-decoration-line: underline;
          color: rgba(53, 64, 82, 0.6);
          &:hover {
            text-decoration-line: underline;
            color: rgba(53, 64, 82, 0.6);
          }
        }
      }
      .btn-no-reg {
        background: #354052;
               border-radius: 17.5px;
        transition: background 0.3s, color 0.3s;
        color: white;
        border: 2px solid;
        padding: 8px 20px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 12px;
        font-size: 12px;
          &:hover {
          background: transparent;
          color: #354052;
        }
      }
      .btn-reg {
background: #73bd82;
       border-radius: 17px 1px 1px 17px
        transition: background 0.3s, color 0.3s;
        color: white;
        border: 2px solid;
        padding: 6px 20px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 12px;
        font-size: 12px;
        svg {
          fill: #fff;
          font-size: 12px;
          font-weight: bold;
          margin-left: 0;
          margin-right: 5px;
        }
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
  .links {
    font-size: 10px;
    line-height: 11px;
    text-decoration-line: underline;
    color: rgba(53, 64, 82, 0.4);
    display: flex;
    justify-content: left;
    p {
      margin-bottom: 0;
      margin-left: 10px;
      &:first-child {
        margin-left: 0px;
      }
      svg {
        font-size: 10px;
        margin-right: 2px;
      }
    }
  }
  .footer-card {
    margin-top: 20px;
    p {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      font-weight: bold;
    }
    hr {
      margin: 4px 0;
      margin-bottom: 13px;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        border: 0.5px solid #c4c4c4;
        border-radius: 4px;
        font-size: 8px;
        line-height: 9px;
        color: #364151;
        padding: 4px;
        margin-bottom: 4px;
      }
    }
  } */
`;

export const StyledNavTabs = styled(Tabs)`
  background: transparent;
  color: rgba(53, 64, 82, 0.6);
  border: none;
  margin-bottom: 20px;
  margin-top: 10px !important;

  .nav-link {
    color: rgba(53, 64, 82, 0.6);
    border: none;
  }
  .nav-link.active {
    font-weight: bold;
    background: transparent;
    border-bottom: 4px solid #354052;
    color: #354052;
    padding-bottom: 2px;
  }
`;

export const StyledTab = styled(Tab)`
`;

