import React, { useState, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom';
import {getLogin, getToken} from 'src/store'
import { connect } from "react-redux";
const getTokenPr = (props) => {
  const token = localStorage.getItem("token")
  return !!token;
}

function PrivateRoutes(props) {
  const [token, setToken] = useState(getTokenPr);

  useEffect(() => {
    const tokenLS = localStorage.getItem("token");
    setToken(tokenLS);
    props.getLogin(!!tokenLS);
    props.getToken(tokenLS);
  }, []);

  if (!token) {
    return <Redirect to="/login" />;
  }

  return <Route path={props.path} component={props.component} exact />;
}

const mapStateToProps = state => {
  return {
    login: state.login,
    token: state.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLogin: status => dispatch(getLogin(status)),
    getToken: token => dispatch(getToken(token))
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoutes);