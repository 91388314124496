import React, { useState, useEffect } from "react";

// Redux
import { connect } from "react-redux";

// Components
import LoadingCard from "src/components/LoadingCard";
import NoEncontrado from "src/components/no-encontrado";
import Publication from "../Publication";

// Styles
import { StyledContainerCard } from "./styled";
import "./style.css";

function PublicationsList(props) {

    const [data, setData] = useState();

    // When List of publicaciones is loaded, set the data state with corresponding list
    useEffect(() => {
        if (props.monitoreo && props.monitoreo.results) 
            setData(props.monitoreo.results);
    }, [props.monitoreo]);

    return (
        <div>
            <StyledContainerCard fluid className="scroll-container">
                {
                    // If no data yet, show loading
                    data == null || data == undefined ?
                    (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <LoadingCard />
                        </div>
                    ): // If no results, show corresponding message
                    data.length == 0 ? 
                    (
                        <NoEncontrado />
                    ) : // Else, show cards
                    (
                        data?.map((el, index) => (
                            <Publication el={el} index={index} key={index}/>
                        ))
                    )
                }
            </StyledContainerCard>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        monitoreo: state.monitoreo,
        isLoading: ownProps.isLoading
    };
};


export default connect(mapStateToProps)(PublicationsList);
