import styled from "styled-components";

export const StyledResults = styled.p`
  display: inline;
  margin-right: 30px;
  margin-bottom: 0px;
  font-size: 12px;
`;

export const StyledMenuCard = styled.div`
  ul li a {
    font-size: 16px;
  }
  .dropdate a {
    background: transparent !important;
    color: #354052 !important;
    border-color: #cedaee !important;
    border-radius: 0 !important;
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 2.255em !important;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .nav-item .nav-link {
    margin-right: 12px;
    color: #354052;
  }
  .active a {
    color: #ff3370 !important;
    font-weight: bold;
  }
`;
