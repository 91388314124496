import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

// Boostrap, icons and styles
import { Form, Col, Row, Container } from "react-bootstrap";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import {
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaSearch,
  FaTelegramPlane,
} from "react-icons/fa";
import {
  MdClose,
  MdFilterAltOff,
  MdOutlineFacebook,
  MdOutlineUploadFile,
} from "react-icons/md";
import { StyledContainerFilters } from "../styled";
import "./style.css";

// Components
import PublicationsList from "src/components/Monitoreo/PublicationsList";
import FormSavePublication from "src/components/Monitoreo/Forms/FormSavePublication";
import FormCreateCard from "src/components/Monitoreo/Forms/FormCreateCard";
import FormCreatePublication from "src/components/Monitoreo/Forms/FormCreatePublication";
import ReactPaginate from "react-paginate";
import WrapperTrends from "src/components/Monitoreo/WrapperTrends";

// Redux
import { connect } from "react-redux";
import { getMonitoreoRequest, monitoreoToggleForm } from "src/store";

const FeedVivo = (props) => {
  const [data, setData] = useState();
  const [isLoadingPpal, setIsLoadingPpal] = useState(true);

  const [lastSelectedDays, setlastselectedDays] = useState(24);
  const [resgister, setlResgister] = useState("Todos");
  const [origin, setOrigin] = useState("Todos");
  const [selectFilterDays, setselectFilterDays] = useState(24);
  const [orden, setOrden] = useState("-virality");
  const [selectFilterStatusSource, setSelectFilterStatus] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const [searchType, setSearchType] = useState('content');
  const [querySearch, setQuerySearch] = useState("");
  const [paginatorPage, setPaginatorPage] = useState(1);
  const [selectedButtons, setSelectedButtons] = useState([
    "instagram",
    "fbpost",
    "tweet",
    "telegram",
    "youtube",
    "tiktok",
  ]);



  const initialSelectValues = {
    lastSelectedDays: 168,
    orden: "Todos",
    register: "Todos",
    limit: 10,
    offset: 0,
  };

 const buildApiUrl = (page = 1, orden) => {
    const typeInValues = [];
    const otherValues = [];
    orden = orden || "";

    selectedButtons.forEach((button) => {
      if (
        button === "fbpost" ||
        button === "tweet" ||
        button === "instagram" ||
        button === "tiktok" ||
        button === "telegram" ||
        button === "youtube"
      ) {
        typeInValues.push(button);
      } else {
        otherValues.push(button);
      }
    });

    let typeInParam = "";

    if (typeInValues.length > 0) typeInParam = `type__in=${typeInValues.join(",")}`;
    else if (!selectAll) typeInParam = "type__in=none";

    const otherParams = otherValues
      .map((button, index) => `param${index + 1}=${button}`)
      .join("&");

    let url = "";

    if (typeInParam) {
      url += `${typeInParam}&`;
    }

    if (otherParams) {
      url += `${otherParams}&`;
    }

    if (origin && origin !== "Todos") {
      if (origin === "manual") {
        url += `upload_type=manual&`;
      } else {
        url += `upload_type=${encodeURIComponent(origin)}&`;
      }
    }

    if (orden && orden !== "Todos") {
      if (orden === "-virality") {
        url += `ordering=-virality`;
      } else {
        url += `ordering=${encodeURIComponent(orden)}`;
      }
    }

    // Calculate offset based on page
    const limit = 15;
    const offset = (page - 1) * limit;
    url += `&limit=${limit}&offset=${offset}`;

    if (querySearch !== "") {
      if (searchType === "content") {
        console.log("Buscando en el contenido");
        url += `&search=${encodeURIComponent(querySearch)}`;
      } else if (searchType === "author") {
        url += `&author__in=${encodeURIComponent(querySearch)}`;
      }
    }

    return url;
  };

  useEffect(() => {
    if (props.monitoreo && props.monitoreo.results)
      setData(props.monitoreo.results);
  }, [props.monitoreo]);


   const fetchMonitoreo = async (page = currentPage) => {
    try {
      if (props.login) {
        setIsLoadingPpal(true);

        const redesUrlParams = buildApiUrl(page, orden);

        const requestParams = {
          days: selectFilterDays,
          register: resgister === "Todos" ? "" : resgister,
          type: origin === "Todos" ? "" : origin,
          redes: redesUrlParams,
          search: querySearch,
        };

        await props.getMonitoreoRequest(requestParams);
        setCurrentPage(page);
      setPaginatorPage(page - 1);
      }
    } catch (error) {
      console.log("Error al cargar las cards:", error);
    } finally {
      setIsLoadingPpal(false);
    }
  };


  useEffect(() => {
    props.toggleForm(false, "none", 0);
  }, [props.activeTab]);

  // Fetch
  useEffect(() => {
    fetchMonitoreo();
  }, [
    props.login,
    selectFilterDays,
    resgister,
    origin,
    selectedButtons,
    orden,
    props.activeTab,
    location.pathname,
  ]);

  useEffect(() => {
  setPaginatorPage(currentPage - 1);
}, [currentPage]);

  useEffect(() => {
    if (props.registro_status == "success") {
      fetchMonitoreo();
    }
  }, [props.registro_status]);

  useEffect(() => {
    if (querySearch === "") {
      fetchMonitoreo();
    }
  }, [querySearch]);

  // Se usa
const handleFilterQueryRequest = (e) => {
   setQuerySearch(e.target.value);
  };

  const handleClearInput = () => {
    setQuerySearch("");
  };

  const handleSelectAllMedia = async (event) => {
    const isChecked = event.target.checked;
    setIsLoadingPpal(true);
    setSelectAll(isChecked);

    if (isChecked) {
      setSelectedButtons([
        "instagram",
        "fbpost",
        "tweet",
        "telegram",
        "youtube",
        "tiktok",
      ]);
    } else {
      setSelectedButtons([]);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsLoadingPpal(false);
  };

  const availableButtons = [
    "instagram",
    "fbpost",
    "tweet",
    "telegram",
    "youtube",
    "tiktok",
  ];

  const handleButtonRedesFilterClick = (buttonType) => {
    setSelectedButtons((prevSelectedButtons) => {
      let updatedButtons;

      if (prevSelectedButtons.includes(buttonType)) {
        updatedButtons = prevSelectedButtons.filter(
          (btn) => btn !== buttonType
        );
      } else {
        updatedButtons = [...prevSelectedButtons, buttonType];
      }

      const isAllButtonsSelected =
        updatedButtons.length === availableButtons.length;

      setSelectedButtons(updatedButtons);
      setSelectAll(isAllButtonsSelected);

      return updatedButtons;
    });
  };

  const handleFilterChangeDay = (event) => {
    setSelectFilterStatus(true);
    if (event.target.value === "Últimas 24 hs") {
      setlastselectedDays(event.target.value);
      setselectFilterDays(24);
    } else {
      setselectFilterDays(Number(event.target.value));
    }
  };

  const handleFilterRegister = (event) => {
    setSelectFilterStatus(true);
    setlResgister(event.target.value === "Todos" ? "" : event.target.value);
  };

  const handleFilterOrigin = (event) => {
    setSelectFilterStatus(true);
    setOrigin(event.target.value === "Todos" ? "" : event.target.value);
  };

  const clearFiltersFeed = (selectedPage) => {
    setCurrentPage(0);
    setQuerySearch("");
    setlastselectedDays(initialSelectValues.lastSelectedDays.toString());
    setselectFilterDays(initialSelectValues.lastSelectedDays.toString());
    setlResgister(initialSelectValues.register);
    setSelectFilterStatus(false);

    setOrden(false);
    // fetchMonitoreo(selectedPage);
  };

  const handleKeyDownBuscadorPpal = (e) => {
    if (e.keyCode === 13) {
       e.preventDefault(); // Prevenir el comportamiento por defecto del formulario
    setCurrentPage(1);
    setPaginatorPage(0);
    fetchMonitoreo(1);
    }
  };



  const handleFilterOrden = (event) => {
    setSelectFilterStatus(true);
    setOrden(event.target.value === "Todos" ? "" : event.target.value);
  };

  const handleLoadNewPublication = () => {
    props.toggleForm(true, "save_new_publication", -1);
  };



  return (
    <Row>
      <Col md={7}>
        <Container fluid>
          <Row>
            <Col sm={12} md={8}>
               <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          border: "1px solid #ddd",
          borderRadius: "15px",
        }}
        className="input-group search-bar"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className="input-group-prepend"
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            className="input-group-text"
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fontSize: "16px" }}
            >
              <path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
            </svg>
          </span>
        </div>

        <select
          style={{
            background: "transparent",
            padding: "4px 0px",
            fontSize: "13px",
            lineHeight: "15px",
            border: "none",
            color: "rgba(53, 64, 82, 0.6)",
            borderRight: "3px solid #f7f9fc",
            marginRight: "2px",
            paddingRight: "5px",
          }}
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
        >
          <option value="content">En el contenido</option>
          <option value="author">Por nombre de cuenta</option>
        </select>

        <input
          type="text"
          className="form-control"
          placeholder={ searchType === 'content' ? "Ingresá los términos y pulsa Enter" : "Ingresá las cuentas separadas por comas" }
          value={querySearch}
          onChange={handleFilterQueryRequest}
          onKeyDown={handleKeyDownBuscadorPpal}
          style={{
            flex: 1,
            border: "none",
            borderRadius: "0",
            padding: "8px 12px",
          }}
          data-sharkid="__1"
        />

        {querySearch !== "" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "auto",
            }}
            className="input-group-append"
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "transparent",
                border: "none",
                padding: "10px",
              }}
              className="input-group-text"
              onClick={handleClearInput}
            >
              <MdClose style={{ fontSize: "16px", color: "#212529" }} />
            </span>
          </div>
        )}
      </div>
    </div>
            </Col>
            <Col sm={12} md={4}>
              <button
                className="button-cargar"
                onClick={() => handleLoadNewPublication()}
              >
                <MdOutlineUploadFile />
                Cargar publicación
              </button>
            </Col>
          </Row>
        </Container>
        <StyledContainerFilters
          style={{ padding: "0px 34px 0px 12px", marginBottom: "34px" }}
          fluid
          className="scroll-container"
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xxl={12}>
              <Row style={{ justifyContent: "space-between" }}>
                <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={8}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        marginRight: "15px",
                        paddingRight: "15px",
                        borderRight: "1px solid rgba(53,64,82,43%)",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0",
                          fontSize: "13px",
                          marginLeft: "4px",
                        }}
                      >
                        Orden
                      </p>
                      <select
                        id="orden"
                        onChange={handleFilterOrden}
                        value={orden}
                      >
                        <option value="-virality">Por viralidad</option>
                        <option value="-time">Por fecha</option>
                        {/*<option value="-relevance">Por relevancia</option>*/}
                      </select>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <p
                        style={{
                          marginBottom: "0",
                          fontSize: "13px",
                          marginLeft: "4px",
                        }}
                      >
                        Período
                      </p>
                      <select
                        id="days"
                        onChange={handleFilterChangeDay}
                        value={selectFilterDays}
                      >
                        <option value="3">Últimos 3 hs</option>
                        <option value="6">Últimos 6 hs</option>
                        <option value="12">Últimos 12 hs</option>
                        <option value="24">Últimas 24 hs</option>
                        <option value="48">Últimos 2 días</option>
                        <option value="72">Últimos 3 días</option>
                        <option value="168">Últimas 7 días</option>
                      </select>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <p
                        style={{
                          marginBottom: "0",
                          fontSize: "13px",
                          marginLeft: "4px",
                        }}
                      >
                        Guardados
                      </p>
                      <select
                        id="register"
                        onChange={handleFilterRegister}
                        value={resgister}
                      >
                        <option value="Todos">Todos</option>
                        <option value="true">Guardado</option>
                        <option value="false">No guardado</option>
                      </select>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <p
                        style={{
                          marginBottom: "0",
                          fontSize: "13px",
                          marginLeft: "4px",
                        }}
                      >
                        Tipo de carga
                      </p>
                      <select
                        id="register"
                        onChange={handleFilterOrigin}
                        value={origin}
                      >
                        <option value="Todos">Todos</option>
                        <option value="manual">Carga manual</option>
                        <option value="scrapper">Automático</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={4}
                  xxl={4}
                  style={{ padding: "0" }}
                >
                  <div className="container-filter">
                    <ul>
                      <li>
                        <MdOutlineFacebook
                          onClick={() => handleButtonRedesFilterClick("fbpost")}
                          style={{
                            fontSize: "20px",
                            color:
                              selectedButtons.includes("fbpost") || selectAll
                                ? "rgb(26 104 233)"
                                : "gray",
                          }}
                        />
                      </li>
                      <li>
                        <FaTwitter
                          onClick={() => handleButtonRedesFilterClick("tweet")}
                          style={{
                            color:
                              selectedButtons.includes("tweet") || selectAll
                                ? "#32ade2"
                                : "gray",
                          }}
                        />
                      </li>
                      <li>
                        <FaInstagram
                          onClick={() =>
                            handleButtonRedesFilterClick("instagram")
                          }
                          style={{
                            color:
                              selectedButtons.includes("instagram") || selectAll
                                ? "#ff4d7d"
                                : "gray",
                          }}
                        />
                      </li>
                      <li>
                        <FaTelegramPlane
                          onClick={() =>
                            handleButtonRedesFilterClick("telegram")
                          }
                          style={{
                            color:
                              selectedButtons.includes("telegram") || selectAll
                                ? "#32ade2"
                                : "gray",
                          }}
                        />
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          fill="currentColor"
                          className="bi bi-tiktok"
                          viewBox="0 0 18 18"
                          onClick={() => handleButtonRedesFilterClick("tiktok")}
                          style={{
                            color: selectedButtons.includes("tiktok")
                              ? "#000"
                              : "gray",
                          }}
                        >
                          <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                        </svg>
                      </li>
                      <li>
                        <FaYoutube
                          onClick={() =>
                            handleButtonRedesFilterClick("youtube")
                          }
                          style={{
                            color:
                              selectedButtons.includes("youtube") || selectAll
                                ? "red"
                                : "gray",
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                  <Row>
                    <Col
                      lg={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                      }}
                    >
                      <Form>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <label
                            htmlFor="custom-switch"
                            style={{
                              marginRight: "10px",
                              fontSize: "12px",
                              lineHeight: "15px",
                              marginBottom: "0px",
                              color: "#354052",
                            }}
                          >
                            Seleccionar todo
                          </label>
                          <Form.Check
                            inline
                            type="switch"
                            id="custom-switch"
                            label=""
                            checked={selectAll}
                            onChange={handleSelectAllMedia}
                            style={{
                              marginRight: "0px",
                            }}
                            className={selectAll ? "switch-on" : "switch-off"}
                          />
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Col>
                {/*
                <Col md={12}>
                <button
                    onClick={(selectedPage) =>
                      clearFiltersFeed(selectedPage.selected)
                    }
                    className="limpiar-filtros"
                  >
                    <MdFilterAltOff />
                    Restablecer
                  </button>
                </Col>
                  */}
              </Row>
            </Col>
          </Row>
        </StyledContainerFilters>

        {/* Listado de publicaciones */}

        <PublicationsList isLoading={isLoadingPpal} />

        {/* Paginador */}
        {data !== undefined &&
        data !== null &&
        data.length > 0 &&
        props.monitoreo.count > 15 &&
        (selectAll || selectedButtons.length > 0) ? (
          <ReactPaginate
            previousLabel={<FaArrowLeft />}
            nextLabel={<FaArrowRight />}
            pageCount={Math.ceil(props.monitoreo.count / 15)}
             onPageChange={({ selected }) => {
    const page = selected + 1;
    setCurrentPage(page);
    setPaginatorPage(selected);
    fetchMonitoreo(page);
    window.scrollTo(0, 0);
  }}
            breakLabel={"..."}
            breakClassName={"break-me"}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName={"pagination"}
            activeClassName={"active"}
            initialPage={currentPage - 1}
            forcePage={paginatorPage}
          />
        ) : null}
      </Col>
      <Col md={5}>
        {/* <Row>
          <WrapperTrends />
        </Row> */}
        <FormSavePublication // Save publication to card
          id="save_publication"
          cardTitle="Guardar publicación"
          cardDescription="Seleccione una de las Tarjetas existentes para guardar esta publicación o cree una nueva"
        />
        <FormCreateCard // Create new card
          id="create_card"
          cardTitle="Crear nueva Tarjeta"
          cardDescription="Las Tarjetas agrupan Contenidos que se refieren al mismo hecho o mensaje."
        />
        <FormSavePublication // Open save publication form to later load new publication
          id="save_new_publication"
          cardTitle="Cargar publicación manualmente"
          cardDescription="Antes de cargar, deberás elegir o crear la Tarjeta donde se alojará la publicación"
        />
        <FormCreateCard // Create new card
          id="create_card_for_new_publication"
          cardTitle="Crear nueva Tarjeta"
          cardDescription="Para almacenar tu nueva publicación debes crear una tarjeta. Las Tarjetas agrupan Contenidos que se refieren al mismo hecho o mensaje."
        />
        <FormCreatePublication // Create new card
          id="create_publication"
          cardTitle="Cargar nueva publicación"
          cardDescription="Complete los siguientes campos para cargar la nueva publicación."
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    monitoreo: state.monitoreo,
    loading: state.loading,
    login: state.login,
    error: state.error,
    error_monitoreo: state.error_monitoreo,
    monitoreo_id: state.monitoreo_id,
    categorias_form: state.categorias_form,
    cards_monitoreo: state.cards_monitoreo,
    desinformaciones_detalle: state.desinformaciones_detalle,
    busqueada_desinformaciones: state.busqueada_desinformaciones,
    registro_status: state.registro_status,
    nuevo_registro: state.nuevo_registro,
    desinformaciones: state.desinformaciones,
    registro_status_manual: state.registro_status_manual,
    categorias_detalle: state.categorias_detalle,
    page_registrados: state.page_registrados,
    trendings: state.trendings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMonitoreoRequest: (filtros) => dispatch(getMonitoreoRequest(filtros)),
    toggleForm: (openForm, openFormId, publication_id) =>
      dispatch(monitoreoToggleForm(openForm, openFormId, publication_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedVivo);
