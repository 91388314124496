import React, { useState } from "react";
import { getTextos } from "src/store";
import { connect } from "react-redux";
import {StyledBackground} from "../styled";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "src/components/Push/Logo";

function HomeTextos(props) {
  const [analisis, setanalisis] = useState(false);
  const [error, setError] = useState(false);

  const handleText = async (e) => {
    let text = document.getElementById("input_text").value;
    try {
      await props.getTextos(text);
      text = document.getElementById("input_text").value = "";
    } catch (error) {
      console.log("error al texto", error);
      setError(true);
    }
    setanalisis(true);
  };

  const handleClear = () => {
    setanalisis(false);
  }
  return (
    <StyledBackground fluid={true}>
      <Row>
        <Col lg={12}>
          <div className="titulo">
            <Logo />
            <p>Análisis de texto</p>
          </div>
        </Col>
      </Row>
      <Row style={{ paddingLeft: "50px", marginTop: "40px" }}>
        <Col lg={6}>
          <div>
            <div id="input-box">
              <div className="row pb-2">
                <div className="col-md-12">
                  <h4 style={{ fontSize: "16px", color: "#354052" }}>
                    Ingresá el texto a analizar
                  </h4>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="input_text"
                  cols="200"
                  rows="10"
                  placeholder="Texto a analizar."
                  required
                ></textarea>
              </div>
              <div>
                <button
                  id="analizar"
                  type="button"
                  className="enter"
                  onClick={() => handleText()}
                >
                  Analizar texto
                </button>
                <button
                  id="analizar"
                  type="button"
                  className="borrar"
                  onClick={() => handleClear()}
                >
                  Borrar
                </button>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          {error ? (
            <div className="text-center">
              <div id="buscando">
                <img
                  src="/static/textos/loading.gif"
                  alt="Loading"
                  title="Loading"
                  width="50px"
                  height="50px"
                />
              </div>
              <div id="error" className="alert alert-danger">
                Hubo un error al analizar el texto. Por favor ponente en
                contacto con innovacion@chequeado.com
              </div>
            </div>
          ) : null}
          {analisis ? (
            <div className="py-3 mt-4" id="resultados_container">
              <div className="row">
                <div className="col-md-3">
                  <h4 style={{ fontSize: "14px" }}>Análisis</h4>
                </div>
                <div className="col-md-9 text-right">
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <span className="green-box"></span>
                    <span
                      className="vertical-align-top"
                      style={{ fontSize: "10px" }}
                    >
                      Frases chequeables
                    </span>
                    {/*  <span className="blue-box"></span>
                    <span
                      className="vertical-align-top"
                      style={{ fontSize: "10px" }}
                    >
                      Robocheck
                    </span> */}
                  </p>
                </div>
              </div>
              <div id="resultados">
                {props.textos.map((el) => (
                  <p
                    style={{
                      marginTop: "16px",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                    className={el.label === true ? "chequeable" : null}
                  >
                    {el.sentence}
                    {/* {el.robocheck !== "" ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            {el.robocheck}
                          </Tooltip>
                        }
                      >
                        <p
                          variant="light"
                          //className="d-inline-flex align-items-center"
                          className={el.robocheck !== "" ? "robocheck" : null}
                        >
                          <span className="ms-1">{el.sentence}</span>
                        </p>
                      </OverlayTrigger>
                    ) : (
                      el.sentence
                    )} */}
                  </p>
                ))}
              </div>
            </div>
          ) : null}
        </Col>
      </Row>

      {/* {error ? (
            <div className="text-center">
              <div id="buscando">
                <img
                  src="/static/textos/loading.gif"
                  alt="Loading"
                  title="Loading"
                  width="50px"
                  height="50px"
                />
              </div>
              <div id="error" className="alert alert-danger">
                Hubo un error al analizar el texto. Por favor ponente en
                contacto con innovacion@chequeado.com
              </div>
            </div>
          ) : null}

          {analisis ? (
            <div className="py-3" id="resultados_container">
              <div className="row">
                <div className="col-md-6">
                  <h4>Análisis</h4>
                </div>
                <div className="col-md-6 text-right">
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span className="green-box"></span>
                    <span className="vertical-align-top">
                      Frases chequeables
                    </span>
                    <span className="blue-box"></span>
                    <span className="vertical-align-top">Robocheck</span>
                  </p>
                </div>
              </div>
              <div id="resultados" className="bg-white p-3 rounded">
                {props.textos.map((el) => (
                  <p
                    style={{ marginTop: "16px", cursor: "pointer" }}
                    className={el.label === true ? "chequeable" : null}
                  >
                    {el.robocheck !== "" ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            {el.robocheck}
                          </Tooltip>
                        }
                      >
                        <p
                          variant="light"
                          //className="d-inline-flex align-items-center"
                          className={el.robocheck !== "" ? "robocheck" : null}
                        >
                          <span className="ms-1">{el.sentence}</span>
                        </p>
                      </OverlayTrigger>
                    ) : (
                      el.sentence
                    )}
                  </p>
                ))}
              </div>
            </div>
          ) : null} */}
    </StyledBackground>
  );
}

const mapStateToProps = (state) => {
  return {
    textos: state.textos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTextos: (text) => dispatch(getTextos(text)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeTextos);
