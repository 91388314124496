import React, { useEffect, useState } from "react";

/* Styled */
import { StyledMedios } from "./styled";

/* Redux */
import { connect } from "react-redux";
import {
  getMessagesFilter,
  getListData,
  getListInvitation,
  getListInvitationDelete,
  getMediosUserQsch,
} from "src/store";

/* Bootstrap and MD */
import {Table, Form, Button, Row, Col} from "react-bootstrap";
import { MdWarning } from "react-icons/md";

/* Components */
import LoadingCard from "src/components/LoadingCard";

function MediosQsch(props) {
  const [active, setActive] = useState("Pedidos");
  const [disabled, setdisabled] = useState(false);
  const [data, setData] = useState();
  const [dataMedios, setDataMedios] = useState();
  const [dataUser, setDataUser] = useState();
  const [loading, setloading] = useState(true);

  const [nameSource, setnameSource] = useState("");
  const [listSource, setListSource] = useState("");

  useEffect(() => {
    try {
      if (props.login) {
        const fetchUser = async () => {
          await props.getMediosUserQsch();
        };
        fetchUser();
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [props.login]);

  /*   useEffect(() => {
    if (props.medios_user_qsch) {
      setDataUser(props.list_users);
      setDataMedios(props.medios_user_qsch);
    }
  }, [props.medios_user_qsch]);
 */

  useEffect(() => {
    if (props.user_data) {
      setDataUser(props.user_data);
    }
  }, [props.user_data]);

  useEffect(() => {
    if (props.medios_user_qsch) {
      setDataMedios(props.medios_user_qsch);
    }
  }, [props.medios_user_qsch]);

  useEffect(() => {
    try {
      if (dataMedios && Object.keys(dataMedios).length != 0) {
        setloading(false);
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [dataMedios]);



  const handleUserForm = (e) => {
    e.preventDefault();
    props.getListInvitation({
        media_name: nameSource,
        section_links: listSource
      });
    setnameSource("");
    setListSource("");
  };

  const handleSourceInvitation = (e) => {
    e.preventDefault();
    setnameSource(e.target.value);
  };

  const handleListInvitation = (e) => {
    e.preventDefault();
    setListSource(e.target.value);
  };

  return (
    <StyledMedios>
      {/* {data?.map((el) => (
        <h2>
          <span>{el.user.username}</span>
        </h2>
      ))}
      <h3>Selección de medios</h3> */}
      <Row>
        <Col lg={12}>
          <div className="tablecontainer"
            style={{ overflow: "scroll", height: "400px", overflowX: "hidden" }}
          >
            <Table bordered size="sm" style={{backgroundColor: "white"}}>
              <thead style={{position: 'sticky', top: '-2px', zIndex: "1"}}>
                <tr>
                  <th>Nombre</th>
                  <th>Habilitado</th>
                </tr>
              </thead>
              <tbody>
                {!loading ? (
                  dataMedios?.map((el) => (
                    <>
                      {el.media_sources.map((i) => (
                        <tr>
                          <td
                            style={{
                              fontSize: "16px",
                              padding: "10px 0 10px 10px",
                            }}
                          >
                            {i.name}
                          </td>

                          <td
                            style={{
                              textAlign: "center",
                              width: "200px",
                              padding: "10px 0 10px 10px",
                            }}
                          >
                            <Form.Check aria-label="option 1" />
                          </td>
                        </tr>
                      ))}
                    </>
                  ))
                ) : (
                  <LoadingCard />
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      <Form onSubmit={handleUserForm}>
        <Row>
          <Col lg={12}>
            <p className="title">Solicitar nuevo medio:</p>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nombre del medio</Form.Label>
              <Form.Control
                type="text"
                defaultValue={nameSource}
                onChange={handleSourceInvitation}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Form.Group
              style={{ marginBottom: "0px" }}
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Urls o links de las secciónes de interes separados por coma o
                saltos de línea (enter)
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                defaultValue={listSource}
                onChange={handleListInvitation}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginTop: "25px", justifyContent: "space-between"  }}>
          <Col lg={8}>
            <div style={{ display: "flex", flexDirection: "row"}}>
              <MdWarning style={{ color: "#F62D54" }} size={35} />
              <p className="warning">
                Este proceso no es automático, requiere tareas de desarrollo,
                testeo y validación. El tiempo de implemenetación, dependiendo la
                demanda será de al menos 72hs.
              </p>
            </div>
          </Col>
          <Col lg={3} style={{textAlign:"end"}}>
            <Button variant="primary" type="submit">
              ENVIAR
            </Button>
          </Col>
        </Row>
      </Form>
    </StyledMedios>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    token: state.token,
    url: state.url,
    user_data: state.user_data,
    new_pass_status: state.new_pass_status,
    new_data_user_success: state.new_data_user_success,
    list_users: state.list_users,
    list_users_post_sucess: state.list_users_post_sucess,
    medios_user_qsch: state.medios_user_qsch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMessagesFilter: (items) => dispatch(getMessagesFilter(items)),
    
    getListData: () => dispatch(getListData()),
    getListInvitation: (newUser) => dispatch(getListInvitation(newUser)),
    getListInvitationDelete: (id) => dispatch(getListInvitationDelete(id)),
    getMediosUserQsch: () => dispatch(getMediosUserQsch()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediosQsch);
