import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
/* Styled */
import { StyledMedios, StyledListHelp } from "./styled";
import { getMonitoreoUser, postMonitoreoUser } from "src/store";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import { StyledMessageAccordion } from "./styled";
import Card from "react-bootstrap/Card";
import { FaChevronDown, FaChevronUp, FaQuestionCircle } from "react-icons/fa";
import buzzumo1 from "src/assets/img/buzzumo1.png";
import buzzumo2 from "src/assets/img/buzzumo2.png";
import buzzumo3 from "src/assets/img/buzzumo3.png";


function MonitoreoUsers(props) {
  const [dataMonitoreo, setDataMonitoreo] = useState();
  const [buzzsumo, setbuzzsumo] = useState();
  const [youtubeAk, setyoutubeAk] = useState();
  const [youtubePl, setyoutubePl] = useState();
  const [crowdtangle, setcrowdtangle] = useState();
  const [twitterCk, settwitterCk] = useState();
  const [twitterCs, settwitterCs] = useState();
  const [twitterAt, settwitterAt] = useState();
  const [twitterAts, settwitterAts] = useState();
  const [twitterLi, settwitterLi] = useState();

  const [newbuzzsumo, setnewbuzzsumo] = useState();
  const [newyoutubeAk, setnewyoutubeAk] = useState();
  const [newyoutubePl, setnewyoutubePl] = useState();
  const [newcrowdtangle, setnewcrowdtangle] = useState();
  const [newtwitterCk, setnewtwitterCk] = useState();
  const [newtwitterCs, setnewtwitterCs] = useState();
  const [newtwitterAt, setnewtwitterAt] = useState();
  const [newtwitterAts, setnewtwitterAts] = useState();
  const [newtwitterLi, setnewtwitterLi] = useState();

  const [generalError, setGeneralError] = useState(false);
  const [errorbuzzsumo, seterrorbuzzsumo] = useState(false);
  const [erroryoutubeAk, seterroryoutubeAk] = useState(false);
  const [erroryoutubePl, seterroryoutubePl] = useState(false);
  const [errorcrowdtangle, seterrorcrowdtangle] = useState(false);
  const [errortwitterCk, seterrortwitterCk] = useState(false);
  const [errortwitterCs, seterrortwitterCs] = useState(false);
  const [errortwitterAt, seterrortwitterAt] = useState(false);
  const [errortwitterAts, seterrortwitterAts] = useState(false);
  const [errortwitterLi, seterrortwitterLi] = useState(false);

  const [openUno, setOpenUno] = useState(false);
  const [openDos, setOpenDos] = useState(false);
  const [openTres, setOpenTres] = useState(false);
  const [openCuatro, setOpenCuatro] = useState(false);
  const [openCinco, setOpenCinco] = useState(false);
  const [openSeis, setOpenSeis] = useState(false);

  const [ayudaUno, setAyudaUno] = useState(false);
  const [ayudaDos, setAyudaDos] = useState(false);
  const [ayudaTres, setAyudaTres] = useState(false);
  const [ayudaCuatro, setAyudaCuatro] = useState(false);
  const [ayudaCinco, setAyudaCinco] = useState(false);
  const [ayudaSeis, setAyudaSeis] = useState(false);

  useEffect(() => {
    try {
      if (props.login) {
        const fetchUser = async () => {
          await props.getMonitoreoUser();
        };
        fetchUser();
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [props.login]);

  useEffect(() => {
    if (props.monitoreo_user) {
      setDataMonitoreo(props.monitoreo_user);

      const buzzsumo = props.monitoreo_user.map((el) => el.buzzsumo_rss_feed);
      setbuzzsumo(buzzsumo);

      const youtubePl = props.monitoreo_user.map(
        (el) => el.youtube_playlist_id
      );
      setyoutubePl(youtubePl);

      const youtubeApi = props.monitoreo_user.map((el) => el.youtube_api_key);
      setyoutubeAk(youtubePl);

      const crowdtangle = props.monitoreo_user.map(
        (el) => el.crowdtangle_api_key
      );
      setcrowdtangle(crowdtangle);

      const twitterCk = props.monitoreo_user.map(
        (el) => el.twitter_consumer_key
      );
      settwitterCk(twitterCk);

      const twitterCs = props.monitoreo_user.map(
        (el) => el.twitter_consumer_secret
      );
      settwitterCs(twitterCk);

      const twitterAt = props.monitoreo_user.map(
        (el) => el.twitter_access_token
      );
      settwitterAt(twitterAt);

      const twitterAts = props.monitoreo_user.map(
        (el) => el.twitter_access_token_secret
      );
      settwitterAts(twitterAts);

      const twitterLi = props.monitoreo_user.map((el) => el.twitter_list_id);
      settwitterLi(twitterLi);
    }
  }, [props.monitoreo_user]);

  useEffect(() => {
    if (props.monitoreo_user_app_error.length > 0) {
      setGeneralError(true);
    }
  }, [props.monitoreo_user_app_error, generalError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.postMonitoreoUser({
      buzzsumo_rss_feed: buzzsumo,
      youtube_api_key: youtubeAk,
      youtube_playlist_id: youtubePl,
      crowdtangle_api_key: crowdtangle,
      twitter_consumer_key: twitterCk,
      twitter_consumer_secret: twitterCs,
      twitter_access_token: twitterAt,
      twitter_access_token_secret: twitterAts,
      twitter_list_id: twitterLi,
    });
  };

  const handleBuzzsumo = (e) => {
    e.preventDefault();
    setbuzzsumo(e.target.value);
  };

  const handleYtAK = (e) => {
    e.preventDefault();
    setyoutubeAk(e.target.value);
  };

  const handleYtPi = (e) => {
    e.preventDefault();
    setyoutubePl(e.target.value);
  };

  const handleCrowdtangle = (e) => {
    e.preventDefault();
    setcrowdtangle(e.target.value);
  };

  const handleTwitterCk = (e) => {
    e.preventDefault();
    settwitterCk(e.target.value);
  };

  const handleTwitterCs = (e) => {
    e.preventDefault();
    settwitterCs(e.target.value);
  };

  const handleTwitterAt = (e) => {
    e.preventDefault();
    settwitterAt(e.target.value);
  };

  const handleTwitterAts = (e) => {
    e.preventDefault();
    settwitterAts(e.target.value);
  };

  const handleTwitterLi = (e) => {
    e.preventDefault();
    settwitterLi(e.target.value);
  };

  const handleRemoveAll = (e) => {
    e.preventDefault();
    setbuzzsumo("");
    setyoutubeAk("");
    setyoutubePl("");
    setcrowdtangle("");
    settwitterCk("");
    settwitterCs("");
    settwitterAt("");
    settwitterAts("");
    settwitterLi("");
  };

  const handleAyudaUno = () => {
    setAyudaUno(true);
    setAyudaDos(false);
    setAyudaTres(false);
    setAyudaCuatro(false);
    setAyudaCinco(false);
    setAyudaSeis(false);
  };

  const handleAyudaDos = () => {
    setAyudaUno(false);
    setAyudaDos(true);
    setAyudaTres(false);
    setAyudaCuatro(false);
    setAyudaCinco(false);
    setAyudaSeis(false);
  };

  const handleAyudaTres = () => {
    setAyudaUno(false);
    setAyudaDos(false);
    setAyudaTres(true);
    setAyudaCuatro(false);
    setAyudaCinco(false);
    setAyudaSeis(false);
  };

  const handleAyudaCuatro = () => {
    setAyudaUno(false);
    setAyudaDos(false);
    setAyudaTres(false);
    setAyudaCuatro(true);
    setAyudaCinco(false);
    setAyudaSeis(false);
  };

  const handleAyudaCinco = () => {
    setAyudaUno(false);
    setAyudaDos(false);
    setAyudaTres(false);
    setAyudaCuatro(false);
    setAyudaCinco(true);
    setAyudaSeis(false);
  };

  const handleAyudaSeis = () => {
    setAyudaUno(false);
    setAyudaDos(false);
    setAyudaTres(false);
    setAyudaCuatro(false);
    setAyudaCinco(false);
    setAyudaSeis(true);
  };

  return (
    <StyledMedios>
      <Row>
        <Col lg={8}>
          <h3>Credenciales para uso de aplicaciones</h3>
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: " flex",
                flexDirection: "row",
                alignItems: "baseline",
              }}
            >
              {/* <StyledMessageAccordion defaultActiveKey="1">
                <Accordion.Item
                  as={Card.Header}
                  eventKey="1"
                  onClick={() => setOpenUno(!openUno)}
                  className="card-header-1"
                >
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <div lg={12} className="resetpass">
                          {openUno ? (
                            <FaChevronDown size={20} />
                          ) : (
                            <FaChevronUp size={20} />
                          )}{" "}
                          Buzzumo{" "}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Card.Body>
                    <Col md={11} lg={12} className="form-contact">
                      <Form inline>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Row>
                            <Col lg={5}>
                              <Form.Label>Link RSS del feed</Form.Label>
                            </Col>
                            <Col lg={7}>
                              <Form.Control
                                type="password"
                                value={buzzsumo}
                                onChange={handleBuzzsumo}
                                required
                                className="form-control-monitoreo"
                              />
                              {props.monitoreo_user_app_error.indexOf(
                                "buzzsumo_rss_feed"
                              ) > -1 ? (
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    fontSize: "11px",
                                    marginTop: "6px",
                                    color: "red",
                                  }}
                                >
                                  Se produjo un error en este campo
                                </p>
                              ) : null}
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Card.Body>
                </Accordion.Item>
              </StyledMessageAccordion>
            </div>

            <div
              style={{
                display: " flex",
                flexDirection: "row",
                alignItems: "baseline",
              }}
            >
             {/*  <StyledMessageAccordion defaultActiveKey="1">
                <Accordion.Item
                  as={Card.Header}
                  eventKey="1"
                  onClick={() => setOpenDos(!openDos)}
                >
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <div lg={12} className="resetpass">
                          {openDos ? (
                            <FaChevronDown size={20} />
                          ) : (
                            <FaChevronUp size={20} />
                          )}
                          Crowdtangle{" "}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Card.Body>
                    <Col md={11} lg={11} className="form-contact">
                      <Form inline>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Row>
                            <Col lg={5}>
                              <Form.Label>API Key</Form.Label>
                            </Col>
                            <Col lg={7}>
                              <Form.Control
                                type="password"
                                value={crowdtangle}
                                onChange={handleCrowdtangle}
                                required
                                className="form-control-monitoreo"
                              />
                              {props.monitoreo_user_app_error.indexOf(
                                "crowdtangle_api_key"
                              ) > -1 ? (
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    fontSize: "11px",
                                    marginTop: "6px",
                                    color: "red",
                                  }}
                                >
                                  Se produjo un error en este campo
                                </p>
                              ) : null}
                            </Col>
                          </Form.Row>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Card.Body>
                </Accordion.Item>
              </StyledMessageAccordion> */}
            </div>
            {/* <StyledMessageAccordion defaultActiveKey="1">
              <Accordion.Item
                as={Card.Header}
                eventKey="1"
                onClick={() => setOpenTres(!openTres)}
              >
                <Row>
                  <Col lg={12}>
                    <Row>
                      <div lg={12} className="resetpass">
                        {openTres ? (
                          <FaChevronDown size={20} />
                        ) : (
                          <FaChevronUp size={20} />
                        )}{" "}
                        Twitter{" "}
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Card.Body>
                  <Col md={11} lg={11} className="form-contact">
                    <Form inline>
                      <Form.Group
                        controlId="formBasicEmail"
                        style={{ marginBottom: "20px" }}
                      >
                        <Form.Row>
                          <Col lg={5}>
                            <Form.Label>Consumer key</Form.Label>
                          </Col>
                          <Col lg={7}>
                            <Form.Control
                              type="password"
                              value={twitterCk}
                              onChange={handleTwitterCk}
                              required
                              className="form-control-monitoreo"
                            />
                            {props.monitoreo_user_app_error.indexOf(
                              "twitter_consumer_key"
                            ) > -1 ? (
                              <p
                                style={{
                                  marginBottom: "0px",
                                  fontSize: "11px",
                                  marginTop: "6px",
                                  color: "red",
                                }}
                              >
                                Se produjo un error en este campo
                              </p>
                            ) : null}
                          </Col>
                        </Form.Row>
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicEmail"
                        style={{ marginBottom: "20px" }}
                      >
                        <Form.Row>
                          <Col lg={5}>
                            <Form.Label>Consumer secret</Form.Label>
                          </Col>
                          <Col lg={7}>
                            <Form.Control
                              type="password"
                              value={twitterCs}
                              onChange={handleTwitterCs}
                              required
                              className="form-control-monitoreo"
                            />
                            {props.monitoreo_user_app_error.indexOf(
                              "twitter_consumer_secret"
                            ) > -1 ? (
                              <p
                                style={{
                                  marginBottom: "0px",
                                  fontSize: "11px",
                                  marginTop: "6px",
                                  color: "red",
                                }}
                              >
                                Se produjo un error en este campo
                              </p>
                            ) : null}
                          </Col>
                        </Form.Row>
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicEmail"
                        style={{ marginBottom: "20px" }}
                      >
                        <Form.Row>
                          <Col lg={5}>
                            <Form.Label>Access token</Form.Label>
                          </Col>
                          <Col lg={7}>
                            <Form.Control
                              type="password"
                              value={twitterAt}
                              onChange={handleTwitterAt}
                              required
                              className="form-control-monitoreo"
                            />
                            {props.monitoreo_user_app_error.indexOf(
                              "twitter_access_token"
                            ) > -1 ? (
                              <p
                                style={{
                                  marginBottom: "0px",
                                  fontSize: "11px",
                                  marginTop: "6px",
                                  color: "red",
                                }}
                              >
                                Se produjo un error en este campo
                              </p>
                            ) : null}
                          </Col>
                        </Form.Row>
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicEmail"
                        style={{ marginBottom: "20px" }}
                      >
                        <Form.Row>
                          <Col lg={5}>
                            <Form.Label>Access token secret</Form.Label>
                          </Col>
                          <Col lg={7}>
                            <Form.Control
                              type="password"
                              value={twitterAts}
                              onChange={handleTwitterAts}
                              required
                              className="form-control-monitoreo"
                            />
                            {props.monitoreo_user_app_error.indexOf(
                              "twitter_access_token_secret"
                            ) > -1 ? (
                              <p
                                style={{
                                  marginBottom: "0px",
                                  fontSize: "11px",
                                  marginTop: "6px",
                                  color: "red",
                                }}
                              >
                                Se produjo un error en este campo
                              </p>
                            ) : null}
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Form>
                  </Col>
                </Card.Body>
              </Accordion.Item>
            </StyledMessageAccordion>

            <StyledMessageAccordion defaultActiveKey="1">
              <Accordion.Item
                as={Card.Header}
                eventKey="1"
                onClick={() => setOpenCuatro(!openCuatro)}
              >
                <Row>
                  <Col lg={12}>
                    <Row>
                      <div lg={12} className="resetpass">
                        {openCuatro ? (
                          <FaChevronDown size={20} />
                        ) : (
                          <FaChevronUp size={20} />
                        )}{" "}
                        Youtube{" "}
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Card.Body>
                  <Col md={11} lg={11} className="form-contact">
                    <Form inline>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Row>
                          <Col lg={5}>
                            <Form.Label>Developer key</Form.Label>
                          </Col>
                          <Col lg={7}>
                            <Form.Control
                              type="password"
                              value={youtubeAk}
                              onChange={handleYtAK}
                              required
                              className="form-control-monitoreo"
                            />
                            {props.monitoreo_user_app_error.indexOf(
                              "youtube_api_key"
                            ) > -1 ? (
                              <p
                                style={{
                                  marginBottom: "0px",
                                  fontSize: "11px",
                                  marginTop: "6px",
                                  color: "red",
                                }}
                              >
                                Se produjo un error en este campo
                              </p>
                            ) : null}
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Form>
                  </Col>
                </Card.Body>
              </Accordion.Item>
            </StyledMessageAccordion>
            <h3 style={{marginTop: "40px"}}>Credenciales para uso de aplicaciones</h3>

            <StyledMessageAccordion defaultActiveKey="1">
              <Accordion.Item
                as={Card.Header}
                eventKey="1"
                onClick={() => setOpenCinco(!openCinco)}
              >
                <Row>
                  <Col lg={12}>
                    <Row>
                      <div lg={12} className="resetpass">
                        {openCinco ? (
                          <FaChevronDown size={20} />
                        ) : (
                          <FaChevronUp size={20} />
                        )}{" "}
                        Twitter{" "}
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Card.Body>
                  <Col md={11} lg={11} className="form-contact">
                    <Form inline>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Row>
                          <Col lg={5}>
                            <Form.Label>Twitter list id</Form.Label>
                          </Col>
                          <Col lg={7}>
                            <Form.Control
                              type="password"
                              value={twitterLi}
                              onChange={handleTwitterLi}
                              required
                              className="form-control-monitoreo"
                            />
                            {props.monitoreo_user_app_error.indexOf(
                              "twitter_list_id"
                            ) > -1 ? (
                              <p
                                style={{
                                  marginBottom: "0px",
                                  fontSize: "11px",
                                  marginTop: "6px",
                                  color: "red",
                                }}
                              >
                                Se produjo un error en este campo
                              </p>
                            ) : null}
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Form>
                  </Col>
                </Card.Body>
              </Accordion.Item>
            </StyledMessageAccordion>

            <StyledMessageAccordion defaultActiveKey="1">
              <Accordion.Item
                as={Card.Header}
                eventKey="1"
                onClick={() => setOpenSeis(!openSeis)}
              >
                <Row>
                  <Col lg={12}>
                    <Row>
                      <div lg={12} className="resetpass">
                        {openSeis ? (
                          <FaChevronDown size={20} />
                        ) : (
                          <FaChevronUp size={20} />
                        )}{" "}
                        Youtube{" "}
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Card.Body>
                  <Col md={11} lg={11} className="form-contact">
                    <Form inline>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Row>
                          <Col lg={5}>
                            <Form.Label>URL (lista de reproucción)</Form.Label>
                          </Col>
                          <Col lg={7}>
                            <Form.Control
                              type="password"
                              value={youtubePl}
                              onChange={handleYtPi}
                              required
                              className="form-control-monitoreo"
                            />
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Form>
                  </Col>
                </Card.Body>
              </Accordion.Item>
            </StyledMessageAccordion> */}
            <div style={{ marginTop: "50px", textAlign: "center" }}>
              <button
                variant="primary"
                type="submit"
                style={{ marginRight: "20px" }}
                className="btn-cancelar"
                onClick={(e) => handleRemoveAll(e)}
              >
                Cancelar
              </button>
              <button variant="primary" type="submit" className="btn-guardar">
                Guardar
              </button>
            </div>
          </Form>
        </Col>

        {/* <Col
          lg={6}
          style={{ background: "#E6ECF5", height: "900px" }}
          className="ayuda"
        >
          <p className="titulo-ayuda">
            <FaQuestionCircle /> Ayuda
          </p>
          {ayudaUno ? (
            <StyledListHelp>
              <li>
                <p>
                  <b>1-</b> Ingrese a su cuenta de Buzzsumo, diríjase a
                  Discover/Trending
                </p>
              </li>
              <li style={{ textAlign: "center" }}>
                <img src={buzzumo1} style={{ width: "400px" }} />
              </li>
              <li>
                <p>
                  <b>2-</b> Una vez allí, sobre la barra lateral izquierda,
                  ingrese a Custom Feed (crearlo si no existe)
                </p>
              </li>
              <li style={{ textAlign: "center" }}>
                <img src={buzzumo2} style={{ width: "200px" }} />
              </li>
              <li>
                <p>
                  <b>3-</b> Sobre la parte superior derecha encontrará el botón
                  de RSS que nos entregará el enlace necesario para monitorear
                  el feed
                </p>
              </li>
              <li style={{ textAlign: "center" }}>
                <img src={buzzumo3} style={{ width: "430px" }} />
              </li>
            </StyledListHelp>
          ) : null}
          {ayudaDos ? <p>AYUDA 2</p> : null}
          {ayudaTres ? <p>AYUDA 3</p> : null}
          {ayudaCuatro ? (
            <StyledListHelp>
              <li>
                <p>
                  <b>1-</b> Necesitas una cuenta de Google para acceder a la
                  Consola de la API de Google, solicitar una clave de API y
                  registrar tu aplicación.
                </p>
              </li>
              <li>
                <p>
                  <b>2-</b> Registra tu aplicación en Google para que pueda
                  enviar solicitudes de API.
                </p>
              </li>
              <li>
                <p>
                  <b>3-</b> Después de registrar tu aplicación, selecciona la
                  API de datos de YouTube como uno de los servicios que utiliza
                  tu aplicación:
                  <ul style={{ padding: "25px" }}>
                    <li>
                      <b>i -</b> Ve a la consola de la API y selecciona el
                      proyecto que acabas de registrar.
                    </li>
                    <li>
                      <b>ii -</b> Haz clic en el panel Servicios.
                    </li>
                    <li>
                      <b>iii -</b> En la lista de las API, busca la API de datos
                      de YouTube y cambia el estado a ON.
                    </li>
                  </ul>
                </p>
              </li>
              <li>
                <p>
                  <b>3-</b> Aprende los conceptos básicos del formato de datos
                  JSON (JavaScript Object Notation). JSON es un formato de datos
                  común e independiente del lenguaje que proporciona una
                  representación de texto simple de estructuras de datos
                  arbitrarias. Para obtener más información, visita la página
                  json.org.
                </p>
              </li>
              <li>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    width="320"
                    height="315"
                    src={`https://www.youtube.com/embed/Im69kzhpR3I`}
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </li>
            </StyledListHelp>
          ) : null}
          {ayudaCinco ? <p>AYUDA 5</p> : null}
          {ayudaSeis ? <p>AYUDA 6</p> : null}
        </Col> */}
      </Row>
    </StyledMedios>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    token: state.token,
    url: state.url,
    user_data: state.user_data,
    new_pass_status: state.new_pass_status,
    new_data_user_success: state.new_data_user_success,
    list_users: state.list_users,
    list_users_post_sucess: state.list_users_post_sucess,
    monitoreo_user: state.monitoreo_user,
    monitoreo_user_app_error: state.monitoreo_user_app_error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postMonitoreoUser: (items) => dispatch(postMonitoreoUser(items)),
    getMonitoreoUser: () => dispatch(getMonitoreoUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MonitoreoUsers);
