import React from "react";

export default function Logo({ fillColor, backgroundColor }) {
    return (
      <svg
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        width="28"
        height="28"
      >
        <circle cx="100" cy="100" r="100" fill="#fff" />
        <path
          className="cls-1"
          d="m149.24,120.28c-3.37-2.34-8.94-9.97-8.94-35.61,0-4.87-.57-9.18-1.57-13.01-.37-2.02-2.13-3.55-4.26-3.55-2.39,0-4.33,1.94-4.33,4.33,0,.54.1,1.05.28,1.53,0,0,0,0,0,0,.79,3.06,1.23,6.52,1.23,10.4,0,11.63,1.07,20.99,3.32,28.2.33,1.03,2.41,7.32,5.54,11.08h.08c1.17,1.47,2.49,2.64,3.81,3.52,1.03.73,1.76,1.91,1.76,3.08,0,2.05-1.61,3.66-3.66,3.66H57.64c-2.05,0-3.66-1.61-3.66-3.66,0-.88.59-2.05,1.61-2.78,8.5-5.86,12.75-20.23,12.75-42.94,0-21.25,13.19-29.46,24.18-32.54l.29-.15s3.08-1.03,7.33-1.03h0s.08,0,.12-.01c2.18-.14,3.91-1.95,3.91-4.17s-1.66-3.96-3.78-4.16c-.1-.01-.2-.02-.31-.02h-.04s-.03,0-.04,0c0,0,0,0-.01,0,0,0-.01,0-.02,0-5.12,0-10.01.99-14.5,2.77,0,0,0,.02,0,.03-11.29,4.4-25.79,14.8-25.79,39.42s-5.57,33.27-8.94,35.61c-3.52,2.34-5.57,6.3-5.57,10.41,0,6.89,5.57,12.46,12.46,12.46h24.48c1.76,9.23,9.97,15.97,19.35,15.97s17.59-6.74,19.35-15.97h21.54c6.89,0,12.46-5.57,12.46-12.46,0-4.1-2.05-8.06-5.57-10.41Zm-47.78,30.04c-3.52,0-6.74-1.76-8.79-4.4h17.59c-2.05,2.64-5.28,4.4-8.79,4.4Z"
        />
      </svg>
    );
  }