import React, {useEffect, useState} from "react";
import { connect } from "react-redux";

/* Styled */
import { StyledMenu } from "./styled";
import {getCardsRequestDefault, getCategory, getPage, getOrder} from 'src/store';


function Menu(props) {

  const [active, setActive] = useState("Todos");
  const [disabled, setdisabled] = useState(false);

  useEffect(() => {
    if(!props.loading) {
      setTimeout(() => {
        setdisabled(false)
      }, 700);
    } else {
      setdisabled(true)
    }
  }, [props.loading])


  const handleMenuClean = (value) => {
    setActive(value)
    props.getCategory(null)
    props.getPage(1)
    props.getOrder("-relevance_score")
    props.getCardsRequestDefault({media_source : "all", days: 3, order: props.order, page: props.page, category: null, search: props.search, speaker: props.search_name, title: props.search_title })
  }

    const handleMenu = (code, name) => {
      setActive(name);
      props.getCategory(code);
      props.getPage(1);
      props.getOrder("-relevance_score");
      props.getCardsRequestDefault({
        media_source: props.media_source,
        days: props.days,
        order: props.order,
        page: props.page,
        category: code,
        speaker: props.search_name,
        title: props.search_title,
      });
    };


  return (
    <StyledMenu fluid id="homeqsch">
      {props.login ? (
        <div className="navbar navbar-expand-lg navbar-light" style={{borderBottom: "none"}}>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ background: "white", ouline: "0" }}
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "black", ouline: "0" }}
            ></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav mr-auto">
              <li
                className="nav-item "
                onClick={() => handleMenuClean("Todos")}
              >
                {active == "Todos" ? (
                  <a className="nav-link active">Todos</a>
                ) : (
                  <a className="nav-link">Todos</a>
                )}
              </li>
              {Object.keys(props.filter).length !== 0 &&
                props.filter.categories.map(([code, name], index) => (
                  <button
                    key={index}
                    disabled={props.disabled}
                    className={props.disabled ? "disabled" : null}
                    onClick={() =>
                      props.disabled ? null : handleMenu(code, name)
                    }
                  >
                    <li
                      className={`nav-item ${name === active ? "active" : ""}`}
                    >
                      <a className="nav-link">{name}</a>
                    </li>
                  </button>
                ))}
              {/* {Object.keys(props.filter).length != 0 && props.filter.categories.map((el, index) => (
                 <button disabled={disabled} className={disabled ? "disabled" : null} key={index}>
                  <li className="nav-item " onClick={()=> disabled ? null : handleMenu(el[0], el[1])} key={index}>
                    <a className={el[1] == active ? "nav-link active " : "nav-link"}>{el[1]}</a>
                  </li>
                </button>
              ))} */}
            </ul>
          </div>
        </div>
      ) : null}
    </StyledMenu>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    token: state.token,
    filter: state.filter,
    days: state.days,
    media_source: state.media_source,
    order: state.order,
    page: state.page,
    search: state.search,
    category: state.category,
    search_name: state.search_name,
    loading: state.loading,
    search_title: state.search_title
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCardsRequestDefault: (arg) => dispatch(getCardsRequestDefault(arg)),
    getCategory: cat => dispatch(getCategory(cat)),
    getPage: page => dispatch(getPage(page)),
    getOrder: (order) => dispatch(getOrder(order))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
