import React, { useState, useEffect } from "react";

/* Redux */
import { connect } from "react-redux";
import {
  getDesgrabador,
  getTextosDesgrabador,
  resetDesgrabador,
} from "src/store";

/* Estilo */
import { StyledBackground } from "../styled";

/* Bootsrap y MD */
import { MdContentCopy } from "react-icons/md";
import { Row, Col, Form } from "react-bootstrap";
import { IoIosRefresh } from "react-icons/io";

/* Components*/
import Logo from "src/components/Desgrabador/Logo";
import LoadingCard from "src/components/LoadingCard";

/* Assets */
import not from "src/assets/img/not.png";
import error from "src/assets/img/error.png";

function HomeDesgrabador(props) {
  const [desgrabacion, setDesgrabacion] = useState(false);
  const [copySuccess, setCopySuccess] = useState(null);

  const [loading, setLoading] = useState(true);
  const [ytUrl, setYtUrl] = useState("");

  const [selectedLanguage, setSelectedLanguage] = useState("es");

  /* useEffect(() => {
  const subtitles = props.desgrabador?.subtitles?.[selectedLanguage];

  if (subtitles && subtitles.length > 0 && selectedLanguage === "es") {
    // Filtra las frases según el idioma seleccionado
    const textArray = subtitles.map((el) => el.text);
    props.getTextosDesgrabador(textArray);
  }

  setLoading("resolve");
}, [props.desgrabador, selectedLanguage]);
 */

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(false);

    try {
      const lang = selectedLanguage;
      await props.getDesgrabador(ytUrl, lang);

      setLoading(true);
      setDesgrabacion(true);
    } catch (error) {
      console.log("error al desbrabar", error);
    }
  };

  const cleanInput = () => {
    props.resetDesgrabador();
    setYtUrl("");
    setDesgrabacion(false);
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguage(newLanguage);
    props.getDesgrabador(ytUrl, newLanguage);
    if (newLanguage === "es") {
      const subtitles = props.desgrabador?.subtitles?.[newLanguage];
      if (subtitles && subtitles.length > 0) {
        const textArray = subtitles.map((el) => el.text);
        props.getTextosDesgrabador(textArray);
      }
    }
  };

  const handleCopyClick = () => {
    const textToCopy = props.desgrabador?.subtitles
      ?.map((el) => el.text)
      .join(" ");

    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      setCopySuccess("¡Texto copiado!");
      alert("¡Texto copiado al portapapeles!");
    } catch (err) {
      console.error("Error al copiar el texto: ", err);
      setCopySuccess("Error al copiar el texto. Intenta manualmente.");
    }

    setTimeout(() => {
      document.body.removeChild(textArea);
      setCopySuccess(null);
    }, 3000);
  };

  const subtitles_api = {
    es: [props.desgrabador?.subtitles?.es],
    en: [props.desgrabador?.subtitles?.en],
    pt: [props.desgrabador?.subtitles?.pt],
  };

  const subtitleKeysWithElements = Object.keys(subtitles_api).filter(
    (key) => subtitles_api[key].length > 0
  );

  function getLanguageName(key) {
    switch (key) {
      case "es":
        return "Español";
      case "en":
        return "Inglés";
      case "pt":
        return "Portugués";
      default:
        return "Desconocido";
    }
  }

  return (
    <StyledBackground fluid={true}>
      <Row>
        <Col lg={12}>
          <div className="titulo">
            <Logo />
            <p>Desgrabador</p>
          </div>
        </Col>
      </Row>
      <Row style={{ paddingLeft: "50px", marginTop: "40px" }}>
        <Col lg={8}>
          <div id="title">
            <div>
              <h1
                className="mb-3"
                style={{ fontSize: "16px", color: "#354052" }}
              >
                ¿Qué queres desgrabar?
              </h1>
              <div className="row">
                <div className="col-md-12">
                  <form onSubmit={handleOnSubmit} className="form">
                    <div className="row">
                      <div
                        className="col-lg-12"
                        style={{ marginBottom: "10px" }}
                      >
                        <input
                          type="text"
                          name="youtube_url"
                          id="youtube-url-field"
                          className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                          placeholder="Ingresá la URL del video"
                          value={ytUrl}
                          required
                          onChange={(e) => setYtUrl(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-12 mt-2">
                        {/* <input
                          type="submit"
                          value="Ingresar"
                          className="btn big-btn text-white mx-auto shadow"
                          style={{
                            backgroundColor: "#FF3370",
                            borderColor: "#dc3545",
                            color: "#fff",
                          }}
                        /> */}
                        <button id="analizar" type="submit" className="enter">
                          Desgrabar
                        </button>
                        <button
                          id="analizar"
                          type="button"
                          className="borrar"
                          onClick={cleanInput}
                        >
                          Borrar
                        </button>
                      </div>
                    </div>
                  </form>
                  {!loading && !props.error ? <LoadingCard /> : null}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={props.error || !props.desgrabador ? 4 : 12}>
          {!props.error && desgrabacion ? (
            <div className="row">
              <div
                className="col-lg-8"
                style={{
                  marginTop: "50px",
                  background: "#fff",
                  padding: "35px",
                  borderRadius: "10px",
                }}
              >
                {subtitleKeysWithElements.length > 0 ? (
                  <div className="transcripcion" style={{ display: "flex" }}>
                    <div style={{ textAlign: "initial" }}>
                      <p style={{ color: "#000" }}>
                        <img src="https://storage.googleapis.com/area-desarrollo/frontend/PJ-03-0024%20-%20Desgrabador%20refresh/icon_buscar.svg" />{" "}
                        Usá Ctrl+F para buscar en la transcripción.
                      </p>
                      <p style={{ color: "#000" }}>
                        <img src="https://storage.googleapis.com/area-desarrollo/frontend/PJ-03-0024%20-%20Desgrabador%20refresh/icon_seleccion.svg" />
                        Si hacés click en las frases verás el momento del video
                        donde se dijeron.
                      </p>
                    </div>

                    <div>
                      <button className="btn-copy" onClick={handleCopyClick}>
                        <MdContentCopy style={{ marginRight: "5px" }} />
                        <b>Copiar transcripción</b>
                      </button>
                      <div className="select-idioma">
                        <Form.Select
                          onChange={handleLanguageChange}
                          style={{ fontSize: "12px", borderRadius: "20px" }}
                          placeholder="Opciones disponibles"
                        >
                          {subtitleKeysWithElements.map((key) => (
                            <option key={key} value={key}>
                              {getLanguageName(key)}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                ) : null}

                {props.desgrabador?.subtitles?.length > 0 ? (
                  <h5 className="text-justify py-5 texto">
                    {props.desgrabador.subtitles.map((el, index) => {
                      const labelInfo = props.label[index];
                      const isCurrentLanguage = selectedLanguage === "es";
                      const shouldApplyGreenClass =
                        labelInfo && labelInfo.label && isCurrentLanguage;

                      return (
                        <a
                          key={index}
                          href={`https://youtube.com/watch?v=${props.desgrabador.youtube_id}&t=${el.timestamp_seconds}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          data-toggle="tooltip"
                          title="Hacé click para ver esta frase en el video"
                          className={
                            shouldApplyGreenClass ? "green" : "frase_link"
                          }
                        >
                          {el.text + " "}
                        </a>
                      );
                    })}
                  </h5>
                ) : (
                  <div id="cartel-vacio" className="cartel-vacio">
                    <img src={not} />
                    <p style={{ color: "#354052" }}>
                      Este video no posee subtítulos para poder realizar la
                      desgrabación.
                    </p>
                  </div>
                )}
                <div style={{ textAlign: "center" }}>
                  <button
                    className="btn-reset"
                    type="button"
                    onClick={cleanInput}
                  >
                    <IoIosRefresh />
                    <b>Realizar otra desgrabación</b>
                  </button>
                </div>
              </div>
            </div>
          ) : props.error ? (
            // Mostrar mensaje de error si hay un error
            <div className="container py-3 px-0">
              <div className="alert alert-chq text-left">
                <div>
                  <p className="font-weight-bold">
                    {" "}
                    <img
                      src={error}
                      style={{ width: "25px", marginRight: "10px" }}
                    />
                    ¿No te funcionó?
                  </p>
                  <p style={{ marginLeft: "34px" }}>
                    <b>&#8226;</b> Revisá que la dirección que estás ingresando
                    sea de un video de YouTube y contenga la ID del video.
                    <br />
                    <b>&#8226;</b> Revisá que el video que estás intentando
                    transcribir tenga subtítulos disponibles, ya sea
                    tradicionales o generados automáticamente, aunque sean en
                    otro idioma.
                    <br />
                    <b>&#8226;</b> Revisá que el video no sea "Privado", sino
                    "Público" u "Oculto".
                    <br />
                  </p>
                  <p style={{ marginLeft: "34px" }}>
                    <b>
                      Si todo lo de arriba está bien, entonces es posible que el
                      Desgrabador esté con demasiadas tareas y el vínculo con
                      YouTube esté saturado. Volvé a intentarlo en un par de
                      horas, perdón.
                    </b>
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </Col>
      </Row>
    </StyledBackground>
  );
}

const mapStateToProps = (state) => {
  return {
    desgrabador: state.desgrabador,
    error: state.error,
    label: state.label,

    error_desgrabador: state.error_desgrabador,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDesgrabador: (video_url, lang) =>
      dispatch(getDesgrabador(video_url, lang)),
    getTextosDesgrabador: (sentences) =>
      dispatch(getTextosDesgrabador(sentences)),
    resetDesgrabador: () => dispatch(resetDesgrabador()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeDesgrabador);
