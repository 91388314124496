import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserData,
  getNewPass,
  getNewDataUser,
  errorNewPassword,
  errorNewData,
  getMenuUsuarios,
} from "src/store";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContent from "react-bootstrap/TabContent";
import TabPane from "react-bootstrap/TabPane";
import Nav from "react-bootstrap/Nav";
import { StyledUserPanel } from "../styled";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { StyledMessageAccordion, CircleIcon } from "../styled";
import { FaChevronDown, FaChevronUp, FaPen, FaUser } from "react-icons/fa";
import { VscDebugBreakpointData } from "react-icons/vsc";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import LoadingCard from "src/components/LoadingCard";
import ListadoUsuarios from "src/components/User/ListadoUsuarios";
import MediosQsch from "src/components/Qsch/Medios";
import RedesQsch from "src/components/Qsch/Redes";
import MonitoreoUsers from "src/components/Monitoreo/MonitoreoUsers";
import $ from "jquery";
import { IoMdSettings } from "react-icons/io";
import Modal from "react-bootstrap/Modal";

function HomeUser(props) {
  const [loading, setloading] = useState(true);
  const [data, setData] = useState();
  const [menuUsuario, setMenu] = useState();
  const [openUno, setOpenUno] = useState(false);
  const [queryPass, setqueryPass] = useState("");
  const [queryPassNew, setqueryPassNew] = useState("");
  const [queryPassConfirm, setqueryPassConfirm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [checkedOne, setCheckedOne] = useState(true);
  const [checkedTwo, setCheckedTwo] = useState(true);
const [passwordsMatch, setPasswordsMatch] = useState(true);
const [showSuccessModal, setShowSuccessModal] = useState(false);
const [showErrorModal, setShowErrorModal] = useState(false);
const [errorType, setErrorType] = useState("");
const [exitoType, setexitoType] = useState("");
const [showModal, setShowModal] = useState(false);
const [showModalError, setShowModalError] = useState(false);

const [datos, setDatos] = useState({
  nombre: props.user_data.length > 0 ? props.user_data[0].user.first_name : "",
  apellido: props.user_data.length > 0 ? props.user_data[0].user.last_name : "",
  email: props.user_data.length > 0 ? props.user_data[0].user.email : "",
});

useEffect(() => {
  if (props.user_data) {
    if (props.user_data.length > 0) {
      setDatos({
        nombre: props.user_data[0].user.first_name || "",
        apellido: props.user_data[0].user.last_name || "",
        email: props.user_data[0].user.email || "",
        username: props.user_data[0].user.username || "",
      });
    }
    setloading(false);
  }
}, [props.user_data, props.menu_usuarios, props.error_new_password]);

  useEffect(() => {
    try {
      if (props.login) {
        const fetchUser = async () => {
          await props.getUserData();
         // await props.getMenuUsuarios();
        };
        fetchUser();
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [props.login]);

  useEffect(() => {
    try {
      if (datos && Object.keys(datos).length != 0) {
        setloading(false);
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [datos]);

  useEffect(() => {
    setShowModal(props.new_pass_status);
    setShowModalError(props.error_new_password);
  }, [props.new_pass_status, props.error_new_password]);


    useEffect(() => {
      setShowModalError(props.error_new_password);
    }, [props.error_new_password]);


  const handleFilterPass = (e) => {
    e.preventDefault();
    setqueryPass(e.target.value);
  };

  const handleFilterPassNew = (e) => {
    e.preventDefault();
    setqueryPassNew(e.target.value);
  };

  const handleFilterPassConfirm = (e) => {
    e.preventDefault();
    setqueryPassConfirm(e.target.value);
  };


    const onCloseModaleExito = () => {
      props.errorNewPassword(false);
      setShowModal(false);
      setqueryPass("")
      setqueryPassConfirm("")
      setqueryPassNew("") 
    };


    const onCloseModaleError = () => {
       console.log("props.new_pass_status", props.error_new_password);
       setShowErrorModal(false);
       props.errorNewPassword(false);
    };

      const openModaleExito = () => {
      setShowSuccessModal(true);
      }


const handleSubmit = (e) => {
  e.preventDefault();

  if (arePasswordsEqual()) {
    props.getNewPass({
      old_password: queryPass,
      new_password: queryPassNew,
      confirm_new_password: queryPassConfirm,
    });

  } else {
    // Mostrar modal de error si hay un error
    setShowErrorModal(true);

    // Determinar el tipo de error
    if (queryPassNew !== queryPassConfirm) {
      // Contraseña antigua y de confirmación no coinciden
      setErrorType("Las nuevas contraseñas no coinciden");
    }
  }

  // Restablecer cualquier estado de error
  //props.errorNewPassword(false);
  props.errorNewData(false);
};



    const arePasswordsEqual = () => {
     return queryPassNew === queryPassConfirm;
    };

  const handleRemoveAll = () => {
    setqueryPass("");
    setqueryPassConfirm("");
    setqueryPassNew("");
    props.errorNewPassword(false);
    props.errorNewData(false);
  };

  const validatePasswordsMatch = (newPassword, confirmPassword) => {
    if (newPassword === confirmPassword) {
    }
    setPasswordsMatch(newPassword === confirmPassword);
  };

 const handleInputChange = (event) => {
   setDatos({
     ...datos,
     [event.target.name]: event.target.value,
   });
 };

  const handleSubmitData = (e) => {
    e.preventDefault();
    const nombre = props.user_data.map((el) => el.user.first_name).toString();
    const apellido = props.user_data.map((el) => el.user.last_name).toString();
    const email = props.user_data.map((el) => el.user.email).toString();
    const id = props.user_data.map((el) => el.id).toString();
    props.getNewDataUser(id, {
      first_name: datos.nombre ? datos.nombre : nombre,
      last_name: datos.apellido ? datos.apellido : apellido,
      email:  datos.email ? datos.email : email,
      qsch: checkedOne == false ? checkedOne : true,
      ylch: checkedTwo == false ? checkedTwo : true,
    });
  };

  const handleChangeOne = () => {
    setCheckedOne(!checkedOne);
  };

  const handleChangeTwo = () => {
    setCheckedTwo(!checkedTwo);
  };

  const handleRemoveUserData = (e) => {
    e.preventDefault();
    setCheckedOne(true);
    setCheckedTwo(true);
    setDatos({ nombre: "", apellido: "" });
    props.errorNewData(false);
  };

  return (
    <Container fluid={true} style={{ marginTop: "35px" }}>
      <Row>
        <Col lg={12}>
          <div className="titulo" style={{ display: "flex", alignItems: "center"}}>
            <CircleIcon className="circle" style={{ padding: "2px" }}>
              <IoMdSettings
                size={23}
                style={{ fill: "#fff", marginRight: "8px" }}
              />
            </CircleIcon>

            <p
              style={{ fontSize: "16px", marginBottom: "0", color: "#354052" }}
            >
              Ajustes
            </p>
          </div>
        </Col>
      </Row>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <StyledUserPanel>
          {/* <Col lg={3} className="izq-panel">
            <h3 className="padding">CONFIGURACIÓN</h3>
            <p className="menu">Sistema</p>
            <Nav variant="pills" className="flex-column">
              {!loading ? (
                role != "member" ? (
                  <>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        style={{ paddingLeft: "60px" }}
                      >
                        <VscDebugBreakpointData style={{ fontSize: "10px" }} />{" "}
                        Perfil
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        style={{ paddingLeft: "60px" }}
                      >
                        <VscDebugBreakpointData style={{ fontSize: "10px" }} />{" "}
                        Miembros
                      </Nav.Link>
                    </Nav.Item>

                    <p className="menu">Aplicaciones</p>
                    <>
                      {props.menu_usuarios
                        .map((el) => el.modules)
                        .map((i) => (
                          <>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="five"
                                style={{ paddingLeft: "60px" }}
                              >
                                <VscDebugBreakpointData
                                  style={{ fontSize: "10px" }}
                                />{" "}
                                {i[1]}
                              </Nav.Link>
                            </Nav.Item>
                            <p
                              style={{
                                paddingLeft: "60px",
                                marginTop: "6px",
                                marginBottom: "6px",
                                fontSize: "16px",
                                color: "#000",
                              }}
                            >
                              <VscDebugBreakpointData
                                style={{ fontSize: "10px" }}
                              />{" "}
                              ¿Que se chequea hoy?
                            </p>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="tree"
                                style={{ paddingLeft: "110px" }}
                              >
                                {i[2]}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="four"
                                style={{ paddingLeft: "110px" }}
                              >
                                {i[3]}
                              </Nav.Link>
                            </Nav.Item>
                          </>
                        ))}
                    </>
                  </>
                ) : null
              ) : null}
            </Nav>
          </Col> */}
          <Col lg={12} className="der-panel">
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Row>
                  {!loading ? (
                    <>
                      <Col md={6} lg={6}>
                        <h2
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: "10px",
                            marginBottom: "20px"
                          }}
                        >
                          <p style={{fontSize: "18px", marginBottom: "0"}}>
                            <FaUser style={{ fontSize: "16px", paddingBottom: "2px" }} />{" "}
                            {datos.username}
                          </p>
                        </h2>
                        <h3>Información personal:</h3>
                        <Row>
                          <Col md={12} lg={12}>
                            <Form
                              onSubmit={handleSubmitData}
                              style={{ marginBottom: "25px" }}
                            >
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Nombre</Form.Label>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Form.Control
                                    type="text"
                                    name="nombre"
                                    style={{ width: "70%" }}
                                    value={datos.nombre}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </Form.Group>

                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Apellido</Form.Label>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Form.Control
                                    type="text"
                                    name="apellido"
                                    style={{ width: "70%" }}
                                    value={datos.apellido}
                                    //  placeholder={el.user.last_name}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Email</Form.Label>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Form.Control
                                    type="text"
                                    name="email"
                                    style={{ width: "70%" }}
                                    value={datos.email}
                                    //  placeholder={el.user.email}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </Form.Group>
                              {/* <ul>
                                  <li style={{ marginBottom: "20px" }}>
                                    <p>Organización:</p>
                                    <span>{el.organization.name}</span>
                                  </li>
                                  <li>
                                    <p> Pais:</p>
                                    <span>{el.organization.country.name}</span>
                                  </li>
                                </ul>
                                <h3>Información de contacto:</h3>
                                <ul>
                                  <li>
                                    E-mail:
                                    <br />
                                    <span>{el.user.email}</span>
                                  </li>
                                </ul> */}
                              <Row>
                                <Col md={6} lg={8}>
                                  {/*<div className="notificaciones">
                                      <p>
                                        <b>Notificaciones</b>
                                      </p>
                                      <Form.Check
                                        type="checkbox"
                                        id="customControlAutosizing"
                                        label="Resumen de frases de los ultimos dias"
                                        value={checkedOne}
                                        onChange={handleChangeOne}
                                      />
                                      <Form.Check
                                        type="checkbox"
                                        id="customControlAutosizing"
                                        label="Ya lo chequeamos"
                                        value={checkedTwo}
                                        onChange={handleChangeTwo}
                                      /> 
                                    </div>*/}
                                </Col>
                              </Row>
                              {props.new_data_user_success ? (
                                <Row>
                                  <Col lg={12}>
                                    <p
                                      style={{
                                        color: "green",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Los datos fueron guardados exitosamente.
                                    </p>
                                  </Col>
                                </Row>
                              ) : null}

                              {props.error_new_data_user ? (
                                <Row style={{ textAlign: "center" }}>
                                  <Col lg={8}>
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Se produjo un error.
                                    </p>
                                  </Col>
                                </Row>
                              ) : null}
                              <Row>
                                <Col lg={8} style={{ textAlign: "center" }}>
                                  {/* <button
                                      variant="primary"
                                      type="submit"
                                      style={{ marginRight: "20px" }}
                                      className="btn-cancelar"
                                      onClick={(e) => handleRemoveUserData(e)}
                                    >
                                      Cancelar
                                    </button> */}
                                  <button
                                    variant="primary"
                                    type="submit"
                                    className="enter"
                                    style={{ marginTop: "70px" }}
                                  >
                                    Guardar
                                  </button>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6} lg={6}>
                        <StyledMessageAccordion>
                          <Card.Body
                            style={{ padding: "0px", marginTop: "54px" }}
                          >
                            <Col md={11} lg={11} className="form-contact">
                              <p
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "0",
                                }}
                              >
                                Restablecer contraseña
                              </p>
                              <p>
                                Complete los siguientes campos para modificar su
                                contraseña.
                              </p>
                              <Form onSubmit={handleSubmit}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Ingrese contraseña actual
                                  </Form.Label>
                                  <Form.Control
                                    type="password"
                                    value={queryPass}
                                    className="italic"
                                    onChange={handleFilterPass}
                                    placeholder="Ingrese contraseña actual"
                                    required
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Label>
                                    Ingrese nueva contraseña
                                  </Form.Label>
                                  <Form.Control
                                    type="password"
                                    value={queryPassNew}
                                    className="italic"
                                    onChange={handleFilterPassNew}
                                    placeholder="Ingrese nueva contraseña"
                                    required
                                    minLength="8"
                                  />
                                  <Form.Text
                                    className="text-muted"
                                    style={{ fontSize: "12px" }}
                                  >
                                    La nueva contraseña debe tener al menos 8
                                    caracteres.
                                  </Form.Text>
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Label>
                                    Reingrese nueva contraseña
                                  </Form.Label>
                                  <Form.Control
                                    type="password"
                                    value={queryPassConfirm}
                                    className="italic"
                                    onChange={handleFilterPassConfirm}
                                    placeholder="Reingrese nueva contraseña"
                                    required
                                    minLength="8"
                                  />
                                </Form.Group>
                                {props.new_pass_status && (
                                  <Modal
                                    show={showModal}
                                    onClose={() => onCloseModaleExito()}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        La contraseña fue cambiada con éxito.
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      La contraseña fue cambiada con éxito.
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <Button
                                        variant="secondary"
                                        onClick={() => onCloseModaleExito()}
                                      >
                                        Cerrar
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                )}

                                {/* {!passwordsMatch && (
                                    <Form.Text
                                      className="text-danger"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Las contraseñas no coinciden.
                                    </Form.Text>
                                  )}

                                  {props.error_new_password ? (
                                    <Row>
                                      <Col lg={12}>
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          La contraseña actual no es correcta.
                                        </p>
                                      </Col>
                                    </Row>
                                  ) : null} */}

                                <Row>
                                  <Col
                                    md={12}
                                    lg={12}
                                    style={{ textAlign: "left" }}
                                  >
                                    {/* <button
                                        variant="primary"
                                        type="submit"
                                        className="btn-cancelar-form"
                                        onClick={() => handleRemoveAll()}
                                      >
                                        Cancelar
                                      </button> */}
                                    <button
                                      variant="primary"
                                      type="submit"
                                      className="enter"
                                      style={{ marginTop: "30px" }}
                                    >
                                      Restablecer
                                    </button>
                                  </Col>
                                </Row>
                              </Form>
                            </Col>
                          </Card.Body>
                        </StyledMessageAccordion>
                      </Col>
                    </>
                  ) : (
                    <LoadingCard />
                  )}
                </Row>
              </Tab.Pane>
              {props.error_new_password ? (
                <>
                  <Modal
                    show={showModalError}
                    onClose={() => onCloseModaleError()}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        La contraseña actual no es correcta.
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      La contraseña actual no es correcta.
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => onCloseModaleError()}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              ) : null}

              <Modal show={showErrorModal} onClose={() => onCloseModaleError()}>
                <Modal.Header closeButton>
                  <Modal.Title>Error al modificar la contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorType}</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => onCloseModaleError()}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* <Tab.Pane eventKey="second">
                <Row>
                  <ListadoUsuarios />
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="tree">
                <Row>
                  <MediosQsch />
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="four">
                <Row>
                  <RedesQsch />
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="five">
                <Row>
                  <MonitoreoUsers />
                </Row>
              </Tab.Pane> */}
            </Tab.Content>
          </Col>
        </StyledUserPanel>
      </Tab.Container>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    token: state.token,
    url: state.url,
    user_data: state.user_data,
    new_pass_status: state.new_pass_status,
    new_data_user_success: state.new_data_user_success,
    error: state.error,
    error_new_password: state.error_new_password,
    error_new_data_user: state.error_new_data_user,
    redes_qsch: state.redes_qsch,
    monitoreo_user: state.monitoreo_user,
    medios_user_qsch: state.medios_user_qsch,
    menu_usuarios: state.menu_usuarios,
    new_pass: state.new_pass
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: () => dispatch(getUserData()),
    getNewPass: (items) => dispatch(getNewPass(items)),
    getNewDataUser: (id, data) => dispatch(getNewDataUser(id, data)),
    errorNewPassword: (status) => dispatch(errorNewPassword(status)),
    errorNewData: (status) => dispatch(errorNewData(status)),
    getMenuUsuarios: () => dispatch(getMenuUsuarios()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeUser);
