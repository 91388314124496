import React, { useState, useEffect } from "react";
import { getTextos } from "../../store";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getCodeNewUser , getCodeNewUserFail} from "../../store";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { compose } from "redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FormNewCode} from '../styled'

function CodeUser(props) {
  const [analisis, setanalisis] = useState(false);
  const [error, setError] = useState(false);

  const [queryEmail, setEmail] = useState("");
  const [queryCode, setCode] = useState();
  const history = useHistory();

/*   useEffect(() => {
    props.getCodeNewUserFail()
  }, []) */

  useEffect(() => {
    if (props.code_new_user_status === "success") {
      history.push("/user/create-password");
    } else {
      if (props.code_new_user_status === "error") {
        setError(true);
      }
    }
  }, [props.code_new_user_status]);

  const handleSubmitData = (e) => {
    e.preventDefault();
    props.getCodeNewUser({
      email: queryEmail,
      code: queryCode,
    });
  };

  const handleInputEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleInputCode = (e) => {
    e.preventDefault();
    setCode(e.target.value);
  };

  return (
    <FormNewCode>
      <Row>
        <Col lg={12}>
        <Form onSubmit={handleSubmitData} style={{ marginBottom: "25px" }}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email:</Form.Label>

          <Form.Control
            type="email"
            name="email"
            value={queryEmail}
            style={{ width: "70%" }}
            onChange={handleInputEmail}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Código:</Form.Label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Form.Control
              type="text"
              name="number"
              value={queryCode}
              style={{ width: "70%" }}
              onChange={handleInputCode}
              required
            />
          </div>
        </Form.Group>
        {error ? (
          <Row>
            <Col lg={12}>
              <p
                style={{
                  padding: "26px 5px",
                  color: "red",
                  borderRadius: "5px",
                }}
              >
                Los datos ingresados no son correctos.
              </p>
            </Col>
          </Row>
        ) : null}

        <button variant="primary" type="submit" className="btn-guardar">
          Guardar
        </button>
      </Form>
        </Col>
      </Row>

    </FormNewCode>
  );
}

const mapStateToProps = (state) => {
  return {
    textos: state.textos,
    code_new_user: state.code_new_user,
    code_new_user_status: state.code_new_user_status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCodeNewUser: (data) => dispatch(getCodeNewUser(data)),
    getCodeNewUserFail: () => dispatch(getCodeNewUserFail())
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CodeUser);
