import React, { useEffect, useState } from "react";
import CamposPush from "src/components/Push/CamposPush";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { StyledBackground } from "../styled";
import { getUserDataCampanas } from "src/store";
import { connect } from "react-redux";
import Logo from "src/components/Push/Logo";
import "../style.css";


function Home(props) {
  
    useEffect(() => {
    try {
      const fetchCampanas = async () => {
        await props.getUserDataCampanas();
      };
      fetchCampanas();
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [props.login]);



  return (
    <>
      <StyledBackground fluid={true}>
        <Row>
          <Col lg={12}>
            <div className="titulo">
              <Logo />
              <p>Notificaciones Push</p>
            </div>
          </Col>
        </Row>

        <>
          <CamposPush />
        </>
      </StyledBackground>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    campanas: state.campanas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  
    getUserDataCampanas: () => dispatch(getUserDataCampanas()),
   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
