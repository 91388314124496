import React from "react";
import { connect } from "react-redux";
import { StyledContainerTT } from "./styled";
import { FaTwitter } from "react-icons/fa";

function TwitterTrends(props) {
  return (
    <StyledContainerTT>
      <div className="wrapper">
        <h1>
          <FaTwitter /> {props.data.name}
        </h1>
        <p className="items">
          {props.data.links?.map((el, index) => (
            <a href={el.url} target="_blank" key={index} rel="noopener noreferrer">
              <span style={{display: "block"}}>{el.text}</span>
            </a>
            )
          ).slice(0, 10)}
        </p>
      </div>
    </StyledContainerTT>
  );
}

const mapStateToProps = (state) => {
  return {
    monitoreo: state.monitoreo,
    monitoreo_posta: state.monitoreo_posta,
    loading: state.loading,
    login: state.login,
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TwitterTrends);
