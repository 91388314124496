import React, { Component } from "react";

/* Others */
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

/* Styles */
import "./Loading.css"

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
        height: null,
        loading: false
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({height : window.innerHeight })
    }
  }


  render() {
    return (
      <div className="loading-card">
        <Loader
          className="spinnerV2"
          type="ThreeDots"
          color="#d53052"
          height={100}
          width={100}
          radius={50}
          zIndex= '2000000'
          top= "25%"
          left= "50%"
        />
      </div>
    );
  }
}

export default Loading;
