import styled from "styled-components";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import chequeabot from "src/assets/img/chequeabot.png";

export const StyledHeader = styled.div`
  background-color: #F2F5FA;
  width: 60px;
  padding-left: 5px;
  hr {
    width: ${(props) => (props.isMenuOpen ? "195px" : "30px")};
  }
  height: 100vh;
  position: sticky;
  margin-left: 10px;
  margin-top: 10px;
  color: #354052;
  border-radius: 10px;
  top: 10px;
  transition: width 0.5s;
  overflow: hidden;
  
  &:hover {
    width: 300px;
  }

  .texto-nav p {
    display: inline-block;
    opacity: ${(props) =>
      props.isMenuOpen ? "1" : "0"};
    font-weight: 400;
    font-size: 12px;
    transition: opacity 0.7s;
    padding-bottom: 3px;
  }

  .texto-nav p
  .hr-ajustes {
    margin: 0
  }
`;

export const Styletest = styled(Navbar)`
  background-image: url(${chequeabot});
  background-size: cover;
  height: 80px;
  width: 120px;
  display: inline-block;
  margin-bottom: -20px;
  margin-left: 15px;
`;

export const StyledDropdown = styled.div`
  .navbar-test {
    overflow: hidden;
    background-color: red;
    font-family: Arial, Helvetica, sans-serif;
  }

  .dropdown {
    overflow: hidden;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 999999999;
  }

  .show {
    display: block;
    list-style: none;
    padding: 0px;
  }

  .dropdown .dropbtn {
    cursor: pointer;
    font-size: 16px;
    border: none;
    outline: none;
    font-weight: bold;
    color: #354052;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }
  .rotate {
    transform: rotate(180deg);
    transition: all 0.2s;
  }
`;

export const StyleNavBar = styled(Navbar)`
background-color: #FFF;
background-image: url(${chequeabot});
background-repeat: no-repeat;
background-position-y: 10px;
background-position-x: 300px;
border-bottom: 20px solid #707a8a;
@media (max-width: 992px) {
  background-position-x: 222px;
}

.active a{
  color: #ff3370;
}


.dropdown:hover .dropdown-content {
  display: block;
}

#responsive-navbar-nav .menu-mb {
  display: inline-flex;
  marginRight: 20px;
  @media (max-width: 992px) {
    display: block;
  }
}
}
`;

export const StyledRowLogo = styled.div`
  a {
    color: rgb(53, 64, 82);
    padding: 0px;
    font-weight: 700;
    text-decoration: none;
    font-size: 28px;
    line-height: 24px;
  }

  @media (max-width: 320px) {
    a {
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

export const StyledTitle = styled.h1`
  color: #354052;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  margin-left: 8px;
  display: ${(props) =>
    props.isMenuOpen ? "block !important" : "none!important"};
  }
`;

export const StyledBtnUser = styled.h1`
  color: #354052;
  font-weight: bold;
  padding-bottom: 6px;
  font-size: 12px;
  -webkit-text-decoration: none;
  text-decoration: none;
  a {
    color: #354052;
    font-weight: bold;
    padding-bottom: 6px;
    font-size: 12px;
    text-decoration: none;
    svg {
      margin-right: 8px;
    }
  }
  a:hover {
    text-decoration: none;
    color: #354052;
  }

  &:focus {
    box-shadow: none !important;
  }

  /*   display: flex;
  flex-direction: row;
  align-items: center;
  color: #ff3370;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  @media (max-width: 992px) {
    text-align: center;
  } */
`;

export const StyledDrawerMenu = styled.div`
  padding: 15px 10px;
  width: 230px;
  
  svg {
    fill: #354052;
    circle {
      fill: #fff;
    }
    &:hover {
      fill: #354052;
      cursor: pointer;
    }
  }

  .menu-content-open {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
  }
`;


export const StyledMenuClose = styled.div`
  width: 100px;
  z-index: 1;
  transition: left 0.3s ease 0s;

  height: 1000px;
  display: flex;
  flex-direction: column;
  padding: 35px 20px 20px;
  align-items: center;
  svg {
    margin-right: 0;
  }
  .openButton {
    background: transparent;
    border: none;
    cursor: pointer;
    margin-bottom: 15px;
    padding: 0px;
    svg {
      fill: #354052;
      &:hover {
        fill: #354052;
        cursor: pointer;
      }
    }
  }
`;


export const StyledBtn = styled(Button)`
    color: #354052 ;
    background-color: transparent !important;
    border: none;
    border-radius: 0;
    font-weight: normal;
    font-size: 18px;
    border-radius: 12px;
    cursor: default!important;

  a {
    color:  #354052 ;
    font-weight:bold;
    padding-bottom: 6px;
    font-size: 16px;
  }
  a:hover {
    text-decoration: none;
    color: #354052;
  }

  &:focus {
    box-shadow: none !important;
  }

}
`;
export const CircleIcon = styled.div`
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: white;
  padding: 4px 4px;
  margin-right: 8px;
`;

export const StyledLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: #354052;
  font-weight: bold;
  font-size: 12px;

  &:hover {
    color: #354052;
    ${CircleIcon} {
      background-color: #354052; /* Cambia el color del círculo al hacer hover */
    }

    svg {
      color: white; /* Cambia el color del ícono al hacer hover */
    }
  }
`;

export const StyledBtnMenu = styled(Button)`
  color: #354052;
  background-color: transparent !important;
  border: none;
  border-radius: 0;
  font-weight: normal;
  font-size: 18px;
  border-radius: 12px;
  cursor: default !important;
  padding: 0;
  margin-bottom: 18px;
  width: -webkit-fill-available;
  text-align: left;
  a {
    color: #354052;
    font-weight: bold;
    padding-bottom: 6px;
    font-size: 12px;
    text-decoration: none;

    .exit:hover {
      color: #f72d54;
    }
    &:hover div{
      background: #e8ebf2;
      border-radius: 15px;
    }

    svg {
      margin-right: 8px;
      fill: #354052;
      strokewidth: 0px;
    }
  }

  &:focus {
    box-shadow: none !important;
  }
`;


export const StyleBrand = styled(Navbar.Brand)`
  /*
  .imageLogo {
    display: block;
  }
  .imageLogoTilde {
    display: none;
  }

  @media (max-width: 500px) {
    .imageLogo {
      display: none;
    }
    .imageLogoTilde {
      width: 50px;
      display: block;
      margin: 0;
    }
  }

  @media (max-width: 320px) {
    .imageLogo {
      display: none;
    }
    .imageLogoTilde {
      width: 25px;
      display: block;
      margin: 0;
    }
  }
 */
`;
