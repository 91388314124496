import styled from "styled-components";

export const StyledLogin = styled.div`
  p {
    font-size: 10px;
  }

  .small-text p {
    font-size: 10px;
    margin-bottom: 0;
  }

  .login-box {
      width: 370px;
      border: 1px solid #EFEFEF;
      border-radius: 10px;    
      padding: 20px 30px;
      padding-top: 0;
      margin-top: 90px;
      background: white;
  }
`;
