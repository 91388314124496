import styled from "styled-components";
import Col from "react-bootstrap/Col";
export const StyledWrapperAgenda = styled(Col)`
  margin-top: 20px;
  padding: 0px;
  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    text-align: left;
    color: #354052;
    margin-bottom: 3px;
  }
  hr {
    margin-top: 7px;
    height: 6px;
    opacity: 1.25;
    background: #E63457;
  }
  .nav-tabs .nav-link {
    color: #000000;
  }

  .wrapperbuttons {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 40px;
  }
  ul {
    columns: 2;
    li {
      &:nth-child(7) {
        -webkit-column-break-after: always;
        break-after: always;
      }
    }
    @media (max-width: 768px) {
      columns: auto;
      padding-left: 0px;
    }
    @media (max-width: 767px) {
      columns: 2;
    }
    @media (max-width: 425px) {
      columns: auto;
    }
  }


`;
