import React from "react";

function FormSuccessMessage({message, align}) {

  return (
    <div>
        {message ? (
          <p className="success-message" style={{textAlign: align}}>
            {message}
          </p>
        ) : null}
    </div>
  );
}


export default FormSuccessMessage;
