import React from "react";

export default function Logo({ fillColor, backgroundColor }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
            width="24"
            height="24"
            fill={fillColor}
        >
            <circle cx="100" cy="100" r="100" fill={backgroundColor} />
            <path d="m127.86,46.93h20.24c2.93,0,5.3,2.38,5.3,5.3v20.24c0,2.53,2.05,4.58,4.58,4.58h0c2.53,0,4.58-2.05,4.58-4.58v-20.24c0-7.99-6.47-14.46-14.46-14.46h-20.24c-2.53,0-4.58,2.05-4.58,4.58h0c0,2.53,2.05,4.58,4.58,4.58Z" />
            <path d="m46.6,72.47v-20.24c0-2.93,2.38-5.3,5.3-5.3h20.24c2.53,0,4.58-2.05,4.58-4.58h0c0-2.53-2.05-4.58-4.58-4.58h-20.24c-7.99,0-14.46,6.47-14.46,14.46v20.24c0,2.53,2.05,4.58,4.58,4.58h0c2.53,0,4.58-2.05,4.58-4.58Z" />
            <path d="m72.14,153.73h-20.24c-2.93,0-5.3-2.38-5.3-5.3v-20.24c0-2.53-2.05-4.58-4.58-4.58h0c-2.53,0-4.58,2.05-4.58,4.58v20.24c0,7.99,6.47,14.46,14.46,14.46h20.24c2.53,0,4.58-2.05,4.58-4.58h0c0-2.53-2.05-4.58-4.58-4.58Z" />
            <path d="m153.4,128.18v20.24c0,2.93-2.38,5.3-5.3,5.3h-20.24c-2.53,0-4.58,2.05-4.58,4.58h0c0,2.53,2.05,4.58,4.58,4.58h20.24c7.99,0,14.46-6.47,14.46-14.46v-20.24c0-2.53-2.05-4.58-4.58-4.58h0c-2.53,0-4.58,2.05-4.58,4.58Z" />
            <rect
                x="62.99"
                y="76.64"
                width="74.02"
                height="9.48"
                rx="4.58"
                ry="4.58"
            />
            <rect
                x="62.99"
                y="95.59"
                width="74.02"
                height="9.48"
                rx="4.58"
                ry="4.58"
            />
            <rect
                x="62.99"
                y="114.54"
                width="46"
                height="9.48"
                rx="4.58"
                ry="4.58"
            />
        </svg>
    );
}
