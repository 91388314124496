import React, { useState, useEffect } from "react";
import { getTextos } from "../../store";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getResetPassword } from "../../store";
import { useHistory } from "react-router-dom";
import { FormNewCode } from "../styled";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getLogin, getToken, getItemsMenu } from "../../store";
import { Link } from "react-router-dom";
import { StyledLogin } from "../styled";
import VentaChequeabot from "../../components/VentaChequeabot";
import robot from "src/assets/img/Capa_1.png";
import logo from "src/assets/img/logo_solo.png";
import sobre from "src/assets/img/Rectangle 48.png";
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function ResetPassword(props) {
  const [email, setemail] = useState("");
  const [error, setError] = useState(false);
  const history = useHistory();
  const [message, setmessage] = useState(false);

  const [queryEmail, setEmail] = useState("");

  useEffect(() => {
    $("#header").hide();
    return () => {
      $("#header").show();
    };
  }, []);

  useEffect(() => {
    if (props.first_password_status === "success") {
      setmessage(true);
      setemail(props.first_password.data.hidden_email);
      //history.push("/login");
    } else {
      if (props.first_password_status === "error") {
        setError(true);
      }
    }
  }, [props.first_password_status]);

  const handleSubmitData = (e) => {
    e.preventDefault();
    props.getResetPassword({
      username: queryEmail,
    });
  };

  const handleEmailReset = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const backToLogin = () => {
    history.push("/login");
  }

  return (
    <Container fluid={true} style={{ background: "#f6f9fc" }}>
      <Row>
        <Col lg={6} style={{ paddingLeft: "0" }}>
          <VentaChequeabot login={true} />
        </Col>
        <Col lg={6}>
          <StyledLogin>
            <div className="mx-auto login-box">
              <Row>
                <Col lg={12} className="text-center">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "64px",
                        height: "64px",
                        borderRadius: "50%",
                        backgroundColor: "#354052", // Color azul
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "15px",
                        marginTop: "-23px",
                      }}
                    >
                      <img src={robot} />
                    </div>
                    <h1
                      style={{
                        fontWeight: "800",
                        fontSize: "16px",
                        lineHeight: "30px",
                      }}
                    >
                      Soporte
                    </h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="text-center">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "800",
                        fontSize: "12px",
                        marginBottom: "0",
                      }}
                    >
                      Reestablecer contraseña
                    </h1>
                    {message ? (
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "left",
                          marginBottom: "0",
                          color: "green",
                        }}
                      >
                        Se ha enviado un correo electrónico a{" "}
                        <span style={{ margin: "0 3px" }}>{email}</span>
                        con un enlace para reestablecer tu contraseña.
                      </p>
                    ) : (
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "left",
                          marginBottom: "0",
                        }}
                      >
                        Ingresá tu nombre de usuario o correo electrónico y hacé
                        click en el botón para reestablecer tu contraseña
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  className="mx-auto mt-2"
                  lg={12}
                  style={{ color: "#354052" }}
                >
                  {message ? (
                    <>
                      <div style={{ textAlign: "center", margin: "24px 0" }}>
                        <img src={sobre} />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <button
                          className="enter"
                          style={{ marginRight: "0" }}
                          onClick={backToLogin}
                        >
                          Volver
                        </button>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <form
                      onSubmit={handleSubmitData}
                      style={{ marginBottom: "16px" }}
                    >
                      <div>
                        <input
                          className="mb-1 form-control"
                          type="text"
                          placeholder="Usuario o correo electrónico"
                          name="pass"
                          value={queryEmail}
                          required
                          onChange={handleEmailReset}
                        />
                      </div>
                      {error ? (
                        <div>
                          <p
                            style={{
                              fontSize: "12px",
                              marginBottom: "0px",
                              marginTop: "10px",
                              color: "red",
                            }}
                          >
                            Se produjo un erorr
                          </p>
                        </div>
                      ) : null}
                      <div
                        className="mt-4"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          type="submit"
                          value="Registrar"
                          className="enter"
                          id="login-btn"
                        >
                          Restablecer contraseña
                        </button>
                      </div>
                      <hr />
                    </form>
                  )}

                  <p className="mt-4 text-center">
                    ¿Aún tienes probemas para iniciar seisón?
                    <br />
                    Comunicate a{" "}
                    <a href="mailto:innovacion@chequeado.com">
                      innovacion@chequeado.com
                    </a>
                  </p>
                </Col>
              </Row>
            </div>
            <Row>
              <Col
                lg={12}
                className="text-center small-text"
                style={{ marginTop: "20px" }}
              >
                <p>Chequeabot</p>
                <p> App version V1.7.0 Chequeado</p>
                <img src={logo} style={{ width: "100px", marginTop: "10px" }} />
              </Col>
            </Row>
          </StyledLogin>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    textos: state.textos,
    code_new_user: state.code_new_user,
    first_password_status: state.first_password_status,
    first_password: state.first_password
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getResetPassword: (data) => dispatch(getResetPassword(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
