import React from "react";

export default function Logo({ fillColor, backgroundColor }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        width="24"
        height="24"
        fill={fillColor}
      >
        <circle cx="100" cy="100" r="100" fill={backgroundColor} />
        <path
          class="cls-1"
          d="M116.93,65.09h-40.63c-2.63,0-4.77,2.14-4.77,4.77s2.14,4.77,4.77,4.77h40.63c2.63,0,4.77-2.14,4.77-4.77s-2.14-4.77-4.77-4.77Z"
        />
        <path
          class="cls-1"
          d="M76.3,101.71h40.63c2.63,0,4.77-2.14,4.77-4.77s-2.14-4.77-4.77-4.77h-40.63c-2.63,0-4.77,2.14-4.77,4.77s2.14,4.77,4.77,4.77Z"
        />
        <path
          class="cls-1"
          d="M95.23,146.34h-32.47c-4.84,0-8.77-3.93-8.77-8.77V56.31c0-4.84,3.93-8.77,8.77-8.77h67.71c4.84,0,8.77,3.93,8.77,8.77v54.17c0,2.63,2.14,4.77,4.77,4.77s4.77-2.14,4.77-4.77v-54.17c0-10.1-8.22-18.31-18.31-18.31H62.76c-10.1,0-18.31,8.22-18.31,18.31v81.26c0,10.1,8.22,18.31,18.31,18.31h32.47c2.63,0,4.77-2.14,4.77-4.77s-2.14-4.77-4.77-4.77Z"
        />
        <path
          class="cls-1"
          d="M144.01,126.03c-1.27,0-2.47.5-3.37,1.4l-15.53,15.53-1.41,1.41-1.41-1.41-4.65-4.65c-.9-.9-2.1-1.4-3.37-1.4s-2.47.5-3.37,1.4c-1.86,1.86-1.86,4.89,0,6.75l9.44,9.44c.9.9,2.1,1.4,3.37,1.4s2.47-.5,3.37-1.4l20.31-20.31c.9-.9,1.4-2.1,1.4-3.37s-.5-2.47-1.4-3.37c-.9-.9-2.1-1.4-3.37-1.4Z"
        />
        <path
          class="cls-1"
          d="M103.39,119.26h-27.09c-2.63,0-4.77,2.14-4.77,4.77s2.14,4.77,4.77,4.77h27.09c2.63,0,4.77-2.14,4.77-4.77s-2.14-4.77-4.77-4.77Z"
        />
      </svg>
    );
}

