import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Bootstrap & icons
import { Col, Card, } from "react-bootstrap";
import { FaTwitter, FaInstagram, FaYoutube, FaTelegramPlane } from "react-icons/fa";
import { MdFavorite, MdRemoveRedEye, MdOutlineDelete, MdOutlineReply, MdOutlineBarChart, MdAccessTime, MdOutlineCalendarToday, MdOutlineFacebook, MdThumbDownOffAlt, MdThumbUpOffAlt, MdOutlineUploadFile  } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { getColorVeredict } from "src/utils/formatter";

// Redux
import { connect } from "react-redux";
import {
  getRegistroID,
  monitoreoToggleForm,
  deleteRegistros
} from "src/store";

// Utils
import { numberFormatter } from "src/utils/formatter";
import dayjs from "dayjs";
import "dayjs/locale/es";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.locale("es");

function SavedCardPreview(props) {

  const [deleteId, setDeleteId] = useState(null);
  const history = useHistory();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteClick = (id) => {
    handleShow();
    setDeleteId(id);
  };

  const handleConfirmationDelete = () => {
    props.deleteRegistros(deleteId);
    setShow(false);
  };

  const handleCardClick = (cardId) => {
    history.push(`/periscopio/${cardId}`);
  };

  function truncate(str, n){
    return (str.length > n) ? str.slice(0, n-1) + '[...]' : str;
  };

  return (
    <Col md={3} key={props.el.id}>
      <Card className="card-registro">
        <div className="card-editar">
          <MdAccessTime />
          <p className="fecha">
            {dayjs(props.el.created_at).fromNow()}
          </p>
          <MdOutlineDelete
            className="tacho"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteClick(props.el.id)}
          />
        </div>
        <Modal show={show} onClose={handleClose} centered>
          <Modal.Header>
            <Modal.Title>Esta acción es irreversible</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Confirmas que quieres borrar la tarjeta <b>{props.el.name}</b>?</Modal.Body>
          <Modal.Footer>
            <button
              variant="primary"
              onClick={handleClose}
              className="guardar"
            >
              Cancelar
            </button>
            <button
              variant="secondary"
              onClick={() => handleConfirmationDelete()}
              className="cancelar"
            >
              Borrar tarjeta
            </button>
          </Modal.Footer>
        </Modal>
        <p className="titulo" onClick={() => handleCardClick(props.el.id)}>
          {props.el.name}
        </p>
        <p className="bajada" onClick={() => handleCardClick(props.el.id)}>
          {truncate(props.el.description, 150)}
        </p>
        <div
          className="clasificacion"
          style={{
            border: `2px solid ${getColorVeredict(props.el.status)}`,
          }}
        >
          <div className="circulos">
            {props.el.status === "done" && (
              <>
                <div className="circulo gris" />
                <div className="circulo gris" />
                <div className="circulo gris" />
                <div className="circulo verde" />
                <p>Chequeado</p>
              </>
            )}
            {props.el.status === "pending" && (
              <>
                <div className="circulo gris" />
                <div className="circulo gris" />
                <div className="circulo rojo" />
                <div className="circulo gris" />
                <p>Falta chequear</p>
              </>
            )}
            {props.el.status === "cancelled" && (
              <>
                <div className="circulo gris" />
                <div className="circulo gris" />
                <div className="circulo gris" />
                <div className="circulo gris-oscuro" />
                <p>No se chequea</p>
              </>
            )}
            {props.el.status === "in_progress" && (
              <>
                <div className="circulo gris" />
                <div className="circulo yellow" />
                <div className="circulo gris" />
                <div className="circulo gris" />
                <p>En proceso</p>
              </>
            )}
          </div>
        </div>
        <ul className="contenedor-redes-registro">
          {Array.from(
            { length: props.el.post_stats?.manual },
            (_, index) => (
              <li key={index}>
                <MdOutlineUploadFile  className="manual" />
                <MdOutlineBarChart /> 414
                <MdOutlineReply /> 8
              </li>
            )
          )}
          {props.el.post_stats.fbpost ? (
            <li>
              <span>
                <MdOutlineFacebook className="fb" />
                <span id={`app-title-likes-${props.index}`}>
                  <MdFavorite style={{ fontSize: "14px" }} />{" "}
                  {numberFormatter(props.el.post_stats.fbpost?.likes, 2)}
                </span>
                <span id={`app-title-share-${props.index}`}>
                  <MdOutlineReply style={{ fontSize: "14px" }} />{" "}
                  {numberFormatter(props.el.post_stats.fbpost?.shares, 2)}
                </span>
              </span>
            </li>
          ) : null}

          {props.el.post_stats?.tweet ? (
            <li>
              <span>
                <FaTwitter className="twitter" />
                <span id={`app-title-likes-${props.index}`}>
                  <MdFavorite style={{ fontSize: "14px" }} />{" "}
                  {numberFormatter(props.el.post_stats.tweet?.likes, 2)}
                </span>
                <span id={`app-title-share-${props.index}`}>
                  <MdOutlineReply style={{ fontSize: "14px" }} />{" "}
                  {numberFormatter(props.el.post_stats.tweet?.shares, 2)}
                </span>
                <span id={`app-title-views-${props.index}`}>
                  <MdRemoveRedEye style={{ fontSize: "14px" }} />{" "}
                  {numberFormatter(props.el.post_stats.tweet?.views, 2)}
                </span>
              </span>
            </li>
          ) : null}
          {props.el.post_stats?.instagram ? (
            <li>
              <span>
                <FaInstagram className="instagram" />
                <span id={`app-title-likes-${props.index}`}>
                  <MdFavorite style={{ fontSize: "14px" }} />{" "}
                  {numberFormatter(props.el.post_stats.instagram?.likes, 2)}
                </span>
              </span>
            </li>
          ) : null}
          {props.el.post_stats?.youtube ? (
            <li>
              <span>
                <FaYoutube className="yt" />
                <span id={`app-title-likes-${props.index}`}>
                  <MdFavorite style={{ fontSize: "14px" }} />{" "}
                  {numberFormatter(props.el.post_stats?.youtube.likes, 2)}
                </span>
                <span id={`app-title-share-${props.index}`}>
                  <MdRemoveRedEye style={{ fontSize: "14px" }} />{" "}
                  {numberFormatter(props.el.post_stats?.youtube.views, 2)}
                </span>
              </span>
            </li>
          ) : null}
          {props.el.post_stats?.telegram ? (
            <li>
              <span>
                <FaTelegramPlane className="telegram" />
                <span id={`app-title-likes-${props.index}`}>
                  <MdFavorite style={{ fontSize: "14px" }} />{" "}
                  {numberFormatter(props.el.post_stats?.telegram.likes, 2)}
                </span>
                <span id={`app-title-share-${props.index}`}>
                  <MdOutlineReply style={{ fontSize: "14px" }} />{" "}
                  {numberFormatter(props.el.post_stats?.telegram.shares, 2)}
                </span>
              </span>
            </li>
          ) : null}
          {props.el.post_stats?.manual ? (
            <li>
              <span>
                <MdOutlineUploadFile className="manual" />
              </span>
            </li>
          ) : null}
          {props.el.post_stats?.tiktok ? (
            <li>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="#000"
                  className="bi bi-tiktok"
                  viewBox="0 0 14 14"
                >
                  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                </svg>
                <span id={`app-title-likes-${props.index}`}>
                  <MdFavorite style={{ fontSize: "14px" }} />  {numberFormatter(props.el.post_stats?.tiktok.likes, 2)}
                </span>
                <span id={`app-title-views-${props.index}`}>
                  <MdRemoveRedEye style={{ fontSize: "14px" }} />{numberFormatter(props.el.post_stats?.tiktok.views, 2)}
                </span>
              </span>
            </li>
          ) : null}
        </ul>
        <div style={{ textAlign: "center", position: "absolute", bottom: "15px", left: "0", right: "0" }}>
          <button
            className="btn btn-default-registro my-auto"
            type="submit"
            onClick={() => handleCardClick(props.el.id)}
          >
            Más información
          </button>
        </div>
      </Card>
    </Col>
  );
}


const mapStateToProps = (state, ownProps) => {
  return {
    el: ownProps.el,
    index: ownProps.index,
    page_registrados: state.page_registrados
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRegistroID: (id) => dispatch(getRegistroID(id)),
    deleteRegistros: (id) => dispatch(deleteRegistros(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedCardPreview);

