import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getLogin, getToken, getItemsMenu } from "src/store";
import { Link } from "react-router-dom";
import {StyledLogin} from "./styled";
import VentaChequeabot from "../../VentaChequeabot";
import robot from "src/assets/img/Capa_1.png";
import Container from "react-bootstrap/Container";
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import logo from "src/assets/img/logo_solo.png"
const BASE_DEV = "https://dev.chequeado.com";
const BASE_PREDEV = "https://predev.chequeado.com";


function Login(props) {
  const [loginOkey, setLoginOkey] = useState(false);
  const history = useHistory();

  useEffect(() => {
    $("#header").hide();
    return () => {
      $("#header").show();
    };
  }, []);

  const login = async (nombre, pass) => {
    try {
      const result = await axios.post(`${BASE_DEV}/api-token-auth/`, {
        username: nombre,
        password: pass,
      });
      localStorage.setItem("token", result.data.token);
      props.getLogin(true);
      const tokenLS = localStorage.getItem("token");
      props.getToken(tokenLS);
      if (tokenLS) {
        try {
          await props.getItemsMenu();
        } catch (error) {
          console.log("Se produjo un error generando el menu", error);
        }
      }
      if (props.url) {
        history.push(props.url);
      } else {
        history.push("/");
      }
    } catch (error) {
      setLoginOkey(true);
      console.log("Se produjo un error generando el token:", error);
      document.getElementById("login-btn").disabled = false;
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const nombre = e.target.elements[0].value;
    const pass = e.target.elements[1].value;
    login(nombre, pass);
    localStorage.setItem("user", nombre);
    document.getElementById("login-btn").disabled = true;
    e.target.elements[1].value = "";
  };

  useEffect(() => {
    if (!!localStorage.getItem("token")) {
      history.push("/");
      props.getLogin(true);
    } else {
      props.getLogin(false);
    }
  }, []);

  return (
    <Container fluid={true} style={{ background: "#f6f9fc" }}>
      <Row>
        <Col lg={6} style={{paddingLeft: "0"}}>
          <VentaChequeabot login={true} />
        </Col>
        <Col lg={6}>
          <StyledLogin>
            <div className="mx-auto login-box">
              <Row>
                <Col lg={12} className="text-center">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "64px",
                        height: "64px",
                        borderRadius: "50%",
                        backgroundColor: "#354052", // Color azul
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "15px",
                        marginTop: "-23px",
                      }}
                    >
                      <img src={robot} />
                    </div>
                    <h1
                      style={{
                        fontWeight: "800",
                        fontSize: "20px",
                        lineHeight: "30px",
                      }}
                    >
                      Inicio
                    </h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  className="mx-auto mt-2"
                  lg={12}
                  style={{ color: "#354052" }}
                >
                  {loginOkey ? (
                    <p className="alert alert-danger text-justify pb-2">
                      El usuario o la contraseña ingresados no son correctos. Sí
                      el problema persiste comunicate a
                      innovacion@chequeado.com.
                    </p>
                  ) : null}
                  <form
                    onSubmit={handleOnSubmit}
                    style={{ marginBottom: "16px" }}
                  >
                    <div>
                      <p
                        className="mb-1 font-weight-bold"
                        style={{ fontSize: "12px" }}
                      >
                        Usuario
                      </p>
                      <input
                        className="form-control"
                        type="text"
                        name="nombre"
                        placeholder="Usuario"
                        required
                      />
                    </div>
                    <div className="pt-3">
                      <p
                        className="mb-1 font-weight-bold"
                        style={{ fontSize: "12px" }}
                      >
                        Contraseña
                      </p>
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="Contraseña"
                        required
                      />
                    </div>
                    <div
                      className="mt-4"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        type="submit"
                        value="Registrar"
                        className="enter"
                        id="login-btn"
                        style={{ marginRight: "0" }}
                      >
                        Iniciar sesión
                      </button>
                      <p className="mt-4 mb-0 text-center">
                        <span className="text-secondary">
                          <Link to="/reset-password">
                            {" "}
                            ¿Olvidaste tu contraseña? <br />
                          </Link>
                        </span>
                      </p>
                    </div>
                    <hr />
                    <p className="mt-4 text-center">
                      ¿Querés sumar Chequeabot a tu equipo?
                      <br />
                      Escribinos a{" "}
                      <a href="mailto:innovacion@chequeado.com">
                        innovacion@chequeado.com
                      </a>
                    </p>
                  </form>
                </Col>
              </Row>
            </div>
            <Row>
              <Col
                lg={12}
                className="text-center small-text"
                style={{ marginTop: "20px", marginBottom: "50px" }}
              >
                <p>Chequeabot</p>
                <p> App version V1.7.0 Chequeado</p>
                <img src={logo} style={{ width: "100px", marginTop: "10px" }} />
              </Col>
            </Row>
          </StyledLogin>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    token: state.token,
    url: state.url,
    items_menu: state.items_menu,
    user_data: state.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLogin: (status) => dispatch(getLogin(status)),
    getToken: (token) => dispatch(getToken(token)),
    getItemsMenu: () => dispatch(getItemsMenu()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);