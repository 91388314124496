import styled from "styled-components";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


export const StyledContainerTT = styled.div`
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #D8D8D8;
    background: #fff;
    border-radius: 10px;
    padding: 20px;

    h1 {
      font-weight: bold;
      color: #354052;
      font-size: 14px;
      line-height: 16px;
      margin-right: 16px;
      text-align: left;
      margin-bottom: 20px;
      svg {
        margin-right: 8px;
        color: #33CCFF;
      }
      img {
        margin-right: 8px;
      }
    }

    .items {
      margin-bottom: 0px;
      font-size: 13px;
      a {
        color: #354052;
        font-weight: normal;
        text-decoration: none;
        word-break: break-word;
      }
      a:hover {
        color: #ff3370;
        text-decoration: none;
        word-break: break-word;
      }
      span {
        margin-right: 10px;
      }
    }
  }
`;