import styled from "styled-components";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import bg from "src/assets/img/background-chatbot.png"

export const StyledMedios = styled(Col)`
  font-size: 18px;
  color:#616161;
  font-family: 'Archivo', sans-serif;
  background-color: white;
  background-image: url(${bg});
  background-repeat: repeat;
  background-size: cover;
  color: #354052;
  min-height: 800px;
  h2 span {
    color: #354052;
    font-size: 20px;
    line-height: 24px;
  }
  .h3 {
    font-size: 16px;
    line-height: 20px;
    color: #354052;
    margin-top: 35px;
    margin-bottom: 11px;
  }
  .title {
    font-size: 16px;
    line-height: 20px;
    color: #354052;
  }
 }

  .ayuda {
    .titulo-ayuda{
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #354052;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 30px;
      margin-top: 10px;
      svg {
        margin-right: 10px;
      }
    }
  }
`;



export const StyledMessageAccordion = styled(Accordion)`
  width: 140px;
  cursor: pointer;
  .card-header-1 {
    background: transparent !important;
    border-bottom: none !important;
    width: 120px;
  }
  .card-header {
    background: transparent !important;
    border-bottom: none !important;
    width: 140px;
  }
  .resetpass {
    font-size: 16px;
    line-height: 20px;
    color: #354052;
  }
  .card-body{
    padding: 0 0 0 0 !important;
  }
  
`;

export const StyledListHelp = styled.ul`
  li p{
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 20px !important;
  }
`;


