import { createGlobalStyle } from "styled-components";

import bg from "src/assets/img/background-home.png";

export const GlobalStyle = createGlobalStyle`
.app-container {
  display: flex;
  transition: margin-left 0.5s;
  background: #F7F9FC; 
  min-height: 100vh;
}


/*   body{
    font-size: 18px;
    color:#616161;
    font-family: 'Archivo', sans-serif;
    background: #F7F9FC;

    background-image: url(${bg});
    background-repeat: repeat;
    background-size: cover;
    color: #354052;
    min-height: 800px;
  } */

  .bg-blueish{
      background-color: rgb(230, 236, 245);
    }
  .text-main{
    color:white;
    background: #d53a23;
  }
  .bg-transparent{
    background-color: rgb(255,255,255,0.7)!important;
  }

  .bg-transparent h3{
    position: fixed;
    bottom:50%;
  }
  .bg-main{
    background: #d53a23;
  }
`;
