import React, { useState, useEffect, useLayoutEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";

// Bootsrap and CSS
import { Col, Row, Container, Modal } from "react-bootstrap";
import { MdOutlineArrowBack, MdOutlineUploadFile } from "react-icons/md";
import { FaSearch, FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { StyledContainerFilters, StyledContainerCard } from "../styled";
import "./style.css";

// Components
import CardPublicationsList from "src/components/Monitoreo/CardPublicationsList";
import FormEditCard from "src/components/Monitoreo/Forms/FormEditCard";
import FormCreatePublication from "src/components/Monitoreo/Forms/FormCreatePublication";

// Redux
import { connect } from "react-redux";
import { 
  getRegistroIDdata, 
  getDataCard, 
  monitoreoToggleForm,
  monitoreoSetLoadPublicationCardSelectedID
 } from "src/store";

// Date utils
import dayjs from "dayjs";
import "dayjs/locale/es";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Loading from "src/components/LoadingCard";
import ReactPaginate from "react-paginate";
dayjs.extend(customParseFormat);
dayjs.locale("es");

const GuardadosDetail = (props) => {
  const amountOfCardsPerPage = 10;
  const { id } = useParams();
  const history = useHistory();
  const [pendingChange, setPendingChange] = useState(false);
  const [showChangesModal, setShowChangesModal] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);



  const handleClose = () => {
    setShowChangesModal(false);
  };


  const buildApiUrl = (selectedPage) => {
    let url = "";
    if (selectedPage !== undefined) {
      const offset = selectedPage === 0 ? 0 : (selectedPage - 1) * amountOfCardsPerPage;
      url += `${offset}`;
      return url
    } else {
      const defaultPage = 1;
      const offset = (defaultPage - 1) * amountOfCardsPerPage;
      url += `${offset}`;
      return url
    }
  };

   const fetchMonitoreoGuardadosID = async (selectedPage) => {
    try {
      if (props.login) {
        const redesUrlParams = buildApiUrl(selectedPage);
        const requestParams = {
          id: id,
          page: redesUrlParams == "" ? "" : redesUrlParams,
          limit: amountOfCardsPerPage,
        };
        await props.getRegistroIDdata(requestParams)
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
      }
    } catch (error) {
      console.log("Error al cargar las cards:", error);
    }
  };

    useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (props.login) {
          await fetchMonitoreoGuardadosID();
        }
      } catch (error) {
        console.log("Error al cargar las cards:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [props.login]);


  useEffect(() => {
    props.getDataCard(id);
    // Llenar cardData con la info que llegue de aca
  }, [id]);


  useEffect(() => {
    if (props.registro_status == "success") props.getRegistroIDdata(id);
  }, [props.registro_status]);

/*   useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [id]); */

  const handleCardClickBack = () => {
    // Show modal if pending changes
    if (pendingChange) {
      setShowChangesModal(true);
    } else {
      goBack();
    }
  };


    const handlePageChange = (selectedPage) => {
    setLoading(true);
    fetchMonitoreoGuardadosID(selectedPage + 1)
  }

  const goBack = () => {
    // Go back
    props.getDataCard(null);
    history.push("/periscopio");
    props.onFeedTabClick("2");
    window.scrollTo(0, 0);
  };

  const changeInForm = (value) => {
    setPendingChange(value);
  };

  const handleLoadNewPublication = async () => {            
    await props.setLoadPublicationCardSelectedID(id);
    props.toggleForm(true, "create_publication", -1);
  };


  return (
    <Container fluid className="guardados-detail">
      {!props.cardData.name ? (
        <Loading />
      ) : (
        <Row>
          <Col md={7}>
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={6} sm={12} md={4}>
                    <button
                      className="btn btn-back"
                      onClick={() => handleCardClickBack()}
                    >
                      <MdOutlineArrowBack />
                      Volver a Guardados
                    </button>
                  </Col>
                  <Col lg={6} sm={12} md={4}>
                    <button
                      className="button-cargar"
                       onClick={() => handleLoadNewPublication()}
                    >
                      <MdOutlineUploadFile />
                      Cargar publicación
                    </button>
                  </Col>
                </Row>

                <Modal show={showChangesModal} onClose={handleClose} centered>
                  <Modal.Header>
                    <Modal.Title>
                      La Tarjeta tiene cambios sin guardar
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    ¿Quieres volver sin guardar o continuar aquí? <br />
                    Deberás actualizar la Tarjeta manualmente
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      variant="secondary"
                      onClick={goBack}
                      className="cancelar"
                    >
                      Volver sin guardar
                    </button>
                    <button
                      variant="primary"
                      onClick={() => handleClose()}
                      className="guardar"
                    >
                      Continuar aquí
                    </button>
                  </Modal.Footer>
                </Modal>
              </Col>
              <Col lg={12}>
                <p className="title">{props.cardData.name}</p>
              </Col>
            </Row>
            <Row>
              <p className="subtitle">Publicaciones asociadas</p>
              <StyledContainerCard>
                  <CardPublicationsList sortedPublications={props.id_registro_data.results.sort((a, b) => new Date(b.time) - new Date(a.time))} />
              </StyledContainerCard>
            </Row>
          </Col>
          <Col md={5}>
            <FormEditCard
              id="update_card"
              cardTitle="Actualizar Tarjeta"
              cardDescription="Recordá guardar luego de hacer los cambios."
              onChangeInForm={changeInForm}
            />
            <FormCreatePublication // Create new card
              id="create_publication"
              cardTitle="Cargar nueva publicación"
              cardDescription="Complete los siguientes campos para cargar la nueva publicación."
            />
          </Col>
           {props.id_registro_data && props.id_registro_data.count > amountOfCardsPerPage && (
        <ReactPaginate
          previousLabel={<FaArrowLeft />}
          nextLabel={<FaArrowRight />}
          pageCount={Math.ceil(props.id_registro_data.count / amountOfCardsPerPage)}
         onPageChange={(selectedPage) =>
                  handlePageChange(selectedPage.selected)
                }
          breakLabel={"..."}
          breakClassName={"break-me"}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      )}
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    login: state.login,
    error: state.error,
    categorias_detalle: state.categorias_detalle,
    btn_registro: state.btn_registro,
    id_registro: state.id_registro,
    id_registro_data: state.id_registro_data,
    cardData: state.monitoreo__card_selected,
    registro_status: state.registro_status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRegistroIDdata: (id, limit) => dispatch(getRegistroIDdata(id, limit)),
    getDataCard: (id) => dispatch(getDataCard(id)),
    toggleForm: (openForm, openFormId, publication_id) =>
      dispatch(monitoreoToggleForm(openForm, openFormId, publication_id)),
    setLoadPublicationCardSelectedID: (cardSelectId) =>
      dispatch(monitoreoSetLoadPublicationCardSelectedID(cardSelectId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuardadosDetail);
