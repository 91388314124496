import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

export const StyledText = styled(Row)`
  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    text-align: left;
    color: #354052;
    margin-bottom: 3px;
  }
  hr {
    margin-top: 7px;
    height: 6px;
    opacity: 1.25;
    background: #e63457;
  }
  h3 {
    font-size: 14px;
    font-weight: 500;
  }
  p {
    font-size: 14px;
    // font-style: italic;
    font-weight: 500;
  }
  .encabezado {
    text-align: center;
    margin-top: 30px;
    p {
      font-weight: bold;
      font-size: 14px;
      font-style: inherit;
    }
  }
  .status-green {
    color: #73bd82;
  }
  .status-red {
    color: red;
  }
  .prueba {
    margin-botton: 15px;
  }
  .btn-campanas {
    text-align: start;
    margin-top: 50px;
    margin-bottom: 100px;
    button {
      background: #50e78e;
      box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      font-size: 16px;
      padding: 8px 25px;
      line-height: 20px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      color: #354052;
    }
  }
`;