import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { Row, Col, Form } from "react-bootstrap";
import { IoReload } from "react-icons/io5";
import { FaLink } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { FaArrowDown } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import stripHtmlPreserveLinks from "./HTMLStripper";
/* Components*/
import Logo from "src/components/Chequeador/Logo";
import { CopyToClipboard } from 'react-copy-html-to-clipboard';

const Chequeador = () => {
  const [formData, setFormData] = useState({
    contentTypes: [],
    contentDescription: "",
    contentScreenshot: "",
    socialNetworks: [{ name: "", metrics: "", screenshot: "" }],
    toolsUsed: [],
    context: "",
    rating: "",
    ratingJustification: "",
    draftTitle: "",
    draftIntroduction: "",
    development: "",
    summary: "",
  });

  const [isOpenOne, setIsOpenOne] = useState(false);
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const [isOpenThree, setIsOpenThree] = useState(false);
  const [isOpenFour, setIsOpenFour] = useState(false);
  const [isOpenFive, setIsOpenFive] = useState(false);
  const [showGeneratedNote, setShowGeneratedNote] = useState(false);
    const [copies, setCopies] = useState(false);

      const [copyStatus, setCopyStatus] = useState('');

 const getNoteContent = () => {
    const rawContent = `
    ${formData.draftTitle}

    ${formData.draftIntroduction}

    ${formData.draftCirculation}

    ${formData.draftToolsSummary}

    ${formData.draftSourcesSummary}

    ${formData.draftContext}

    ${formData.draftCalification}
        `.trim();

        // Limpiar el HTML antes de copiar
        return stripHtml(rawContent);
      };


  const handleCopy = (text, result) => {
    if (result) {
      alert("El borrador ha sido copiado.");
      setCopyStatus('success');
    } else {
      alert("Hubo un error al copiar el borrador. Por favor, inténtalo de nuevo.");
      setCopyStatus('error');
    }
  };

  const handleAccordionToggleOne = (eventKey) => {
    setIsOpenOne(eventKey === "0");
  };

  const handleAccordionToggleTwo = (eventKey) => {
    setIsOpenTwo(eventKey === "0");
  };

  const handleAccordionToggleThree = (eventKey) => {
    setIsOpenThree(eventKey === "0");
  };

  const handleAccordionToggleFour = (eventKey) => {
    setIsOpenFour(eventKey === "0");
  };

  const handleAccordionToggleFive = (eventKey) => {
    setIsOpenFive(eventKey === "0");
  };

  // Definición de las herramientas
  const toolPlaceholderText =
    "Resultados de usar la herramienta (agregar hipervínculos en el texto a los resultados de la herramienta). En caso de no haber resultados también podés comunicarlo.";
  const tools = [
    {
      id: "googleKeywords",
      name: "Búsqueda por palabras",
      description: "Permite buscar información usando palabras claves.",
      placeholder: toolPlaceholderText,
      category: "general",
      examples: [
        { name: "Google", link: "https://google.com" },
        { name: "Bing", link: "https://bing.com" },
      ],
    },
    {
      id: "images",
      name: "Herramientas para imágenes",
      description: "Permite buscar coincidencias visuales en la  web.",
      placeholder: toolPlaceholderText,
      category: "image",
      examples: [
        { name: "Google Imágenes", link: "https://images.google.com/" },
        { name: "TinEye", link: "https://tineye.com/" },
      ],
    },
    {
      id: "videos",
      name: "Herramientas para videos",
      description:
        "Permite generar fotogramas de vídeos para facilitar la búsqueda.",
      placeholder: toolPlaceholderText,
      category: "image",
      examples: [
        {
          name: "InVID",
          link: "https://chromewebstore.google.com/detail/fake-news-debunker-by-inv/mhccpoafgdgbhnjfhkcmgknndkeenfhe",
        },
        {
          name: "YouTube Data Viewer",
          link: "https://ytlarge.com/youtube/video-data-viewer/",
        },
      ],
    },
    {
      id: "ia",
      name: "Herramientas para IA",
      description: "Permite detectar contenido generado con IA.",
      placeholder: toolPlaceholderText,
      category: "video",
      examples: [
        {
          name: "Hive Moderation (texto, imagen, video audio)",
          link: "https://hivemoderation.com/ai-generated-content-detection",
        },
        { name: "Deepware (video)", link: "https://scanner.deepware.ai/" },
        { name: "VerificAudio (audio)", link: "https://verificaudio.ai/" },
      ],
    },
    {
      id: "fact-check-explorer",
      name: "Fact Check Explorer",
      description:
        "Permite encontrar verificaciones sobre temas ya investigados por organizaciones de fact checking.",
      placeholder: toolPlaceholderText,
      category: "audio",
      examples: [
        {
          name: "Fact Check Explorer",
          link: "https://toolbox.google.com/factcheck/explorer/search/list:recent;hl=es",
        },
      ],
    },
    {
      id: "other-tools",
      name: "Otras herramientas",
      description: "Más recursos, apps y programas.",
      placeholder: toolPlaceholderText,
      category: "all",
      examples: [
        {
          name: "Caja de Herramientas",
          link: "https://chequeado.com/herramientasparaperiodistas/index.html#/",
        },
      ],
    },
    {
      id: "contactSources",
      name: "Consultar fuentes",
      description: "Verificar el contenido con fuentes y/o documentos.",
      placeholder:
        "¿Qué fuentes consultaste y que información obtuviste? Hipervíncula todo contenido relevante (perfiles, documentos, etc.).",
      category: "general",
      examples: [],
    },
  ];

  useEffect(() => {
    const savedData = localStorage.getItem("verificationData");
    if (savedData) {
      try {
        setFormData(JSON.parse(savedData));
      } catch (error) {
        console.error("Error parsing saved data:", error);
      }
    }
  }, []);

  useEffect(() => {
    try {
      localStorage.setItem("verificationData", JSON.stringify(formData));
    } catch (error) {
      console.error("Error saving data to localStorage:", error);
    }
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSocialNetworkChange = (index, field, value) => {
    const updatedNetworks = [...formData.socialNetworks];
    updatedNetworks[index] = { ...updatedNetworks[index], [field]: value };
    setFormData((prevData) => ({
      ...prevData,
      socialNetworks: updatedNetworks,
    }));
  };

  const addSocialNetwork = () => {
    setFormData((prevData) => ({
      ...prevData,
      socialNetworks: [
        ...prevData.socialNetworks,
        { name: "", metrics: "", screenshot: "" },
      ],
    }));
  };

  const removeSocialNetwork = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      socialNetworks: prevData.socialNetworks.filter((_, i) => i !== index),
    }));
  };

  const handleToolUse = (toolId, isUsed) => {
    if (isUsed) {
      setFormData((prevData) => ({
        ...prevData,
        toolsUsed: [...prevData.toolsUsed, { id: toolId, conclusion: "" }],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        toolsUsed: prevData.toolsUsed.filter((tool) => tool.id !== toolId),
      }));
    }
  };

  const handleToolConclusion = (conclusion, toolId) => {
    setFormData((prevData) => ({
      ...prevData,
      toolsUsed: prevData.toolsUsed.map((tool) =>
        tool.id === toolId ? { ...tool, conclusion } : tool
      ),
    }));
  };

  const handleContentTypeChange = (type) => {
    setFormData((prevData) => {
      const updatedTypes = prevData.contentTypes.includes(type)
        ? prevData.contentTypes.filter((t) => t !== type)
        : [...prevData.contentTypes, type];
      return { ...prevData, contentTypes: updatedTypes };
    });
  };

  const generateToolsSummary = () => {
    const selectedTools = formData.toolsUsed.filter(
      (tool) => tool.conclusion.trim() !== "" && tool.id !== "contactSources"
    );

    if (selectedTools.length === 0) {
      return "No se utilizaron herramientas en esta verificación.";
    }

    const summaries = selectedTools.map((selectedTool) => {
      return `${selectedTool.conclusion}`;
    });

    let summaryText = "En el proceso de verificación, \n\n";
    summaryText += summaries.join("\n\n");

    return summaryText;
  };

  function generateSourcesSummary() {
    const tools = formData.toolsUsed.filter(
      (tool) => tool.conclusion.trim() !== "" && tool.id === "contactSources"
    );

    if (tools.length > 0) {
      return tools[0].conclusion;
    }

    return "";
  }

  const resetForm = () => {
    setFormData({
      contentTypes: [],
      contentDescription: "",
      contentScreenshot: "",
      socialNetworks: [{ name: "", metrics: "", screenshot: "" }],
      toolsUsed: [],
      context: "",
      rating: "",
      ratingJustification: "",
      draftTitle: "",
      draftIntroduction: "",
      development: "",
      summary: "",
    });
    setShowGeneratedNote(false);
    localStorage.removeItem("verificationData");
  };

  const generateNote = () => {
    const draftTitle = formData.rating ? `Es ${formData.rating} que...` : "";
    const draftIntroduction = `Circula en redes sociales <a href="${formData.contentScreenshot}">${formData.contentDescription
        ? formData.contentDescription
        : " [una imagen/video/audio que...]"
      }</a>. El contenido circulo por ${formData.socialNetworks
        ? formData.socialNetworks.map((network) => `${network.name}`).join(", ")
        : "[redes]"
      }. Esto es ${formData.rating ? formData.rating : "[calificación]"}: ${formData.ratingJustification
      }.`;
    const draftCirculation = `El contenido tuvo ${formData.socialNetworks
      .map((network) => `${network.metrics} en <a href="${network.screenshot}">${network.name}</a>`)
      .join(", ")}.`;
    const draftToolsSummary = generateToolsSummary();
    const draftSourcesSummary = generateSourcesSummary();
    const draftContext = formData.context;
    const draftCalification = `Por todo esto, concluimos que este contenido es ${formData.rating ? formData.rating : "[calificación]"
      }, ya que ${formData.ratingJustification}.`;

    setFormData((prevData) => ({
      ...prevData,
      draftTitle,
      draftIntroduction,
      draftCirculation,
      draftToolsSummary,
      draftSourcesSummary,
      draftContext,
      draftCalification,
    }));

    setShowGeneratedNote(true);
  };

  /* Configuration for advance text editor (React Quill) */
  const handleEditorChange = (content, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: content,
    }));
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
  };

  const modulesMinimal = {
    toolbar: [["bold", "italic", "underline", "strike"], ["link"]],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const formatsMinimal = ["bold", "italic", "underline", "strike", "link"];

const copyNoteToClipboard = () => {
  const processQuillContent = (content) => {
    if (!content) return '';
    
    return content
      .replace(/<p class="ql-[^>]*>/g, '')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '\n') 
      .replace(/<br\s*\/?>/g, '\n')
      .replace(/class="[^"]*"/g, '')
      .replace(/style="[^"]*"/g, '')
      .replace(/data-[^=]*="[^"]*"/g, '')
      .replace(/<span>/g, '')
      .replace(/<\/span>/g, '')
      .replace(/background-color:[^;]*;?/g, '')
      .replace(/\n\s*\n\s*\n/g, '\n')
      .replace(/^\s+|\s+$/gm, '')
      .trim();
  };

  const noteContent = `${formData.draftTitle}

${processQuillContent(formData.draftIntroduction)}

${processQuillContent(formData.draftCirculation)}

${processQuillContent(formData.draftToolsSummary)}

${processQuillContent(formData.draftSourcesSummary)}

${processQuillContent(formData.draftContext)}

${processQuillContent(formData.draftCalification)}`.trim();

  const tempDiv = document.createElement('div');
  Object.assign(tempDiv.style, {
    whiteSpace: 'pre-wrap',
    position: 'absolute',
    left: '-9999px',
    background: 'none !important',
    backgroundColor: 'red !important',
    webkitBackgroundColor: 'transparent !important',
    padding: '0 !important',
    margin: '0 !important'
  });
  
  // Asignar directamente los estilos críticos
  tempDiv.setAttribute('style', 'white-space: pre-wrap !important; background: none !important; background-color: transparent !important; -webkit-background-color: transparent !important; padding: 0 !important; margin: 0 !important;');
  
  tempDiv.innerHTML = noteContent;
  document.body.appendChild(tempDiv);

  try {
    const range = document.createRange();
    range.selectNodeContents(tempDiv);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    
    document.execCommand('copy');
    selection.removeAllRanges();
    alert("El borrador ha sido copiado.");
  } catch (e) {
    console.error('Error al copiar:', e);
    alert("Hubo un error al copiar el borrador. Por favor, inténtalo de nuevo.");
  } finally {
    document.body.removeChild(tempDiv);
  }
};

  
/* const copyNoteToClipboard = () => {
  const processQuillContent = (content) => {
    if (!content) return '';
    
    return content
      .replace(/<p class="ql-[^>]*>/g, '')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '\n') 
      .replace(/<br\s*\/?>/g, '\n')
      .replace(/class="[^"]*"/g, '')
      .replace(/style="[^"]*"/g, '')
      .replace(/data-[^=]*="[^"]*"/g, '')
      .replace(/<span>/g, '')
      .replace(/<\/span>/g, '')
      .replace(/background-color:[^;]*;?/g, '')
      .replace(/\n\s*\n\s*\n/g, '\n')
      .replace(/^\s+|\s+$/gm, '')
      .trim();
  };

  const noteContent = `${formData.draftTitle}

${processQuillContent(formData.draftIntroduction)}

${processQuillContent(formData.draftCirculation)}

${processQuillContent(formData.draftToolsSummary)}

${processQuillContent(formData.draftSourcesSummary)}

${processQuillContent(formData.draftContext)}

${processQuillContent(formData.draftCalification)}`.trim();

  const tempDiv = document.createElement('div');
  tempDiv.contentEditable = 'true';
  tempDiv.style.whiteSpace = 'pre-wrap';
  tempDiv.style.position = 'absolute';
  tempDiv.style.left = '-9999px';
  tempDiv.innerHTML = noteContent;
  document.body.appendChild(tempDiv);

  try {
    tempDiv.focus();
    document.execCommand('selectAll', false, null);
    document.execCommand('copy');
    alert("El borrador ha sido copiado.");
  } catch (e) {
    console.error('Error al copiar:', e);
    alert("Hubo un error al copiar el borrador. Por favor, inténtalo de nuevo.");
  } finally {
    document.body.removeChild(tempDiv);
  }
};
 */
  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  console.log("formData", formData);
  return (
    <div className="container-fluid">
      <Row style={{marginTop: "30px", marginBottom: "40px", marginLeft: "8px", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Col xs={12} sm={12} md={12} lg={6}>
          <div className="titulo">
            <Logo />
            <p>El Chequeador</p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={3} className="btn-reset-container">
            <button onClick={resetForm} className="button-reset">
              <IoReload style={{ marginRight: "8px" }} /> Resetear formulario
            </button>
          </Col>
      </Row>


      <main className="app-main">
        <section className="new two-column-section row">
          <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
            <h2>¿Qué vas a verificar?</h2>
            <div className="card">
              <fieldset className="content-type-selection">
                <legend>
                  Seleccioná el tipo de formato{" "}
                  <span>Seleccionar todos los que correspondan.</span>
                </legend>
                {["Texto", "Audio", "Imagen", "Video"].map((type) => (
                  <label key={type} className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={formData.contentTypes.includes(type)}
                      onChange={() => handleContentTypeChange(type)}
                    />
                    <span className="checkbox-custom"></span>
                    {type}
                  </label>
                ))}
              </fieldset>
              <label htmlFor="contentDescription">
                Descripción del contenido
              </label>
              <textarea
                id="contentDescription"
                name="contentDescription"
                value={formData.contentDescription}
                onChange={handleInputChange}
                placeholder="Proporcioná una descripción detallada del contenido"
                className="textarea-input"
              />
              <label htmlFor="contentScreenshot">Captura de pantalla</label>
              <input
                id="contentScreenshot"
                type="text"
                name="contentScreenshot"
                value={formData.contentScreenshot}
                onChange={handleInputChange}
                placeholder="Link a la captura de pantalla del contenido (Google Drive o similar)"
                className="text-input"
              />
            </div>
          </div>
          <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
            <div className="tips-card">
              <h3>Recomendaciones</h3>
              <ul>
                <li>
                  Seleccioná contenidos fácticos que puedan ser contrastados.
                </li>
                <li>
                  Priorizá contenidos basándote en:
                  <ul>
                    <li>La relevancia del tema aludido.</li>
                    <li>
                      La gravedad de las consecuencias potenciales de la
                      desinformación.
                    </li>
                    <li>
                      Su circulación y viralización en redes sociales u otros
                      medios.
                    </li>
                  </ul>
                </li>
                <li>
                  Describí el contenido.
                  <ul>
                    <li>
                      Si es una imagen o video, describí detalladamente lo que
                      se ve.
                    </li>
                    <li>
                      Incluí detalles como fechas, lugares o personas
                      mencionadas, si están disponibles.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="warning-card-container">
              <div className="warning-card">
                <h3>¡Atención!</h3>
                <ul>
                  <li>
                    No compartas el link del posteo original. Hacerlo puede dar
                    mayor visibilidad al contenido potencialmente desinformante.
                  </li>
                  <li>
                    Guardá una captura del contenido para resguardarte en caso
                    de que se borre o edite el contenido original.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Accordion
            defaultActiveKey={null}
            className="accordion-hide"
            onSelect={handleAccordionToggleOne}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="tips-card">
                  {" "}
                  <h3>
                    Recomendaciones{" "}
                    <MdKeyboardArrowDown
                      size={25}
                      style={{
                        transform: isOpenOne
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease-in-out",
                      }}
                    />
                  </h3>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="tips-card">
                  <ul>
                    <li>
                      Seleccioná contenidos fácticos que puedan ser
                      contrastados.
                    </li>
                    <li>
                      Priorizá contenidos basándote en:
                      <ul>
                        <li>La relevancia del tema aludido.</li>
                        <li>
                          La gravedad de las consecuencias potenciales de la
                          desinformación.
                        </li>
                        <li>
                          Su circulación y viralización en redes sociales u
                          otros medios.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Describí el contenido.
                      <ul>
                        <li>
                          Si es una imagen o video, describí detalladamente lo
                          que se ve.
                        </li>
                        <li>
                          Incluí detalles como fechas, lugares o personas
                          mencionadas, si están disponibles.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div
                  className="warning-card-container"
                  style={{ marginTop: "20px" }}
                >
                  <div className="warning-card">
                    <h3>¡Atención!</h3>
                    <ul>
                      <li>
                        No compartas el link del posteo original. Hacerlo puede
                        dar mayor visibilidad al contenido potencialmente
                        desinformante.
                      </li>
                      <li>
                        Guardá una captura del contenido para resguardarte en
                        caso de que se borre o edite el contenido original.
                      </li>
                    </ul>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </section>

        <section className="-new two-third-column-section row">
          <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
            <h2>¿Dónde está circulando el contenido ahora?</h2>
            <div className="examples-section">
              <p>
                Ingresá los datos de las redes sociales donde el contenido tuvo
                la mayor cantidad de interacciones.
                <br />
                <br />
                El resto de las redes donde circuló con menor viralidad los
                podés agregar más adelante en el borrador de la nota.
              </p>
            </div>
            <div className="card">
              <div className="social-networks-container">
                {formData.socialNetworks.map((network, index) => (
                  <div key={index} className="social-network-box">
                    <div className="social-network-header">
                      <h4>Red social {index + 1}</h4>
                      <button
                        onClick={() => removeSocialNetwork(index)}
                        className="button-icon"
                        aria-label="Eliminar red social"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          width="24"
                          height="24"
                        >
                          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                        </svg>
                      </button>
                    </div>
                    <div className="social-network-fields">
                      <label htmlFor={`networkName-${index}`}>Red social</label>
                      <input
                        id={`networkName-${index}`}
                        type="text"
                        value={network.name}
                        onChange={(e) =>
                          handleSocialNetworkChange(
                            index,
                            "name",
                            e.target.value
                          )
                        }
                        placeholder="Ingresá una red social (ej: Facebook)"
                        className="text-input"
                      />
                      <label htmlFor={`networkMetrics-${index}`}>
                        Métricas
                      </label>
                      <input
                        id={`networkMetrics-${index}`}
                        type="text"
                        value={network.metrics}
                        onChange={(e) =>
                          handleSocialNetworkChange(
                            index,
                            "metrics",
                            e.target.value
                          )
                        }
                        placeholder='Ingresá las métricas más representativas de la red (ej: 600 mil reproducciones y 17 mil "Me gusta")'
                        className="text-input"
                      />
                      <label htmlFor={`networkScreenshot-${index}`}>
                        Captura de pantalla
                      </label>
                      <input
                        id={`networkScreenshot-${index}`}
                        type="text"
                        value={network.screenshot}
                        onChange={(e) =>
                          handleSocialNetworkChange(
                            index,
                            "screenshot",
                            e.target.value
                          )
                        }
                        placeholder="Link a captura de pantalla que visualice las métricas (Google Drive o similar)"
                        className="text-input"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col-lg-5 center-mobile">
                  <button
                    onClick={addSocialNetwork}
                    className="button-secondary"
                  >
                    Agregar red social
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
            <div className="tips-card">
              <h3>Recomendaciones</h3>
              <ul>
                <li>
                  Monitoreá y registrá la circulación en diversas plataformas:
                  <ul>
                    <li>Redes sociales (Facebook, X, Instagram, etc.).</li>
                    <li>
                      Aplicaciones de mensajería (WhatsApp, Telegram, etc.).
                    </li>
                    <li>Medios de comunicación tradicionales y digitales.</li>
                  </ul>
                </li>
                <li>
                  Proporcioná datos cuantitativos cuando estén disponibles:
                  <ul>
                    <li>Número de compartidos.</li>
                    <li>Likes o reacciones.</li>
                    <li>Comentarios.</li>
                    <li>Visualizaciones o reproducciones.</li>
                  </ul>
                </li>
                <li>
                  Indicá la fecha en que empezó a circular el contenido, si es
                  posible.
                </li>
                <li>
                  Mencioná si figuras públicas o medios de comunicación han
                  compartido el contenido.
                </li>
                <li>
                  Guardá capturas de pantalla de la circulación en cada
                  plataforma para documentación.
                </li>
              </ul>
            </div>
            <div className="warning-card-container">
              <div className="warning-card">
                <h3>¡Atención!</h3>
                <ul>
                  <li>
                    No compartas el link del posteo original. Hacerlo puede dar
                    mayor visibilidad al contenido potencialmente desinformante.
                  </li>
                  <li>
                    Guardá una captura del contenido para resguardarte en caso
                    de que se borre o edite el contenido original.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Accordion
            defaultActiveKey={null}
            className="accordion-hide"
            onSelect={handleAccordionToggleTwo}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="tips-card">
                  {" "}
                  <h3>
                    Recomendaciones{" "}
                    <MdKeyboardArrowDown
                      size={25}
                      style={{
                        transform: isOpenTwo
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease-in-out",
                      }}
                    />
                  </h3>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="tips-card">
                  <ul>
                    <li>
                      Monitoreá y registrá la circulación en diversas
                      plataformas:
                      <ul>
                        <li>Redes sociales (Facebook, X, Instagram, etc.).</li>
                        <li>
                          Aplicaciones de mensajería (WhatsApp, Telegram, etc.).
                        </li>
                        <li>
                          Medios de comunicación tradicionales y digitales.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Proporcioná datos cuantitativos cuando estén disponibles:
                      <ul>
                        <li>Número de compartidos.</li>
                        <li>Likes o reacciones.</li>
                        <li>Comentarios.</li>
                        <li>Visualizaciones o reproducciones.</li>
                      </ul>
                    </li>
                    <li>
                      Indicá la fecha en que empezó a circular el contenido, si
                      es posible.
                    </li>
                    <li>
                      Mencioná si figuras públicas o medios de comunicación han
                      compartido el contenido.
                    </li>
                    <li>
                      Guardá capturas de pantalla de la circulación en cada
                      plataforma para documentación.
                    </li>
                  </ul>
                </div>
                <div
                  className="warning-card-container"
                  style={{ marginTop: "20px" }}
                >
                  <div className="warning-card">
                    <h3>¡Atención!</h3>
                    <ul>
                      <li>
                        No compartas el link del posteo original. Hacerlo puede
                        dar mayor visibilidad al contenido potencialmente
                        desinformante.
                      </li>
                      <li>
                        Guardá una captura del contenido para resguardarte en
                        caso de que se borre o edite el contenido original.
                      </li>
                    </ul>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </section>

        <section className="new two-third-column-section row">
          <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
            <h2>¿Cómo vas a investigar el contenido?</h2>
            <div className="examples-section">
              <p>
                Utilizá todas las herramientas que necesites para encontrar el
                contenido original u otra información que te permita avanzar con
                el proceso de verificación.
              </p>
            </div>
            <div className="tools-grid">
              {tools.map((tool) => (
                <div key={tool.id} className="tool-card">
                  <label
                    className="checkbox-label"
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                  >
                    <input
                      type="checkbox"
                      checked={formData.toolsUsed.some((t) => t.id === tool.id)}
                      onChange={(e) => handleToolUse(tool.id, e.target.checked)}
                    />
                    <span
                      className="checkbox-custom"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    ></span>
                    {tool.name}
                  </label>
                  <p>{tool.description}</p>
                  {tool.examples && tool.examples.length > 0 && (
                    <div className="tool-examples">
                      <h4>Herramientas recomendadas</h4>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {tool.examples.map((example, index) => (
                          <>
                            <div key={index}>
                              <FaLink size={14} />{" "}
                              <a
                                className="tool-examples-link"
                                href={example.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {example.name}
                              </a>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  )}

                  {formData.toolsUsed.some((t) => t.id === tool.id) && (
                    <ReactQuill
                      theme="snow"
                      value={
                        formData.toolsUsed.find((t) => t.id === tool.id)
                          ?.conclusion || ""
                      }
                      onChange={(content) =>
                        handleToolConclusion(content, tool.id)
                      }
                      modules={modulesMinimal}
                      formats={formatsMinimal}
                      placeholder={tool.placeholder}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="tips-column hidde-mobile  col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
            <div className="tips-card ">
              <h3>Recomendaciones</h3>
              <ul>
                <li>
                  Para imágenes y videos:
                  <ul>
                    <li>
                      Utilizá herramientas de búsqueda inversa de imágenes.
                    </li>
                    <li>
                      Verificá metadatos (fecha, hora y lugar de creación).
                    </li>
                    <li>
                      Utilizá herramientas para detectar generaciones con IA.
                    </li>
                  </ul>
                </li>
                <li>
                  Para audios:
                  <ul>
                    <li>Contactá con las fuentes o personas mencionadas.</li>
                    <li>
                      Utilizá herramientas para detectar generación con IA.
                    </li>
                  </ul>
                </li>
                <li>
                  Intentá contactar a la fuente original del contenido para
                  conocer su fundamento.
                </li>
                <li>
                  Si es posible, contactá a los actores involucrados o afectados
                  por la desinformación.
                </li>
                <li>
                  Consultá fuentes alternativas como:
                  <ul>
                    <li>Informes y análisis de expertos.</li>
                    <li>Recopilaciones estadísticas de fuentes confiables.</li>
                  </ul>
                </li>
                <li>
                  Documentá todos tus hallazgos y cita todas las fuentes
                  utilizadas.
                </li>
                <li>Incluí enlaces a los documentos y datos originales.</li>
              </ul>

              <ul>
                <li>
                  ¿La circulación del contenido se da en el marco de algún
                  evento político, económico o social de relevancia? ¿Cuál?
                  ¿Cómo se relaciona este contenido con ese evento? (Por ejemplo
                  campañas electorales, dictámenes o debate de leyes, atentados,
                  manifestaciones, etc.)
                </li>
                <li>
                  ¿El contenido ya circuló en el pasado? ¿Fue desmentido o
                  trabajado por otros chequeadores? ¿Circuló en otros países?
                </li>
                <li>
                  ¿Forma parte de una narrativa que incluye otras
                  desinformaciones sobre el mismo tema? ¿Podés proporcionar
                  ejemplos? ¿Qué registros hay de esta narrativa?
                </li>
                <li>
                  ¿El contenido está agrupado dentro del marco de una teoría
                  conspirativa? ¿Cuál? ¿Qué fuentes pueden consultarse sobre el
                  tema?
                </li>
                <li>
                  ¿El formato de la desinformación es recurrente, como placas de
                  medios con citas falsas o imágenes o videos creados con IA?
                  Podés comentarlo y aportar consejos o recomendaciones para
                  detectarlas.
                </li>
              </ul>
            </div>
          </div>
          <Accordion
            defaultActiveKey={null}
            className="accordion-hide"
            onSelect={handleAccordionToggleThree}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="tips-card">
                  {" "}
                  <h3>
                    Recomendaciones{" "}
                    <MdKeyboardArrowDown
                      size={25}
                      style={{
                        transform: isOpenThree
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease-in-out",
                      }}
                    />
                  </h3>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="tips-card">
                  <ul>
                    <li>
                      Para imágenes y videos:
                      <ul>
                        <li>
                          Utilizá herramientas de búsqueda inversa de imágenes.
                        </li>
                        <li>
                          Verificá metadatos (fecha, hora y lugar de creación).
                        </li>
                        <li>
                          Utilizá herramientas para detectar generaciones con
                          IA.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Para audios:
                      <ul>
                        <li>
                          Contactá con las fuentes o personas mencionadas.
                        </li>
                        <li>
                          Utilizá herramientas para detectar generación con IA.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Intentá contactar a la fuente original del contenido para
                      conocer su fundamento.
                    </li>
                    <li>
                      Si es posible, contactá a los actores involucrados o
                      afectados por la desinformación.
                    </li>
                    <li>
                      Consultá fuentes alternativas como:
                      <ul>
                        <li>Informes y análisis de expertos.</li>
                        <li>
                          Recopilaciones estadísticas de fuentes confiables.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Documentá todos tus hallazgos y cita todas las fuentes
                      utilizadas.
                    </li>
                    <li>Incluí enlaces a los documentos y datos originales.</li>
                  </ul>

                  <ul>
                    <li>
                      ¿La circulación del contenido se da en el marco de algún
                      evento político, económico o social de relevancia? ¿Cuál?
                      ¿Cómo se relaciona este contenido con ese evento? (Por
                      ejemplo campañas electorales, dictámenes o debate de
                      leyes, atentados, manifestaciones, etc.)
                    </li>
                    <li>
                      ¿El contenido ya circuló en el pasado? ¿Fue desmentido o
                      trabajado por otros chequeadores? ¿Circuló en otros
                      países?
                    </li>
                    <li>
                      ¿Forma parte de una narrativa que incluye otras
                      desinformaciones sobre el mismo tema? ¿Podés proporcionar
                      ejemplos? ¿Qué registros hay de esta narrativa?
                    </li>
                    <li>
                      ¿El contenido está agrupado dentro del marco de una teoría
                      conspirativa? ¿Cuál? ¿Qué fuentes pueden consultarse sobre
                      el tema?
                    </li>
                    <li>
                      ¿El formato de la desinformación es recurrente, como
                      placas de medios con citas falsas o imágenes o videos
                      creados con IA? Podés comentarlo y aportar consejos o
                      recomendaciones para detectarlas.
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </section>

        <section className="new two-column-section row">
          <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
            <h2>¿Qué contexto podés aportar sobre este contenido?</h2>
            <div className="card">
              <label htmlFor="context">Contexto</label>
              <textarea
                id="context"
                name="context"
                value={formData.context}
                onChange={handleInputChange}
                placeholder="¿Qué contexto podés aportar sobre este contenido?"
                className="textarea-input"
              />
            </div>
          </div>
          <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
            <div className="tips-card">
              <h3>Recomendaciones</h3>
              <p>
                Algunas preguntas que pueden ayudar a construir el contexto:
              </p>
              <ul>
                <li>
                  ¿La circulación del contenido se da en el marco de algún
                  evento político, económico o social de relevancia? ¿Cuál?
                  ¿Cómo se relaciona este contenido con ese evento? (Por ejemplo
                  campañas electorales, dictámenes o debate de leyes, atentados,
                  manifestaciones, etc.)
                </li>
                <li>
                  ¿El contenido ya circuló en el pasado? ¿Fue desmentido o
                  trabajado por otros chequeadores? ¿Circuló en otros países?
                </li>
                <li>
                  ¿Forma parte de una narrativa que incluye otras
                  desinformaciones sobre el mismo tema? ¿Podés proporcionar
                  ejemplos? ¿Qué registros hay de esta narrativa?
                </li>
                <li>
                  ¿El contenido está agrupado dentro del marco de una teoría
                  conspirativa? ¿Cuál? ¿Qué fuentes pueden consultarse sobre el
                  tema?
                </li>
                <li>
                  ¿El formato de la desinformación es recurrente, como placas de
                  medios con citas falsas o imágenes o videos creados con IA?
                  Podés comentarlo y aportar consejos o recomendaciones para
                  detectarlas.
                </li>
              </ul>
              <p>
                Además, si el contenido fue elaborado sobre una temática
                compleja, podés incluir contexto para explicar esa temática.
              </p>
            </div>
          </div>
          <Accordion
            defaultActiveKey={null}
            className="accordion-hide"
            onSelect={handleAccordionToggleFour}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="tips-card">
                  {" "}
                  <h3>
                    Recomendaciones{" "}
                    <MdKeyboardArrowDown
                      size={25}
                      style={{
                        transform: isOpenFour
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease-in-out",
                      }}
                    />
                  </h3>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="tips-card">
                  <p>
                    Algunas preguntas que pueden ayudar a construir el contexto:
                  </p>
                  <ul>
                    <li>
                      ¿La circulación del contenido se da en el marco de algún
                      evento político, económico o social de relevancia? ¿Cuál?
                      ¿Cómo se relaciona este contenido con ese evento? (Por
                      ejemplo campañas electorales, dictámenes o debate de
                      leyes, atentados, manifestaciones, etc.)
                    </li>
                    <li>
                      ¿El contenido ya circuló en el pasado? ¿Fue desmentido o
                      trabajado por otros chequeadores? ¿Circuló en otros
                      países?
                    </li>
                    <li>
                      ¿Forma parte de una narrativa que incluye otras
                      desinformaciones sobre el mismo tema? ¿Podés proporcionar
                      ejemplos? ¿Qué registros hay de esta narrativa?
                    </li>
                    <li>
                      ¿El contenido está agrupado dentro del marco de una teoría
                      conspirativa? ¿Cuál? ¿Qué fuentes pueden consultarse sobre
                      el tema?
                    </li>
                    <li>
                      ¿El formato de la desinformación es recurrente, como
                      placas de medios con citas falsas o imágenes o videos
                      creados con IA? Podés comentarlo y aportar consejos o
                      recomendaciones para detectarlas.
                    </li>
                  </ul>
                  <p>
                    Además, si el contenido fue elaborado sobre una temática
                    compleja, podés incluir contexto para explicar esa temática.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </section>

        <section className="new two-column-section row">
          <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
            <h2>¿Cuál es tu conclusión?</h2>
            <div className="card">
              <label htmlFor="rating">Calificación</label>
              <select
                id="rating"
                name="rating"
                value={formData.rating}
                onChange={handleInputChange}
                className="select-input"
              >
                <option disabled selected>
                  Seleccioná una opción
                </option>
                <option value="verdadero">Verdadero</option>
                <option value="falso">Falso</option>
                <option value="engañoso">Engañoso</option>
              </select>
              <label htmlFor="ratingJustification">Justificación</label>
              <textarea
                id="ratingJustification"
                name="ratingJustification"
                value={formData.ratingJustification}
                onChange={handleInputChange}
                placeholder="Proporcioná una justificación detallada de la calificación."
                className="textarea-input-conclusion"
              />
            </div>
          </div>
          <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
            <div className="tips-card">
              <h3>Recomendaciones</h3>
              <ul>
                <li>
                  Calificaciones:
                  <ul>
                    <li>
                      <strong>Falso:</strong> El contenido completo ha
                      demostrado ser falso al contrastarlo con fuentes
                      confiables.
                    </li>
                    <li>
                      <strong>Engañoso:</strong> Parte del contenido puede ser
                      verdadero, pero ha sido manipulado para generar un mensaje
                      particular.
                    </li>
                    <li>
                      <strong>Verdadero:</strong> El contenido completo ha
                      demostrado ser verdadero al contrastarlo con fuentes
                      confiables.
                    </li>
                    <li>
                      <strong>Insostenible:</strong> La afirmación surge de
                      investigaciones con falta de sustento o errores
                      metodológicos graves, o es imposible de verificar.
                    </li>
                  </ul>
                </li>
                <li>
                  Basá tu calificación en la evidencia recopilada y las fuentes
                  consultadas.
                </li>
                <li>
                  Justificá tu calificación con hechos concretos y citas de
                  fuentes confiables.
                </li>
                <li>
                  Si hay aspectos que no pudiste verificar completamente,
                  mencionalo en tu justificación.
                </li>
                <li>
                  Sé transparente sobre el proceso de verificación y las
                  limitaciones encontradas.
                </li>
              </ul>
            </div>
          </div>
          <Accordion
            defaultActiveKey={null}
            className="accordion-hide"
            onSelect={handleAccordionToggleFive}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="tips-card">
                  {" "}
                  <h3>
                    Recomendaciones{" "}
                    <MdKeyboardArrowDown
                      size={25}
                      style={{
                        transform: isOpenFive
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease-in-out",
                      }}
                    />
                  </h3>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="tips-card">
                  <ul>
                    <li>
                      Calificaciones:
                      <ul>
                        <li>
                          <strong>Falso:</strong> El contenido completo ha
                          demostrado ser falso al contrastarlo con fuentes
                          confiables.
                        </li>
                        <li>
                          <strong>Engañoso:</strong> Parte del contenido puede
                          ser verdadero, pero ha sido manipulado para generar un
                          mensaje particular.
                        </li>
                        <li>
                          <strong>Verdadero:</strong> El contenido completo ha
                          demostrado ser verdadero al contrastarlo con fuentes
                          confiables.
                        </li>
                        <li>
                          <strong>Insostenible:</strong> La afirmación surge de
                          investigaciones con falta de sustento o errores
                          metodológicos graves, o es imposible de verificar.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Basá tu calificación en la evidencia recopilada y las
                      fuentes consultadas.
                    </li>
                    <li>
                      Justificá tu calificación con hechos concretos y citas de
                      fuentes confiables.
                    </li>
                    <li>
                      Si hay aspectos que no pudiste verificar completamente,
                      mencionalo en tu justificación.
                    </li>
                    <li>
                      Sé transparente sobre el proceso de verificación y las
                      limitaciones encontradas.
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </section>

         <section className="verification-step-new nw-section">
          <div className="form-container">
            <h2>¿Cómo redactarás tu nota de verificación?</h2>

            <button
              onClick={generateNote}
              className="button-primary nw-generate-button"
            >
              Generar borrador de nota
            </button>
          </div>
          <div className="card nw-card"></div>
          {showGeneratedNote && (
            <div className="nw-generated-note">
              <div className="nw-input-group">
                <div className="nw-input-field">
                  <label htmlFor="draftTitle" className="nw-label">
                    Título: debe incluir la calificación y mencionar la
                    desinformación.
                  </label>
                  <input
                    id="draftTitle"
                    type="text"
                    name="draftTitle"
                    value={formData.draftTitle}
                    onChange={handleInputChange}
                    placeholder="Título de la verificación"
                    className="text-input nw-text-input"
                  />
                </div>
                <div className="nw-recommendation">
                  <h4 className="nw-recommendation-title">Ejemplo:</h4>
                  <p className="nw-recommendation-text">
                    No, este video no muestra un “mar hecho hielo” en la
                    Patagonia argentina: fue grabado en 2020 en Turquía.
                  </p>
                </div>
              </div>

              <div className="nw-input-group">
                <div className="nw-input-field">
                  <label htmlFor="draftIntroduction" className="nw-label">
                    Explicar dónde circuló, describir la desinformación e
                    indicar la calificación.
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={formData.draftIntroduction}
                    onChange={(content) =>
                      handleEditorChange(content, "draftIntroduction")
                    }
                    modules={modules}
                    formats={formats}
                    placeholder={
                      "Es " + formData.rating.toLowerCase() + " que..."
                    }
                  />
                </div>
                <div className="nw-recommendation">
                  <h4 className="nw-recommendation-title"> Ejemplo:</h4>
                  <p className="nw-recommendation-text">
                    Circula en redes sociales una imagen de un embotellamiento
                    en el peaje de Hudson de la autopista Buenos Aires – La
                    Plata. Los posteos aseguran que fue tomada durante el último
                    fin de semana largo, del 20 al 23 de junio de 2024. Pero
                    esto es falso. La fotografía corresponde a Semana Santa de
                    marzo de 2018.
                  </p>
                </div>
              </div>

              <div className="nw-input-group">
                <div className="nw-input-field">
                  <label htmlFor="draftCirculation" className="nw-label">
                    Explicar en detalle la cantidad de redes en las que circuló
                    y sus correspondientes interacciones.
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={formData.draftCirculation}
                    onChange={(content) =>
                      handleEditorChange(content, "draftCirculation")
                    }
                    modules={modules}
                    formats={formats}
                  />
                </div>
                <div className="nw-recommendation">
                  <h4 className="nw-recommendation-title">Ejemplo:</h4>
                  <p className="nw-recommendation-text">
                    La desinformación se viralizó en las últimas horas a través
                    de X -superando las 600 mil reproducciones, 17 mil “Me
                    gusta” y 5 mil “reposteos”- y de YouTube.
                  </p>
                </div>
              </div>

              <div className="nw-input-group">
                <div className="nw-input-field">
                  <label htmlFor="draftToolsSummary" className="nw-label">
                    Especificar las herramientas digitales utilizadas e incluir
                    links con capturas de pantalla.
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={formData.draftToolsSummary}
                    onChange={(content) =>
                      handleEditorChange(content, "draftToolsSummary")
                    }
                    modules={modules}
                    formats={formats}
                  />
                </div>
                <div className="nw-recommendation">
                  <h4 className="nw-recommendation-title">Ejemplo:</h4>
                  <p className="nw-recommendation-text">
                    Una búsqueda inversa en Google con fragmentos del video
                    arroja entre sus coincidencias diversas publicaciones que
                    circulan con las mismas imágenes a través de X, Facebook,
                    Instagram, Threads y YouTube desde el 1 de junio de 2024.
                  </p>
                </div>
              </div>

              <div className="nw-input-group">
                <div className="nw-input-field">
                  <label htmlFor="draftSourcesSummary" className="nw-label">
                    Indican las fuentes consultadas y referencias documentales
                    (si corresponde). Incluir enlaces.
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={formData.draftSourcesSummary}
                    onChange={(content) =>
                      handleEditorChange(content, "draftSourcesSummary")
                    }
                    modules={modules}
                    formats={formats}
                  />
                </div>
                <div className="nw-recommendation">
                  <h4 className="nw-recommendation-title">Ejemplo:</h4>
                  <p className="nw-recommendation-text">
                    Chequeado se comunicó con Alex Milberg, director de Forbes
                    Argentina, quien señaló: “El ranking de los músicos fue
                    publicado en otra etapa de la revista. Esa información nunca
                    fue actualizada y publicarla como si fuera nueva es
                    desinformar.
                  </p>
                </div>
              </div>

              <div className="nw-input-group">
                <div className="nw-input-field">
                  <label htmlFor="draftContext" className="nw-label">
                    Contextualizar y explicar la investigación que sostiene la
                    calificación.
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={formData.draftContext}
                    onChange={(content) =>
                      handleEditorChange(content, "draftContext")
                    }
                    modules={modules}
                    formats={formats}
                  />
                </div>
                <div className="nw-recommendation">
                  <h4 className="nw-recommendation-title">Ejemplo:</h4>
                  <p className="nw-recommendation-text">
                    La imagen ya fue verificada por otras organizaciones de fact
                    checking y, a través de una búsqueda inversa se puede
                    comprobar que la imagen ya fue difundida en mayo de 2021 por
                    el medio colombiano Semana y por medios argentinos.
                  </p>
                </div>
              </div>

              <div className="nw-input-group">
                <div className="nw-input-field">
                  <label htmlFor="draftCalification" className="nw-label">
                    Calificar: indicar nuevamente la calificación y explicar.
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={formData.draftCalification}
                    onChange={(content) =>
                      handleEditorChange(content, "draftCalification")
                    }
                    modules={modules}
                    formats={formats}
                  />
                </div>
                <div className="nw-recommendation">
                  <h4 className="nw-recommendation-title">Ejemplo:</h4>
                  <p className="nw-recommendation-text">
                    Por lo tanto, es falso que este video donde se ve una
                    concentración de personas con banderas palestinas haya sido
                    grabado durante la última jornada electoral en Francia:
                    corresponde a una protesta a favor de Palestina del pasado 1
                    de junio.
                  </p>
                </div>
              </div>

              <div className="nw-copy-section">
                <button
                  onClick={copyNoteToClipboard}
                  className="button-secondary nw-copy-button"
                >
                  Copiar borrador
                </button>
              </div>
            </div>
          )}
        </section>
      
       {/*  <section className="verification-step-new nw-section">
          <div>
            <h2>¿Cómo redactarás tu nota de verificación?</h2>
            <div className="card nw-card"></div>

            <button
              onClick={generateNote}
              className="button-primary nw-generate-button"
            >
              Generar borrador de nota
            </button>

            {showGeneratedNote && (
              <div className="nw-generated-note">
                <div className="nw-input-group">
                  <div className="nw-input-field">
                    <label htmlFor="draftTitle" className="nw-label">
                      Título: debe incluir la calificación y mencionar la
                      desinformación.
                    </label>
                    <input
                      id="draftTitle"
                      type="text"
                      name="draftTitle"
                      value={formData.draftTitle}
                      onChange={handleInputChange}
                      placeholder="Título de la verificación"
                      className="text-input nw-text-input"
                    />
                  </div>
                  <div className="nw-recommendation">
                    <h4 className="nw-recommendation-title">Ejemplo:</h4>
                    <p className="nw-recommendation-text">
                      No, este video no muestra un “mar hecho hielo” en la
                      Patagonia argentina: fue grabado en 2020 en Turquía.
                    </p>
                  </div>
                </div>

                <div className="nw-input-group">
                  <div className="nw-input-field">
                    <label htmlFor="draftIntroduction" className="nw-label">
                      Explicar dónde circuló, describir la desinformación e
                      indicar la calificación
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={formData.draftIntroduction}
                      onChange={(content) =>
                        handleEditorChange(content, "draftIntroduction")
                      }
                      modules={modules}
                      formats={formats}
                      placeholder={
                        "Es " + formData.rating.toLowerCase() + " que..."
                      }
                    />
                  </div>
                  <div className="nw-recommendation">
                    <h4 className="nw-recommendation-title"> Ejemplo:</h4>
                    <p className="nw-recommendation-text">
                      Circula en redes sociales una imagen de un embotellamiento
                      en el peaje de Hudson de la autopista Buenos Aires – La
                      Plata. Los posteos aseguran que fue tomada durante el
                      último fin de semana largo, del 20 al 23 de junio de 2024.
                      Pero esto es falso. La fotografía corresponde a Semana
                      Santa de marzo de 2018.
                    </p>
                  </div>
                </div>

                <div className="nw-input-group">
                  <div className="nw-input-field">
                    <label htmlFor="draftCirculation" className="nw-label">
                      Explicar en detalle la cantidad de redes en las que
                      circuló y sus correspondientes interacciones.
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={formData.draftCirculation}
                      onChange={(content) =>
                        handleEditorChange(content, "draftCirculation")
                      }
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                  <div className="nw-recommendation">
                    <h4 className="nw-recommendation-title">Ejemplo:</h4>
                    <p className="nw-recommendation-text">
                      La desinformación se viralizó en las últimas horas a
                      través de X -superando las 600 mil reproducciones, 17 mil
                      “Me gusta” y 5 mil “reposteos”- y de YouTube.
                    </p>
                  </div>
                </div>

                <div className="nw-input-group">
                  <div className="nw-input-field">
                    <label htmlFor="draftToolsSummary" className="nw-label">
                      Especificar las herramientas digitales utilizadas e
                      incluir links con capturas de pantalla:
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={formData.draftToolsSummary}
                      onChange={(content) =>
                        handleEditorChange(content, "draftToolsSummary")
                      }
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                  <div className="nw-recommendation">
                    <h4 className="nw-recommendation-title">Ejemplo:</h4>
                    <p className="nw-recommendation-text">
                      Una búsqueda inversa en Google con fragmentos del video
                      arroja entre sus coincidencias diversas publicaciones que
                      circulan con las mismas imágenes a través de X, Facebook,
                      Instagram, Threads y YouTube desde el 1 de junio de 2024.
                    </p>
                  </div>
                </div>

                <div className="nw-input-group">
                  <div className="nw-input-field">
                    <label htmlFor="draftSourcesSummary" className="nw-label">
                      Indican las fuentes consultadas y referencias documentales
                      (si corresponde). Incluir enlaces.
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={formData.draftSourcesSummary}
                      onChange={(content) =>
                        handleEditorChange(content, "draftSourcesSummary")
                      }
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                  <div className="nw-recommendation">
                    <h4 className="nw-recommendation-title">Ejemplo:</h4>
                    <p className="nw-recommendation-text">
                      Chequeado se comunicó con Alex Milberg, director de Forbes
                      Argentina, quien señaló: “El ranking de los músicos fue
                      publicado en otra etapa de la revista. Esa información
                      nunca fue actualizada y publicarla como si fuera nueva es
                      desinformar.
                    </p>
                  </div>
                </div>

                <div className="nw-input-group">
                  <div className="nw-input-field">
                    <label htmlFor="draftContext" className="nw-label">
                      Contextualizar y explicar la investigación que sostiene la
                      calificación
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={formData.draftContext}
                      onChange={(content) =>
                        handleEditorChange(content, "draftContext")
                      }
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                  <div className="nw-recommendation">
                    <h4 className="nw-recommendation-title">Ejemplo:</h4>
                    <p className="nw-recommendation-text">
                      La imagen ya fue verificada por otras organizaciones de
                      fact checking y, a través de una búsqueda inversa se puede
                      comprobar que la imagen ya fue difundida en mayo de 2021
                      por el medio colombiano Semana y por medios argentinos.
                    </p>
                  </div>
                </div>

                <div className="nw-input-group">
                  <div className="nw-input-field">
                    <label htmlFor="draftCalification" className="nw-label">
                      Calificar: indicar nuevamente la calificación y explicar.
                    </label>
                    <ReactQuill
                      theme="snow"
                      value={formData.draftCalification}
                      onChange={(content) =>
                        handleEditorChange(content, "draftCalification")
                      }
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                  <div className="nw-recommendation">
                    <h4 className="nw-recommendation-title">Ejemplo:</h4>
                    <p className="nw-recommendation-text">
                      Por lo tanto, es falso que este video donde se ve una
                      concentración de personas con banderas palestinas haya
                      sido grabado durante la última jornada electoral en
                      Francia: corresponde a una protesta a favor de Palestina
                      del pasado 1 de junio.
                    </p>
                  </div>
                </div>

                <div className="nw-copy-section">
                  <button
                    onClick={copyNoteToClipboard}
                    className="button-secondary nw-copy-button"
                  >
                    Copiar borrador
                  </button>
                </div>
              </div>
            )}
          </div>
        </section> */}
      </main>
    </div>
  );
};

export default Chequeador;
