
const VEREDICTOS = {
  pending: "Falta chequear",
  in_progress: "En proceso de chequeo",
  done: "Chequeado",
  cancelled: "No se chequea",
};

export function getVeredicto(veredictoKey) {
  const veredicto = veredictoKey;
  return VEREDICTOS[veredicto] ? VEREDICTOS[veredicto] : veredicto;
}

const COLORES_VEREDICTO = {
  pending: "#F72D54",
  in_progress: "#EBEF0F",
  done: "#25D366",
  cancelled: "#C4C4C4",
};

export function getColorVeredict(veredictoKey) {
  if (veredictoKey != null) {
    const color = veredictoKey.toLowerCase();
    return COLORES_VEREDICTO[color];
  } else {
    return "#354052";
  }
}

const STATUS = {
  pending: "Falta chequear",
  in_progress: "En proceso",
  done: "Chequeado",
  cancelled: "No se chequea",
};

export function getStatusLabel(status) {
  if (status != null) {
    return STATUS[status];
  } else {
    return STATUS["pending"];
  }
}


export function numberFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast(item => num >= item.value);
  return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
}