import React from "react";

/* Bootstrap Components */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";


/* Others */
import cajavacia from "src/assets/img/cajavacia.png";

function NoEncontrado() {

    return (
      <Container>
        <Row>
          <Col className="text-center">
            <img src={cajavacia} className="img-fluid mx-auto d-block mb-4 mt-4" alt="not-found"/>
            <h4 style={{color: '#354052'}}>No hay resultados para esta búsqueda.<br /> Intentá buscar por un rango mayor de días. </h4>
          </Col>
        </Row>
      </Container>
    );
  }

export default NoEncontrado;
