import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
/* Styled */
import { StyledMedios } from "./styled";
import { getFileUpload, errorFile, getRedesRequest } from "src/store";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FaTrash } from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaTwitter, FaYoutube } from "react-icons/fa";

function RedesQsch(props) {
  const [data, setData] = useState();
  const [dataUser, setDataUser] = useState();
  const [loading, setloading] = useState(true);

  const [fileUploadOngoing, setfileUploadOngoing] = useState(false);
  const [fileUploadOngoingYT, setfileUploadOngoingYT] = useState(false);
  useEffect(() => {
    if (props.user_data) {
      setData(props.user_data);
    }
  }, [props.user_data]);

  useEffect(() => {
    if (props.redes_qsch) {
      setDataUser(props.redes_qsch);
    }
  }, [props.redes_qsch]);

  useEffect(() => {
    try {
      if (props.login) {
        const fetchUser = async () => {
          await props.getRedesRequest();
        };
        fetchUser();
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [props.login]);

  useEffect(() => {
    try {
      if (dataUser && Object.keys(dataUser).length != 0) {
        setloading(false);
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [dataUser]);

  const uploadFile = () => {
    setfileUploadOngoing(true);

    const fileInput = document.querySelector("#fileInput");
    const formData = new FormData();

    formData.append("file", fileInput.files[0]);

    props.getFileUpload(formData);
  };

  const uploadFileYT = () => {
    setfileUploadOngoingYT(true);

    const fileInput = document.querySelector("#fileInputYT");
    const formData = new FormData();

    formData.append("file", fileInput.files[0]);

    props.getFileUpload(formData);
  };

  const handleRemoveRed = (id) => {
    
  };

  return (
    <StyledMedios>
      {/* {data?.map((el) => (
        <h2>
          <span>{el.user.username}</span>
        </h2>
      ))}
 */}
      <Row>
        <Col lg={5}>
          <h3>
            <FaTwitter
              style={{ color: "#33CCFF", paddingRight: "4px" }}
              size={25}
            />
            Lista de usuario de Twitter
          </h3>
          <div className="tablecontainer"
            style={{
              overflow: "scroll",
              height: "600px",
              overflowX: "hidden",
              border: "1px solid grey",
              marginBottom: "40px",
            }}
          >
            <Table bordered size="sm" style={{ overflow: "scroll", height: "600px", overflowX: "hidden", backgroundColor: "white" }} bordered={ false }>
              <thead style={{position: 'sticky', top: '-2px', zIndex: "1"}}>
                <tr>
                  <th style={{ width: "90%", textAlign: "inherit" }}>Nombre</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {!loading
                  ? dataUser?.map((el) => (
                      <>
                        {el.twitter_accounts.map((i) => (
                          <tr>
                            <td
                              style={{
                                fontSize: "16px",
                                padding: "16px 0 10px 10px",
                              }}
                            >
                              {i.username}
                            </td>
                            <td>
                              <button
                                style={{
                                  //border: "1px solid white",
                                  background: "white",
                                  border: "none"
                                }}
                                onClick={(id) => handleRemoveRed(i.id)}
                              >
                                <FaTrash style={{ color: "#F62D54" }} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>
          <Row>
            <Col
              lg={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: "16px" }}>Añadir cuenta mediante .cvs</p>
            </Col>
            <Col lg={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                <input type='file' name='photo' id='photo' />
                <label for='photo'>
                    <span>Seleccionar archivo</span>
                </label>
                </div>
                
                <Button onClick={uploadFileYT} variant="primary">
                  Importar
                </Button>
                {props.file_error ? <p>No se pudo cargar el archivo</p> : null}
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={5}>
          <h3>
            <FaYoutube
              style={{ color: "red", paddingRight: "4px" }}
              size={25}
            />
            Lista de canales de Youtube
          </h3>
          <div className="tablecontainer"
            style={{
              overflow: "scroll",
              height: "600px",
              overflowX: "hidden",
              border: "1px solid grey",
              marginBottom: "40px",
            }}
          >
            <Table bordered size="sm" style={{ overflow: "scroll", height: "600px", overflowX: "hidden", backgroundColor: "white" }} bordered={ false }>
              <thead style={{position: 'sticky', top: '-2px', zIndex: "1"}}>
                <tr>
                  <th style={{ width: "90%", textAlign: "inherit" }}>Nombre</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!loading
                  ? dataUser?.map((el) => (
                      <>
                        {el.youtube_channels.map((i) => (
                          <tr>
                            <td
                              style={{
                                fontSize: "16px",
                                padding: "16px 0 10px 10px",
                              }}
                            >
                              {i.channel_name}
                            </td>
                            <td>
                              <button
                                style={{
                                  border: "1px solid white",
                                  background: "white",
                                }}
                                onClick={(id) => handleRemoveRed(i.id)}
                              >
                                <FaTrash style={{ color: "#F62D54" }} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>
          <Row>
            <Col
              lg={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: "16px" }}>Añadir cuenta mediante .cvs</p>
            </Col>
            <Col lg={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <input type='file' name='photo' id='photo' />
                <label for='photo'>
                    <span>Seleccionar archivo</span>
                </label>
                <Button onClick={uploadFileYT} variant="primary">
                  Importar
                </Button>
                {props.file_error ? <p>No se pudo cargar el archivo</p> : null}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledMedios>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    token: state.token,
    url: state.url,
    user_data: state.user_data,
    new_pass_status: state.new_pass_status,
    new_data_user_success: state.new_data_user_success,
    list_users: state.list_users,
    list_users_post_status: state.list_users_post_status,
    list_users_post_active: state.list_users_post_active,
    code_new_user: state.code_new_user,
    error_invitation: state.error_invitation,
    file_error: state.file_error,
    redes_qsch: state.redes_qsch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFileUpload: (file) => dispatch(getFileUpload(file)),
    errorFile: (status) => dispatch(errorFile(status)),
    getRedesRequest: () => dispatch(getRedesRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedesQsch);
