import styled from "styled-components";
import Container from "react-bootstrap/Container";

export const StyledContainerCard = styled(Container)`
  .scroll-container {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
  }

  ::-webkit-scrollbar {
    width: 5px; /* Ancho del scrollbar */
  }

  /* Estilos del thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb */
    border-radius: 5px; /* Radio de borde del thumb */
  }

  /* Estilos del thumb cuando se está arrastrando */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color del thumb al pasar el mouse sobre él */
  }

  /* Estilos del thumb cuando está activo (haciendo clic) */
  ::-webkit-scrollbar-thumb:active {
    background-color: #333; /* Color del thumb al hacer clic en él */
  }

  /*   background: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 10px; */

  .dates {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    cursor: pointer;
    .fecha {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 13px;
      margin-right: 13px;
      color: #354052;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        color: #c4c4c4;
        width: 15px;
        margin-right: 5px;
      }
    }
    .hora {
      margin-bottom: 0;
      font-size: 12px;
      margin-right: 13px;
      line-height: 13px;
      color: #354052;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        color: #c4c4c4;
        width: 15px;
        margin-right: 5px;
      }
    }
    .relevante-rojo {
      color: red;
      margin-bottom: 0;
      font-size: 12px;
      margin-right: 13px;
      line-height: 13px;
      font-weight: bold;
      svg {
        width: 13px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
    .relevante-rojo:hover {
      margin-top: -2px;
    }
    .no-relevante {
      margin-bottom: 0;
      font-size: 12px;
      margin-right: 13px;
      line-height: 13px;
      color: #354052;

      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
    .no-relevante:hover {
      margin-top: -2px;
    }
    .id {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      flex-grow: 1;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
    .usuario {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        color: #c4c4c4;
        width: 13px;
        margin-right: 5px;
      }
    }
    .dia {
      margin-bottom: 0;
      margin-right: 13px;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        color: #c4c4c4;
        width: 15px;
        margin-right: 5px;
      }
    }
  }

  .texto {
    font-size: 14px;
    line-height: 18px;
    color: #354052;
    text-decoration: none;
    // cursor: pointer;
    word-break: break-word;
    a {
      color: #354052;
      text-decoration: none;
    }
  }
  .redes {
    margin-top: 15px;
    font-size: 11px;
    line-height: 13px;
    color: #354052;
    .contenedor-redes {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      span {
        display: flex;
        align-items: flex-end;
      }
      .fb {
        fill: #3c5a99;
        font-size: 22px;
      }
      .twitter {
        fill: #04c5f9;
        font-size: 22px;
      }
      .instagram {
        fill: #3c5a99;
        font-size: 22px;
      }
      .telegram {
        fill: #32ade2;
        font-size: 22px;
      }
      .whatsapp {
        fill: #25d366;
        font-size: 22px;
      }
      .manual {
        fill: black;
        font-size: 22px;
      }
      .yt {
        fill: red;
        font-size: 22px;
      }
      svg {
        color: #c4c4c4 !important;
        font-size: 15px;
        margin-right: 2px;
        margin-left: 5px;
      }
      .trendig {
        color: white;
        padding: 2px 8px;
        background: #f72d54;
        border-radius: 3px;
        margin-left: 15px;
        svg {
          margin: 0px;
          fill: white;
          padding-right: 1px;
        }
      }
      .tag {
        padding: 2px 18px;
        margin-left: 11px;
        font-size: 10px;
        line-height: 11px;
        color: #354052;
        background: #fafafa;
        border: 1px solid #c4c4c4;
        border-radius: 3px;
      }
      .mas-info {
        font-size: 12px;
        line-height: 11px;
        text-decoration-line: underline;
        margin-right: 16px;
        color: rgba(53, 64, 82, 0.6);
        cursor: pointer;
      }
      .ver-reg {
        a {
          font-size: 10px;
          line-height: 11px;
          margin-right: 16px;
          text-decoration-line: underline;
          color: rgba(53, 64, 82, 0.6);
          &:hover {
            text-decoration-line: underline;
            color: rgba(53, 64, 82, 0.6);
          }
        }
      }

      .btn-no-reg {
        background: #354052;
        border-radius: 17.5px;
        transition: background 0.3s, color 0.3s;
        color: white;
        border: 2px solid;
        padding: 8px 20px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 12px;
        font-size: 12px;
        &:hover {
          background: transparent;
          color: #354052;
        }
      }
      .btn-reg {
        background: #73bd82;
        border-radius: 17px 3px 3px 17px;
        border: 2px solid;
        transition: background 0.3s, color 0.3s;
        color: white;
        padding: 7px 20px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 12px;
        font-size: 12px;
        svg {
          fill: #fff;
          font-size: 12px;
          font-weight: bold;
          margin-left: 0;
          margin-right: 5px;
        }
        a {
          color: white;
          text-decoration: none;
        }
        &:hover {
          border: 2px solid #73bd82;
          background: transparent;
          color: #73bd82;
        }
      }
      .btn-reg-more {
        border: 2px solid #354052;
        background: #354052;
        border-radius: 3px 17px 17px 3px;
        padding: 5px 6px 4px 4px;  
        transition: background 0.3s, color 0.3s;

        &:hover {
          background: transparent;

          svg {
            color: #354052 !important;
          }
        }

        svg {
          color: white !important;
          margin: 0px 4px;
          padding: 0px;
          font-size: 14px;
        }
      }
    }
  }
  .links {
    font-size: 10px;
    line-height: 11px;
    text-decoration-line: underline;
    color: rgba(53, 64, 82, 0.4);
    display: flex;
    justify-content: left;
    p {
      margin-bottom: 0;
      margin-left: 10px;
      &:first-child {
        margin-left: 0px;
      }
      svg {
        font-size: 10px;
        margin-right: 2px;
      }
    }
  }
  .footer-card {
    margin-top: 20px;
    p {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 13px;
      color: #354052;
      font-weight: bold;
    }
    hr {
      margin: 4px 0;
      margin-bottom: 13px;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        border: 0.5px solid #c4c4c4;
        border-radius: 4px;
        font-size: 8px;
        line-height: 9px;
        color: #364151;
        padding: 4px;
        margin-bottom: 4px;
      }
    }
  }
`;
