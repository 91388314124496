import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { monitoreoToggleForm, monitoreoCreateNewPublication, monitoreoCreateNewPublicationTwitter, getDataCard } from "src/store";
import { Row, Col, Form, Container, Accordion, Button } from "react-bootstrap";
import {
  StyledForm,
  StyledSidePanelBackground,
  StyledSidePanel,
} from "../styled";
import "../style.css";
import { MdClose, MdOutlineUploadFile } from "react-icons/md";
import { List } from "react-virtualized";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import FormErrorMessage from "../FormErrorMessage";

const FormCreatePublication = (props) => {
  const [creatingPublication, setCreatingPublication] = useState(false);
  const { id } = useParams();
  // Form handling
  const [inputs, setInputs] = useState({
    socialMedia: "",
    likes: "",
    shares: "",
    views: "",
  });
  const [formMessages, setFormMessages] = useState({});
  const [hiddenFields, setHiddenFields] = useState({
    likes: false,
    shares: false,
    views: false,
  });

  function handleFormChange(event) {
    var target_name = event.target.name;
    var value = event.target.value;
    setInputs((values) => ({ ...values, [target_name]: value }));
  }

    useEffect(() => {
    // Lógica que utiliza el id
  }, [id]);
/*   function handleSelectChange(selectedOption) {
    const target_value = selectedOption.value;
    setInputs((values) => ({ ...values, socialMedia: target_value }));

    setHiddenFields({
      likes: target_value === "other",
      shares: ["instagram", "youtube", "tiktok", "other"].includes(
        target_value
      ),
      views: ["fbpost", "instagram", "telegram", "other"].includes(
        target_value
      ),
    });
  } */

  // NUEVA VERSION PARA TWEET
  function handleSelectChange(selectedOption) {
    const target_value = selectedOption.value;
    setInputs((values) => ({ ...values, socialMedia: target_value }));

    if (target_value === "tweet") {
      setHiddenFields({
        content: true,
        likes: true,
        shares: true,
        views: true,
        multimediaFile: true,
      });
    } else {
      setHiddenFields({
        content: false,
        likes: target_value === "other",
        shares: ["instagram", "youtube", "tiktok", "other"].includes(
          target_value
        ),
        views: ["fbpost", "instagram", "telegram", "other"].includes(
          target_value
        ),
        multimediaFile: false,
      });
    }
  }


  const availableSources = [
    { label: "Twitter", value: "tweet" },
    { label: "TikTok", value: "tiktok" },
    { label: "Telegram", value: "telegram" },
    { label: "YouTube", value: "youtube" },
    { label: "Facebook", value: "fbpost" },
    { label: "Instagram", value: "instagram" },
    { label: "Otro", value: "other" },
  ];

  // Close this panel
  const closeForm = () => {
    props.toggleForm(false, props.id, 0);
  };

function handleValidation() {
  let errors = {};
  let formIsValid = true;

  // Social media
  if (!inputs.socialMedia || inputs.socialMedia.length == 0) {
    formIsValid = false;
    errors.socialMedia = "Selecciona al menos una red social.";
  }

  // Condiciones adicionales solo si no es "tweet"
  if (inputs.socialMedia !== "tweet") {
    // Title
    if (!inputs.content || inputs.content.length == 0) {
      formIsValid = false;
      errors.content = "El campo Contenido es obligatorio.";
    }

    // URL
    if (!inputs.url || inputs.url.length == 0) {
      formIsValid = false;
      errors.url = "El campo URL es obligatorio.";
    }
  } else {
    // Validación de URL siempre es requerida
    if (!inputs.url || inputs.url.length == 0) {
      formIsValid = false;
      errors.url = "El campo URL es obligatorio.";
    }
  }

  setFormMessages(errors);
  return formIsValid;
}




const handleCardCreation = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      let newPublication;
      let newPublicationTwitter;
      
      if (inputs.socialMedia === "tweet") {
        newPublicationTwitter = {
          platform: inputs.socialMedia,
          external_link: inputs.url,
          cards: [props.asociatedCardId],
        };
        setCreatingPublication(true);
        await props.monitoreoCreateNewPublicationTwitter(newPublicationTwitter);
        setInputs({});
      } else {
        console.log("ACA");
        newPublication = {
          title: "non relevant field",
          text: inputs.content,
          image: inputs.multimediaFile,
          external_link: inputs.url,
          reporter: localStorage.getItem("username"),
          platform: inputs.socialMedia,
          cards: [props.asociatedCardId],
          metrics: {
            likes: inputs.likes || 0,
            shares: inputs.shares || 0,
            views: inputs.views || 0,
          },
          upload_type: "manual",
        };
        setCreatingPublication(true);
        await props.monitoreoCreateNewPublication(newPublication);
        props.getDataCard(id);
        setInputs({});
      }

      
      setTimeout(function () {
        setCreatingPublication(false);
        closeForm();
      }, 500);
    } else {
      console.log("se produjo un error");
    }
  };

/*   const handleCardCreation = async (e) => {
    e.preventDefault();

    if (handleValidation()) {
      let newPublication = {
        text: inputs.content,
        image: inputs.multimediaFile,
        external_link: inputs.url,
        reporter: localStorage.getItem("username"),
        platform: inputs.socialMedia,
        cards: [props.asociatedCardId],
        metrics: {
          likes: inputs.likes || 0,
          shares: inputs.shares || 0,
          views: inputs.views || 0,
        },
        upload_type: "manual",
      };
      setCreatingPublication(true);
      await props.monitoreoCreateNewPublication(newPublication);
      setInputs({});
      setTimeout(function () {
        setCreatingPublication(false);
        closeForm();
      }, 500);
    } else {
      console.log("se produjo un error");
    }
  }; */

  const showForm = () => {
    return props.openForm && props.openFormId == props.id;
  };

  return (
    <StyledSidePanel className={showForm() ? "show-form" : "hide"}>
      <StyledSidePanelBackground
        className={creatingPublication ? "loading" : ""}
      >
        <Row>
          <Col md={10}>
            <h1>
              {props.cardTitle} <MdOutlineUploadFile className="manual" />
            </h1>
            <p className="mandatory-indicator">* Campos obligatorios</p>
            <p> {props.cardDescription} </p>
          </Col>
          <Col md={2}>
            <MdClose className="close-icon" onClick={closeForm} />
          </Col>
          <StyledForm>
            {/* <Form onSubmit={handleCardCreation}>
              <Form.Group controlId="createNewTarjetaForm.ControlInput1">
                <Form.Label>
                  Contenido <span className="mandatory-indicator">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Contenido de la publicación"
                  className="text-field"
                  name="content"
                  value={inputs.content || ""}
                  onChange={handleFormChange}
                />
                <FormErrorMessage message={formMessages.content} />

                <Form.Label>
                  Red social <span className="mandatory-indicator">*</span>
                </Form.Label>

                <Select
                  id="tema-select"
                  className="select-container"
                  classNamePrefix="select-container"
                  placeholder="Seleccioná la red social de donde proviene"
                  options={availableSources}
                  value={availableSources.find(
                    (option) => option.value === inputs.socialMedia
                  )}
                  onChange={handleSelectChange}
                  maxMenuHeight="200px"
                />
                <FormErrorMessage message={formMessages.socialMedia} />
                <Row style={{ marginBottom: "10px" }}>
                  {!hiddenFields.likes && inputs.socialMedia !== "other" && (
                    <Col lg={4}>
                      <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                        Me gusta
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="100"
                        className="text-field"
                        name="likes"
                        value={inputs.likes}
                        onChange={(e) =>
                          setInputs({ ...inputs, likes: e.target.value })
                        }
                      />
                    </Col>
                  )}
                  {!hiddenFields.shares &&
                    !["instagram", "youtube", "tiktok", "other"].includes(
                      inputs.socialMedia
                    ) && (
                      <Col lg={4}>
                        <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                          Veces compartido
                        </p>
                        <Form.Control
                          type="text"
                          placeholder="100"
                          className="text-field"
                          name="shares"
                          value={inputs.shares}
                          onChange={(e) =>
                            setInputs({ ...inputs, shares: e.target.value })
                          }
                        />
                      </Col>
                    )}
                  {!hiddenFields.views &&
                    !["fbpost", "instagram", "telegram", "other"].includes(
                      inputs.socialMedia
                    ) && (
                      <Col lg={4}>
                        <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                          Visualizaciones
                        </p>
                        <Form.Control
                          type="text"
                          placeholder="1000000"
                          className="text-field"
                          name="views"
                          value={inputs.views}
                          onChange={(e) =>
                            setInputs({ ...inputs, views: e.target.value })
                          }
                        />
                      </Col>
                    )}
                </Row>

                <Form.Label>
                  URL <span className="mandatory-indicator">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="URL/link a la publicación"
                  className="text-field"
                  name="url"
                  value={inputs.url || ""}
                  onChange={handleFormChange}
                />
                <FormErrorMessage message={formMessages.url} />

                <Form.Label>Archivo multimedia</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Link al archivo multimedia"
                  className="text-field"
                  name="multimediaFile"
                  value={inputs.multimediaFile || ""}
                  onChange={handleFormChange}
                />
                <p>
                  <small>
                    <i>
                      Suba la imagen o video a Google Drive o similar e inserte
                      el link aquí
                    </i>
                  </small>
                </p>
              </Form.Group>

              <button
                className="primario"
                type="submit"
                style={{ marginLeft: 0 }}
              >
                Cargar nueva publicación
              </button>
            </Form>
 */}
            
             <Form onSubmit={handleCardCreation}>
              <Form.Group controlId="createNewTarjetaForm.ControlInput1">
                {!hiddenFields.content && (
                  <>
                    <Form.Label>
                      Contenido <span className="mandatory-indicator">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Contenido de la publicación"
                      className="text-field"
                      name="content"
                      value={inputs.content || ""}
                      onChange={handleFormChange}
                    />
                    <FormErrorMessage message={formMessages.content} />
                  </>
                )}

                <Form.Label>
                  Red social <span className="mandatory-indicator">*</span>
                </Form.Label>
                <Select
                  id="tema-select"
                  className="select-container"
                  classNamePrefix="select-container"
                  placeholder="Seleccioná la red social de donde proviene"
                  options={availableSources}
                  value={availableSources.find(
                    (option) => option.value === inputs.socialMedia
                  )}
                  onChange={handleSelectChange}
                  maxMenuHeight="200px"
                />
                <FormErrorMessage message={formMessages.socialMedia} />

                <Row style={{ marginBottom: "10px" }}>
                  {!hiddenFields.likes && (
                    <Col lg={4}>
                      <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                        Me gusta
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="100"
                        className="text-field"
                        name="likes"
                        value={inputs.likes}
                        onChange={(e) =>
                          setInputs({ ...inputs, likes: e.target.value })
                        }
                      />
                    </Col>
                  )}
                  {!hiddenFields.shares && (
                    <Col lg={4}>
                      <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                        Veces compartido
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="100"
                        className="text-field"
                        name="shares"
                        value={inputs.shares}
                        onChange={(e) =>
                          setInputs({ ...inputs, shares: e.target.value })
                        }
                      />
                    </Col>
                  )}
                  {!hiddenFields.views && (
                    <Col lg={4}>
                      <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                        Visualizaciones
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="1000000"
                        className="text-field"
                        name="views"
                        value={inputs.views}
                        onChange={(e) =>
                          setInputs({ ...inputs, views: e.target.value })
                        }
                      />
                    </Col>
                  )}
                </Row>

                <Form.Label>
                  URL <span className="mandatory-indicator">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="URL/link a la publicación"
                  className="text-field"
                  name="url"
                  value={inputs.url || ""}
                  onChange={handleFormChange}
                />
                <FormErrorMessage message={formMessages.url} />

                {!hiddenFields.multimediaFile && (
                  <>
                    <Form.Label>Archivo multimedia</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Link al archivo multimedia"
                      className="text-field"
                      name="multimediaFile"
                      value={inputs.multimediaFile || ""}
                      onChange={handleFormChange}
                    />
                    <p>
                      <small>
                        <i>
                          Suba la imagen o video a Google Drive o similar e
                          inserte el link aquí
                        </i>
                      </small>
                    </p>
                  </>
                )}
              </Form.Group>

              <button
                className="primario"
                type="submit"
                style={{ marginLeft: 0 }}
              >
                Cargar nueva publicación
              </button>
            </Form> 
          </StyledForm>
        </Row>
      </StyledSidePanelBackground>
    </StyledSidePanel>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    monitoreo: state.monitoreo,
    openForm: state.monitoreo__current_form_is_open,
    openFormId: state.monitoreo__current_form_id,
    idOfPublicationToSave: state.monitoreo__current_form_publication_id,
    registro_status: state.registro_status,
    asociatedCardId: state.monitoreo__load_publication_card_selected_id,
    cardTitle: ownProps.cardTitle,
    cardDescription: ownProps.cardDescription,
    id: ownProps.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleForm: (openForm, openFormId, publication_id) =>
      dispatch(monitoreoToggleForm(openForm, openFormId, publication_id)),
    monitoreoCreateNewPublication: (newPublication) =>
      dispatch(monitoreoCreateNewPublication(newPublication)),
    monitoreoCreateNewPublicationTwitter: (newPublicationTwitter) =>
      dispatch(monitoreoCreateNewPublicationTwitter(newPublicationTwitter)),
      getDataCard: (id) => dispatch(getDataCard(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormCreatePublication);
