import React, { useEffect } from "react";
import { getUrl } from "../store";
import { connect } from "react-redux";
import { getCodeNewUserFail } from "../store";
import { StyledHome } from "./styled";

import VentaChequeabot from "../components/VentaChequeabot";
function HomeAll(props) {
  useEffect(() => {
    props.getCodeNewUserFail();
  }, [props.code_new_user_status]);
  
  return (
    <StyledHome>
      <VentaChequeabot login={false} />
    </StyledHome>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    token: state.token,
    url: state.url,
    code_new_user_status: state.code_new_user_status,
    expand: state.expand,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUrl: (url) => dispatch(getUrl(url)),
    getCodeNewUserFail: () => dispatch(getCodeNewUserFail()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeAll);
