import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { IoReload } from "react-icons/io5";
import { FaLink } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { BiSpreadsheet } from "react-icons/bi";
import { MdKeyboardArrowDown, MdArrowBackIos } from "react-icons/md";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import stripHtmlPreserveLinks from "./HTMLStripper";
import "./index.css";
import Logo from "src/components/Chequeador/Logo";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { FaPaperclip } from "react-icons/fa";
import styled from "styled-components";

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
`;

const InputContainer = styled.div`
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;

  svg {
    color: #7f7f7f;
  }
`;
const SocialNetworkInput = styled.input`
  width: 100% !important;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

const StyledInput = styled.input`
  display: none;
`;

const UploadButton = styled.button`
  border: 1px solid #354052;
  color: #354052;
  background: 0 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding: 0px 33px !important;
  border-radius: 3px;
  &:hover {
    background-color: #354052;
    border: 1px solid #354052;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
`;

const Placeholder = styled.span`
  color: #c4c4c4;
  margin-left: 10px;
  font-size: 12px;
`;

const PreviewContainer = styled.div`
  width: 90px;
  height: 90px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;


export const MainSectorImageUpload = ({ formData, handleDrop, handleDragOver, handleFileChange }) => {
  const fileInputRef = useRef(null);

  return (
    <InputWrapper>
      <InputContainer
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <FaPaperclip />
        <Placeholder>
          {formData.contentScreenshot
            ? "Imagen seleccionada"
            : "Click para buscar un archivo"}
        </Placeholder>
        <UploadButton onClick={() => fileInputRef.current.click()}>
          Buscar
        </UploadButton>
        <StyledInput
          id="contentScreenshot"
          type="file"
          name="contentScreenshot"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
        />
      </InputContainer>
      {formData.contentScreenshot && (
        <PreviewContainer>
          <PreviewImage src={formData.contentScreenshot} alt="Preview" />
        </PreviewContainer>
      )}
    </InputWrapper>
  );
};


// Componente para la sección de redes sociales
export const SocialNetworkImageUpload = ({ network, networkIndex, handleDrop, handleDragOver, handleFileChange }) => {
  const fileInputRef = useRef(null);

  return (
    <InputWrapper>
      <InputContainer
        width="100%"
        style={{width: "100%", padding:" 0 6px !important"}}
        onDrop={(e) => handleDrop(e, networkIndex)}
        onDragOver={handleDragOver}
      >
        <FaPaperclip />
        <Placeholder>
          {network.screenshot
            ? "Imagen seleccionada"
            : "Click para buscar un archivo"}
        </Placeholder>
        <UploadButton onClick={() => fileInputRef.current.click()}>
          Buscar
        </UploadButton>
        <StyledInput
          id={`networkScreenshot-${networkIndex}`}
          type="file"
          name={`networkScreenshot-${networkIndex}`}
          ref={fileInputRef}
          onChange={(e) => handleFileChange(e, networkIndex)}
          accept="image/*"
        />
      </InputContainer>
      {network.screenshot && (
        <PreviewContainer>
          <PreviewImage src={network.screenshot} alt="Preview" />
        </PreviewContainer>
      )}
    </InputWrapper>
  );
};

const ChequeadorForm = () => {
  const [formData, setFormData] = useState({
    contentTypes: [],
    contentDescription: "",
    contentScreenshot: "",
    socialNetworks: [{ name: "", metrics: "", screenshot: "" }],
    toolsUsed: [],
    context: "",
    rating: "",
    ratingJustification: "",
    draftTitle: "",
    draftIntroduction: "",
    development: "",
    summary: "",
  });
  const history = useHistory();
  const [showGeneratedNote, setShowGeneratedNote] = useState(false);
  const fileInputRefs = useRef({});

  const pairNetworks = (networks) => {
    const pairs = [];
    for (let i = 0; i < networks.length; i += 2) {
      pairs.push(networks.slice(i, i + 2));
    }
    return pairs;
  };

  const networkPairs = pairNetworks(formData.socialNetworks);

  const returnToHome = () => {
    history.push("/chequeador");
  };


  // Definición de las herramientas
  const toolPlaceholderText =
    "Resultados de usar la herramienta (agregar hipervínculos en el texto a los resultados de la herramienta). En caso de no haber resutlados también podés comunicarlo.";
  const tools = [
    {
      id: "googleKeywords",
      name: "Búsqueda por palabras",
      description: "Permite buscar información usando palabras claves.",
      placeholder: toolPlaceholderText,
      category: "general",
      examples: [
        { name: "Google", link: "https://google.com" },
        { name: "Bing", link: "https://bing.com" },
      ],
    },
    {
      id: "images",
      name: "Herramientas para imágenes",
      description: "Permite buscar coincidencias visuales en la  web.",
      placeholder: toolPlaceholderText,
      category: "image",
      examples: [
        { name: "Google Imágenes", link: "https://images.google.com/" },
        { name: "TinEye", link: "https://tineye.com/" },
      ],
    },
    {
      id: "videos",
      name: "Herramientas para videos",
      description:
        "Permite generar fotogramas de vídeos para facilitar la búsqueda.",
      placeholder: toolPlaceholderText,
      category: "image",
      examples: [
        {
          name: "InVID",
          link: "https://chromewebstore.google.com/detail/fake-news-debunker-by-inv/mhccpoafgdgbhnjfhkcmgknndkeenfhe",
        },
        {
          name: "YouTube Data Viewer",
          link: "https://ytlarge.com/youtube/video-data-viewer/",
        },
      ],
    },
    {
      id: "ia",
      name: "Herramientas para IA",
      description: "Permite detectar contenido generado con IA.",
      placeholder: toolPlaceholderText,
      category: "video",
      examples: [
        {
          name: "Hive Moderation (texto, imagen, video audio)",
          link: "https://hivemoderation.com/ai-generated-content-detection",
        },
        { name: "Deepware (video)", link: "https://scanner.deepware.ai/" },
        { name: "VerificAudio (audio)", link: "https://verificaudio.ai/" },
      ],
    },
    {
      id: "fact-check-explorer",
      name: "Fact Check Explorer",
      description:
        "Permite encontrar verificaciones sobre temas ya investigados por organizaciones de fact checking.",
      placeholder: toolPlaceholderText,
      category: "audio",
      examples: [
        {
          name: "Fact Check Explorer",
          link: "https://toolbox.google.com/factcheck/explorer/search/list:recent;hl=es",
        },
      ],
    },
    {
      id: "other-tools",
      name: "Otras herramientas",
      description: "Más recursos, apps y programas.",
      placeholder: toolPlaceholderText,
      category: "all",
      examples: [
        {
          name: "Caja de Herramientas",
          link: "https://chequeado.com/herramientasparaperiodistas/index.html#/",
        },
      ],
    },
    {
      id: "contactSources",
      name: "Consultar fuentes",
      description: "Verificar el contenido con fuentes y/o documentos.",
      placeholder:
        "¿Qué fuentes consultaste y que información obtuviste? Hipervíncula todo contenido relevante (perfiles, documentos, etc.).",
      category: "general",
      examples: [],
    },
  ];

  /* useEffect(() => {
    const savedData = localStorage.getItem("verificationData");
    if (savedData) {
      try {
        setFormData(JSON.parse(savedData));
      } catch (error) {
        console.error("Error parsing saved data:", error);
      }
    }
  }, []);

  useEffect(() => {
    try {
      localStorage.setItem("verificationData", JSON.stringify(formData));
    } catch (error) {
      console.error("Error saving data to localStorage:", error);
    }
  }, [formData]); */

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSocialNetworkChange = (index, field, value) => {
    const updatedNetworks = [...formData.socialNetworks];
    updatedNetworks[index] = { ...updatedNetworks[index], [field]: value };
    setFormData((prevData) => ({
      ...prevData,
      socialNetworks: updatedNetworks,
    }));
  };

  const addSocialNetwork = () => {
    setFormData((prevData) => ({
      ...prevData,
      socialNetworks: [
        ...prevData.socialNetworks,
        { name: "", metrics: "", screenshot: "" },
      ],
    }));
  };

  const removeSocialNetwork = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      socialNetworks: prevData.socialNetworks.filter((_, i) => i !== index),
    }));
  };

  const handleToolUse = (toolId, isUsed) => {
    if (isUsed) {
      setFormData((prevData) => ({
        ...prevData,
        toolsUsed: [...prevData.toolsUsed, { id: toolId, conclusion: "" }],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        toolsUsed: prevData.toolsUsed.filter((tool) => tool.id !== toolId),
      }));
    }
  };

  const handleToolConclusion = (conclusion, toolId) => {
    setFormData((prevData) => ({
      ...prevData,
      toolsUsed: prevData.toolsUsed.map((tool) =>
        tool.id === toolId ? { ...tool, conclusion } : tool
      ),
    }));
  };

  const handleContentTypeChange = (type) => {
    setFormData((prevData) => {
      const updatedTypes = prevData.contentTypes.includes(type)
        ? prevData.contentTypes.filter((t) => t !== type)
        : [...prevData.contentTypes, type];
      return { ...prevData, contentTypes: updatedTypes };
    });
  };

  const generateToolsSummary = () => {
    const selectedTools = formData.toolsUsed.filter(
      (tool) => tool.conclusion.trim() !== "" && tool.id !== "contactSources"
    );

    if (selectedTools.length === 0) {
      return "No se utilizaron herramientas en esta verificación.";
    }

    const summaries = selectedTools.map((selectedTool) => {
      return `${selectedTool.conclusion}`;
    });

    let summaryText = "En el proceso de verificación, \n\n";
    summaryText += summaries.join("\n\n");

    return summaryText;
  };

  function generateSourcesSummary() {
    const tools = formData.toolsUsed.filter(
      (tool) => tool.conclusion.trim() !== "" && tool.id === "contactSources"
    );

    if (tools.length > 0) {
      return tools[0].conclusion;
    }

    return "";
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prevState => ({
          ...prevState,
          contentScreenshot: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSocialNetworkFileChange = (event, networkIndex) => {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prevState => {
          const updatedNetworks = [...prevState.socialNetworks];
          updatedNetworks[networkIndex] = {
            ...updatedNetworks[networkIndex],
            screenshot: reader.result
          };
          return {
            ...prevState,
            socialNetworks: updatedNetworks
          };
        });
      };
      reader.readAsDataURL(file);
    }
  };


  const resetForm = () => {
    setFormData({
      contentTypes: [],
      contentDescription: "",
      contentScreenshot: "",
      socialNetworks: [{ name: "", metrics: "", screenshot: "" }],
      toolsUsed: [],
      context: "",
      rating: "",
      ratingJustification: "",
      draftTitle: "",
      draftIntroduction: "",
      development: "",
      summary: "",
    });
    setShowGeneratedNote(false);
    localStorage.removeItem("verificationData");
  };



  const handleDrop = (event, networkIndex) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      const reader = new FileReader();
      reader.onloadend = () => {
        handleSocialNetworkChange(networkIndex, "screenshot", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const generateNote = () => {
    const draftTitle = formData.rating ? `Es ${formData.rating} que...` : "";
    const draftIntroduction = `Circula en redes sociales ${
      formData.contentDescription
        ? formData.contentDescription
        : " [una imagen/video/audio que...]"
    } (${formData.contentScreenshot}). El contenido circulo por ${
      formData.socialNetworks
        ? formData.socialNetworks.map((network) => `${network.name}`).join(", ")
        : "[redes]"
    }. Esto es ${formData.rating ? formData.rating : "[calificación]"}: ${
      formData.ratingJustification
    }.`;
    const draftCirculation = `El contenido tuvo ${formData.socialNetworks
      .map((network) => `${network.metrics} en ${network.name}`)
      .join(", ")}.`;
    const draftToolsSummary = generateToolsSummary();
    const draftSourcesSummary = generateSourcesSummary();
    const draftContext = formData.context;
    const draftCalification = `Por todo esto, concluimos que este contenido es ${
      formData.rating ? formData.rating : "[calificación]"
    }, ya que ${formData.ratingJustification}.`;

    setFormData((prevData) => ({
      ...prevData,
      draftTitle,
      draftIntroduction,
      draftCirculation,
      draftToolsSummary,
      draftSourcesSummary,
      draftContext,
      draftCalification,
    }));

    setShowGeneratedNote(true);
  };

  /* Configuration for advance text editor (React Quill) */
  const handleEditorChange = (content, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: content,
    }));
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
  };

  const modulesMinimal = {
    toolbar: [["bold", "italic", "underline", "strike"], ["link"]],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const formatsMinimal = ["bold", "italic", "underline", "strike", "link"];

  /* Configuration to Copy to clipboard */
  /*   const copyNoteToClipboard = () => {
    const noteContent = `
${formData.draftTitle}

${formData.draftIntroduction}

${formData.draftCirculation}

${formData.draftToolsSummary}

${formData.draftSourcesSummary}

${formData.draftContext}

${formData.draftCalification}
        `.trim();

    const clipboardItem = new ClipboardItem({
      ["text/html"]: new Blob(
        [noteContent],
        { type: "text/html" }
      ),
      ["text/plain"]: new Blob(
        [stripHtmlPreserveLinks(noteContent)],
        { type: "text/plain" }
      )
    });

    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        alert("El borrador ha sido copiado.");
      })
      .catch((err) => {
        console.error("Error al copiar el borrador: ", err);
        alert(
          "Hubo un error al copiar el borrador. Por favor, inténtalo de nuevo."
        );
      });
  }; */
  console.log("formData", formData);
  return (
    <>
      <div className="container-fluid ">
        <div className="row container-title">
          <div className="col-lg-12">
            <div className="titulo">
              <Logo />
              <p>Chequeador</p>
            </div>
          </div>
        </div>

        <div className="container-fluid" style={{ margin: "0" }}>
          <header className="app-header">
            <div className="row">
              <Col xs={12} sm={12} md={7} lg={7}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h1 onClick={() => returnToHome()}>
                    <MdArrowBackIos
                      
                      style={{ cursor: "pointer" }}
                    />
                    Todas las verificaciones
                  </h1>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <button onClick={resetForm} className="button-reset">
                      Guardar
                    </button>
                    <span
                      style={{
                        color: "#7F7F7F",
                        fontSize: "11px",
                        fontStyle: "italic",
                        marginTop: "8px",
                      }}
                    >
                     Guardado automático cada 1 minuto.
                    </span>
                  </div>
                </div>
              </Col>
            </div>
          </header>

          <main className="app-main">
            <section className="new two-column-section row">
              <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                <h2>¿Qué vas a verificar?</h2>
                <div className="card" style={{paddingLeft: "0"}}>
                  <fieldset className="content-type-selection">
                    <legend>
                      Seleccioná el tipo de formato{" "}
                      <span>Seleccionar todos los que correspondan.</span>
                    </legend>
                    {["Texto", "Audio", "Imagen", "Video"].map((type) => (
                      <label key={type} className="checkbox-label">
                        <input
                          type="checkbox"
                          checked={formData.contentTypes.includes(type)}
                          onChange={() => handleContentTypeChange(type)}
                        />
                        <span className="checkbox-custom"></span>
                        {type}
                      </label>
                    ))}
                  </fieldset>
                  <label htmlFor="contentDescription">
                    Descripción del contenido
                  </label>
                  <textarea
                    id="contentDescription"
                    name="contentDescription"
                    value={formData.contentDescription}
                    onChange={handleInputChange}
                    placeholder="Proporcioná una justificación..."
                    className="textarea-input"
                  />
                  <label htmlFor="contentScreenshot">Captura de pantalla</label>
                  <MainSectorImageUpload
                    formData={formData}
                    handleDrop={handleDrop}
                    handleDragOver={handleDragOver}
                    handleFileChange={handleFileChange}
                  />
                  
                </div>
              </div>

              <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                <div className="tips-card">
              <h3>Recomendaciones</h3>
              <ul>
                <li>
                  Seleccioná contenidos fácticos que puedan ser contrastados.
                </li>
                <li>
                  Priorizá contenidos basándote en:
                  <ul>
                    <li>La relevancia del tema aludido.</li>
                    <li>
                      La gravedad de las consecuencias potenciales de la
                      desinformación.
                    </li>
                    <li>
                      Su circulación y viralización en redes sociales u otros
                      medios.
                    </li>
                  </ul>
                </li>
                <li>
                  Describí el contenido.
                  <ul>
                    <li>
                      Si es una imagen o video, describí detalladamente lo que
                      se ve.
                    </li>
                    <li>
                      Incluí detalles como fechas, lugares o personas
                      mencionadas, si están disponibles.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

                <div className="warning-card-container">
                  <div className="warning-card">
                    <h3>¡Atención!</h3>
                    <ul>
                      <li>
                        No compartas el link del posteo original. Hacerlo puede
                        dar mayor visibilidad al contenido potencialmente
                        desinformante.
                      </li>
                      <li>
                        Guardá una captura del contenido para resguardarte en
                        caso de que se borre o edite el contenido original.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section className="-new two-third-column-section row">
              <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                <h2>¿Dónde está circulando el contenido ahora?</h2>
                  <div className="row examples-section">
                  <div className="col-7">
                  <p>
                   Ingresá los datos de las redes sociales donde el contenido tuvo la mayor cantidad de interacciones. <br /><br />El resto de las redes donde circuló con menor viralidad los podés agregar más adelante en el borrador de la nota.
                  </p>
                  </div>
                  
                </div>
                <Container>
                  {networkPairs.map((pair, pairIndex) => (
                    <Row key={pairIndex} className="mb-3">
                      {pair.map((network, index) => {
                        const networkIndex = pairIndex * 2 + index;
                        return (
                          <Col md={6} key={networkIndex} style={{paddingLeft: "0"}}>
                            <div className="card" style={{background: "white"}}>
                              <div className="social-network-header d-flex justify-content-between align-items-center" style={{marginBottom: "6px"}}>
                                <h4>Red social {networkIndex + 1}</h4>
                                <Button
                                  variant="link"
                                  onClick={() =>
                                    removeSocialNetwork(networkIndex)
                                  }
                                  className="p-0"
                                  aria-label="Eliminar red social"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    width="24"
                                    height="24"
                                  >
                                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                  </svg>
                                </Button>
                              </div>
                              <div className="social-network-fields">
                                <div>
                                  <label
                                    htmlFor={`networkName-${networkIndex}`}
                                    className="form-label"
                                  >
                                    Red social
                                  </label>
                                  <SocialNetworkInput
                                    id={`networkName-${networkIndex}`}
                                    type="text"
             
                                    value={network.name}
                                    onChange={(e) =>
                                      handleSocialNetworkChange(
                                        networkIndex,
                                        "name",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Ingresá una red social (ej: Facebook)"
                                    className="form-control"
                                  />
                                </div>
                                <div>
                                  <label
                                    htmlFor={`networkMetrics-${networkIndex}`}
                                    className="form-label"
                                  >
                                    Métricas
                                  </label>
                                  <input
                                    id={`networkMetrics-${networkIndex}`}
                                    type="text"
                                    value={network.metrics}
                                    onChange={(e) =>
                                      handleSocialNetworkChange(
                                        networkIndex,
                                        "metrics",
                                        e.target.value
                                      )
                                    }
                                    placeholder='Ingresá las métricas más representativas de la red (ej: 600 mil reproducciones y 17 mil "Me gusta")'
                                    className="form-control"
                                  />
                                </div>
                                <div>
                                  <label htmlFor={`networkScreenshot-${index}`}>Captura de pantalla</label>
                                    <SocialNetworkImageUpload
                                      network={network}
                                      networkIndex={index}
                                      handleDrop={handleDrop}
                                      handleDragOver={handleDragOver}
                                      handleFileChange={(e) => handleSocialNetworkFileChange(e, index)}
                                    />
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  ))}
                  <Row className="mt-3">
                    <Col>
                      <Button
                        onClick={addSocialNetwork}
                        variant="secondary"
                        className="agregar-red"
                      >
                        Agregar red social
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                <div className="tips-card">
                  <h3>Recomendaciones</h3>
                  <ul>
                    <li>
                      Monitoreá y registrá la circulación en diversas
                      plataformas:
                    </li>
                    <ul>
                      <li>Redes sociales (Facebook, X, Instagram, etc.).</li>
                      <li>
                        Aplicaciones de mensajería (WhatsApp, Telegram, etc.).
                      </li>
                      <li>Medios de comunicación tradicionales y digitales.</li>
                    </ul>
                    <li>
                      Proporcioná datos cuantitativos cuando estén disponibles:
                    </li>
                    <ul>
                      <li>Número de compartidos.</li>
                      <li>Likes o reacciones.</li>
                      <li>Comentarios.</li>
                      <li>Visualizaciones o reproducciones.</li>
                    </ul>
                    <li>
                      Indicá la fecha en que empezó a circular el contenido, si
                      es posible.
                    </li>
                    <li>
                      Mencioná si figuras públicas o medios de comunicación han
                      compartido el contenido.
                    </li>
                    <li>
                      Guardá capturas de pantalla de la circulación en cada
                      plataforma para documentación.
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="new two-third-column-section row">
              <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                <h2>¿Cómo vas a investigar el contenido?</h2>
                <div className="row examples-section">
                  <div className="col-7">
<p>
                    Utilizá todas las herramientas que necesites para encontrar
                    el contenido original u otra información que te permita
                    avanzar con el proceso de verificación.
                  </p>
                  </div>
                  
                </div>
                <div className="tools-grid">
                  {tools.map((tool) => (
                    <div key={tool.id} className="tool-card">
                      <label
                        className="checkbox-label"
                        style={{ fontSize: "16px", fontWeight: "bold" }}
                      >
                        <input
                          type="checkbox"
                          checked={formData.toolsUsed.some(
                            (t) => t.id === tool.id
                          )}
                          onChange={(e) =>
                            handleToolUse(tool.id, e.target.checked)
                          }
                        />
                        <span
                          className="checkbox-custom"
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        ></span>
                        {tool.name}
                      </label>
                      <p>{tool.description}</p>
                      {tool.examples && tool.examples.length > 0 && (
                        <div className="tool-examples">
                          <h4>Herramientas recomendadas</h4>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {tool.examples.map((example, index) => (
                              <>
                                <div key={index}>
                                  <FaLink size={14} />{" "}
                                  <a
                                    className="tool-examples-link"
                                    href={example.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {example.name}
                                  </a>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      )}

                      {formData.toolsUsed.some((t) => t.id === tool.id) && (
                        <ReactQuill
                          theme="snow"
                          value={
                            formData.toolsUsed.find((t) => t.id === tool.id)
                              ?.conclusion || ""
                          }
                          onChange={(content) =>
                            handleToolConclusion(content, tool.id)
                          }
                          modules={modulesMinimal}
                          formats={formatsMinimal}
                          placeholder={tool.placeholder}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="tips-column hidde-mobile  col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                <div className="tips-card ">
                  <h3>Recomendaciones</h3>
                  <ul>
                    <li>Para imágenes y videos:</li>
                    <ul>
                      <li>
                        Utilizá herramientas de búsqueda inversa de imágenes.
                      </li>
                      <li>
                        Verificá metadatos (fecha, hora y lugar de creación).
                      </li>
                      <li>
                        Utilizá herramientas para detectar generaciones con IA.
                      </li>
                    </ul>
                    <li>Para audios:</li>
                    <ul>
                      <li>Contactá con las fuentes o personas mencionadas.</li>
                      <li>
                        Utilizá herramientas para detectar generación con IA.
                      </li>
                    </ul>
                    <li>
                      Intentá contactar a la fuente original del contenido para
                      conocer su fundamento.
                    </li>
                    <li>
                      Si es posible, contactá a los actores involucrados o
                      afectados por la desinformación.
                    </li>
                    <li>Consultá fuentes alternativas como:</li>
                    <ul>
                      <li>Informes y análisis de expertos.</li>
                      <li>
                        Recopilaciones estadísticas de fuentes confiables.
                      </li>
                    </ul>
                    <li>
                      Documentá todos tus hallazgos y cita todas las fuentes
                      utilizadas.
                    </li>
                    <li>Incluí enlaces a los documentos y datos originales.</li>
                  </ul>
                </div>
              </div>
              {/* <Accordion
                defaultActiveKey={null}
                className="accordion-hide"
                onSelect={handleAccordionToggleThree}
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="tips-card">
                      {" "}
                      <h3>
                        Recomendaciones{" "}
                        <MdKeyboardArrowDown
                          size={25}
                          style={{
                            transform: isOpenThree
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.3s ease-in-out",
                          }}
                        />
                      </h3>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="tips-card">
                      <ul>
                        <li>Para imágenes y videos:</li>
                        <ul>
                          <li>
                            Utilizá herramientas de búsqueda inversa de
                            imágenes.
                          </li>
                          <li>
                            Verificá metadatos (fecha, hora y lugar de
                            creación).
                          </li>
                          <li>
                            Utilizá herramientas para detectar generaciones con
                            IA.
                          </li>
                        </ul>
                        <li>Para audios:</li>
                        <ul>
                          <li>
                            Contactá con las fuentes o personas mencionadas.
                          </li>
                          <li>
                            Utilizá herramientas para detectar generación con
                            IA.
                          </li>
                        </ul>
                        <li>
                          Intentá contactar a la fuente original del contenido
                          para conocer su fundamento.
                        </li>
                        <li>
                          Si es posible, contactá a los actores involucrados o
                          afectados por la desinformación.
                        </li>
                        <li>Consultá fuentes alternativas como:</li>
                        <ul>
                          <li>Informes y análisis de expertos.</li>
                          <li>
                            Recopilaciones estadísticas de fuentes confiables.
                          </li>
                        </ul>
                        <li>
                          Documentá todos tus hallazgos y cita todas las fuentes
                          utilizadas.
                        </li>
                        <li>
                          Incluí enlaces a los documentos y datos originales.
                        </li>
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}
            </section>

            <section className="new two-column-section row">
              <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                <h2>¿Qué contexto podés aportar sobre este contenido?</h2>
                <div className="card" style={{paddingLeft: "0"}}>
                  <label htmlFor="context" style={{marginBottom: "14px"}}>Contexto</label>
                  <textarea
                    id="context"
                    name="context"
                    value={formData.context}
                    onChange={handleInputChange}
                    placeholder="¿Qué contexto podés aportar sobre este contenido?"
                    className="textarea-input"
                  />
                </div>
              </div>
              <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                <div className="tips-card">
                  <h3>Recomendaciones</h3>
                  <p>
                    Algunas preguntas que pueden ayudar a construir el contexto:
                  </p>
                  <ul>
                    <li>
                      ¿La circulación del contenido se da en el marco de algún
                      evento político, económico o social de relevancia? ¿Cuál?
                      ¿Cómo se relaciona este contenido con ese evento? (Por
                      ejemplo campañas electorales, dictámenes o debate de
                      leyes, atentados, manifestaciones, etc.)
                    </li>
                    <li>
                      ¿El contenido ya circuló en el pasado? ¿Fue desmentido o
                      trabajado por otros chequeadores? ¿Circuló en otros
                      países?
                    </li>
                    <li>
                      ¿Forma parte de una narrativa que incluye otras
                      desinformaciones sobre el mismo tema? ¿Podés proporcionar
                      ejemplos? ¿Qué registros hay de esta narrativa?
                    </li>
                    <li>
                      ¿El contenido está agrupado dentro del marco de una teoría
                      conspirativa? ¿Cuál? ¿Qué fuentes pueden consultarse sobre
                      el tema?
                    </li>
                    <li>
                      ¿El formato de la desinformación es recurrente, como
                      placas de medios con citas falsas o imágenes o videos
                      creados con IA? Podés comentarlo y aportar consejos o
                      recomendaciones para detectarlas.
                    </li>
                  </ul>
                  <p>
                    Además, si el contenido fue elaborado sobre una temática
                    compleja, podés incluir contexto para explicar esa temática.
                  </p>
                </div>
              </div>
              {/* <Accordion
                defaultActiveKey={null}
                className="accordion-hide"
                onSelect={handleAccordionToggleFour}
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="tips-card">
                      {" "}
                      <h3>
                        Recomendaciones{" "}
                        <MdKeyboardArrowDown
                          size={25}
                          style={{
                            transform: isOpenFour
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.3s ease-in-out",
                          }}
                        />
                      </h3>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="tips-card">
                      <p>
                        Algunas preguntas que pueden ayudar a construir el
                        contexto:
                      </p>
                      <ul>
                        <li>
                          ¿La circulación del contenido se da en el marco de
                          algún evento político, económico o social de
                          relevancia? ¿Cuál? ¿Cómo se relaciona este contenido
                          con ese evento? (Por ejemplo campañas electorales,
                          dictámenes o debate de leyes, atentados,
                          manifestaciones, etc.)
                        </li>
                        <li>
                          ¿El contenido ya circuló en el pasado? ¿Fue desmentido
                          o trabajado por otros chequeadores? ¿Circuló en otros
                          países?
                        </li>
                        <li>
                          ¿Forma parte de una narrativa que incluye otras
                          desinformaciones sobre el mismo tema? ¿Podés
                          proporcionar ejemplos? ¿Qué registros hay de esta
                          narrativa?
                        </li>
                        <li>
                          ¿El contenido está agrupado dentro del marco de una
                          teoría conspirativa? ¿Cuál? ¿Qué fuentes pueden
                          consultarse sobre el tema?
                        </li>
                        <li>
                          ¿El formato de la desinformación es recurrente, como
                          placas de medios con citas falsas o imágenes o videos
                          creados con IA? Podés comentarlo y aportar consejos o
                          recomendaciones para detectarlas.
                        </li>
                      </ul>
                      <p>
                        Además, si el contenido fue elaborado sobre una temática
                        compleja, podés incluir contexto para explicar esa
                        temática.
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}
            </section>

            <section className="new two-column-section row">
              <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                <h2 style={{marginBottom: "0px"}}>¿Cuál es tu conclusión?</h2>
                <div className="card"  style={{paddingLeft: "0", paddingTop: "17px"}}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <label htmlFor="rating">Calificación</label>
                    <select
                      id="rating"
                      name="rating"
                      value={formData.rating || ''}
                      onChange={handleInputChange}
                      className="select-input"
                    >
                      <option value="" disabled>Elegí una opción</option>
    <option value="verdadero">Verdadero</option>
    <option value="falso">Falso</option>
    <option value="engañoso">Engañoso</option>
                    </select>
                  </div>

                  <label
                    htmlFor="ratingJustification"
                    style={{ marginTop: "18px" }}
                  >
                    Justificación
                  </label>
                  <textarea
                    id="ratingJustification"
                    name="ratingJustification"
                    value={formData.ratingJustification}
                    onChange={handleInputChange}
                    placeholder="Proporcioná una justificación detallada de la calificación."
                    className="textarea-input-conclusion"
                  />
                </div>
                <section className="verification-step-new nw-section">
                  <div className="form-container">
                    <button
                      onClick={generateNote}
                      className="button-primary nw-generate-button"
                    >
                      <BiSpreadsheet className="icon" /> Generar borrador de
                      nota
                    </button>
                  </div>
                </section>
              </div>
              <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                <div className="tips-card">
                  <h3>Recomendaciones</h3>
                  <ul>
                    <li>Calificaciones:</li>
                    <ul>
                      <li>
                        <strong>Falso:</strong> El contenido completo ha
                        demostrado ser falso al contrastarlo con fuentes
                        confiables.
                      </li>
                      <li>
                        <strong>Engañoso:</strong> Parte del contenido puede ser
                        verdadero, pero ha sido manipulado para generar un
                        mensaje particular.
                      </li>
                      <li>
                        <strong>Verdadero:</strong> El contenido completo ha
                        demostrado ser verdadero al contrastarlo con fuentes
                        confiables.
                      </li>
                      <li>
                        <strong>Insostenible:</strong> La afirmación surge de
                        investigaciones con falta de sustento o errores
                        metodológicos graves, o es imposible de verificar.
                      </li>
                    </ul>
                    <li>
                      Basá tu calificación en la evidencia recopilada y las
                      fuentes consultadas.
                    </li>
                    <li>
                      Justificá tu calificación con hechos concretos y citas de
                      fuentes confiables.
                    </li>
                    <li>
                      Si hay aspectos que no pudiste verificar completamente,
                      mencionalo en tu justificación.
                    </li>
                    <li>
                      Sé transparente sobre el proceso de verificación y las
                      limitaciones encontradas.
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
};

export default ChequeadorForm;
