import React, { useEffect, useState } from "react";

// Bootstrap & icons
import { Col, Row } from "react-bootstrap";
import {
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaTelegramPlane,
} from "react-icons/fa";
import {
  MdFavorite,
  MdRemoveRedEye,
  MdUpdate,
  MdPerson2,
  MdOutlineReply,
  MdAccessTime,
  MdOutlineCalendarToday,
  MdOutlineFacebook,
  MdOutlineUploadFile,
} from "react-icons/md";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { FaPlus } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";

// Redux
import { connect } from "react-redux";
import {
  resetRegistroStatus,
  monitoreoToggleForm,
  getRegistroID,
  postMonitoreoAsociar,
  getRegistroIDdata,
  getDataCard,
} from "src/store";

// Utils
import { numberFormatter } from "src/utils/formatter";
import dayjs from "dayjs";
import "dayjs/locale/es";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.locale("es");

function ContentCard(props) {
  const [showModal, setShowModal] = useState(false);
  const [unlinkingPost, setUnlinkingPost] = useState(false);
  const history = useHistory();
  const guardarTarjeta = () => {
    // Cierro el form para generar transicion si ya estaba abierto
    props.toggleForm(false, "save_publication", -1);
    // Open Save Form
    setTimeout(
      () => props.toggleForm(true, "save_publication", props.el.id),
      50
    );
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = (id) => {
    setShowModal(true);
  };

  const seeDetail = (cardId) => {
    history.push(`/periscopio/${cardId}`);
    setShowModal(false);
  };

  const unLink = async (postId) => {
    var updatedCardsList = props.el.cards?.filter(function (item) {
      return item.id.toString() != props.cardId.toString();
    });
    updatedCardsList = updatedCardsList.map((item) => item.id);
    setUnlinkingPost(true);
    await props.postMonitoreoAsociar({ cards: updatedCardsList }, postId);
  };

  // This triggers after a correct register
  useEffect(() => {
    if (props.registro_status === "success" && props.isSaved) {
      props.resetRegistroStatus();
    }
  }, [props.registro_status]);

  const extractTikTokVideoId = (url) => {
    const match = url.match(/\/video\/(\d+)/);
    return match ? match[1] : null;
  };

  const videoId =
    props.el.upload_type === "manual"
      ? extractTikTokVideoId(props.el.external_link)
      : extractTikTokVideoId(props.el.video);

  return (
    <Row className="white-card" key={props.index}>
      <Col lg={12}>
        <div className="dates">
          <p className="fecha" id={`app-title-${props.index}`}>
            <MdOutlineCalendarToday />
            {dayjs(props.el.time).fromNow()}
          </p>
          <p className="hora">
            <MdAccessTime />
            {dayjs(props.el.time).format("HH:mm")}
          </p>

          {props?.el?.author !== null ? (
            <p className="dia" style={{ fontWeight: "bold" }}>
              <MdPerson2 />
              {props.el.author}
            </p>
          ) : null}

          <p className="id">{props.el.account}</p>
          {props.el.cards?.length > 0 ? (
            <>
              <p className="usuario">
                <MdUpdate />
                {dayjs(props.el.report_time).format("D-MM-YYYY")}
              </p>
              {props.el.reporter != null ? (
                <p className="usuario">
                  <MdUpdate /> {props.el.reporter}
                </p>
              ) : (
                ""
              )}
            </>
          ) : null}
        </div>
      </Col>
      <>
        {props.el.type === "tiktok" &&
        props.el.upload_type === "manual" &&
        props.el.video === "" ? (
          <Col
            lg={5}
            style={{
              borderRadius: "15px",
              backgroundColor: "#e9e9e9",
            }}
          >
            <iframe
              src={`https://www.tiktok.com/embed/${videoId}`}
              className="iframe"
              allowFullScreen
              scrolling="no"
              allow="encrypted-media"
              style={{
                width: "100%",
                height: "500px", // Aspect ratio de 16:9
              }}
            ></iframe>
          </Col>
        ) : props.el.type === "tiktok" &&
          props.el.upload_type === "scrapper" &&
          props.el.video ? (
          <Col
            lg={5}
            style={{
              borderRadius: "15px",
              backgroundColor: "#e9e9e9",
            }}
          >
            <div>
              <iframe
                src={`https://www.tiktok.com/embed/${videoId}`}
                className="iframe"
                allowFullScreen
                scrolling="no"
                allow="encrypted-media"
                style={{
                  width: "100%",
                  height: "500px", // Aspect ratio de 16:9
                }}
              ></iframe>
            </div>
          </Col>
        ) : props.el.video ? (
          <Col
            lg={5}
            style={{
              borderRadius: "15px",
              backgroundColor: "#e9e9e9",
            }}
          >
            {props.el.type === "tweet" ? (
              <iframe
                src={props.el.video}
                width="100%"
                height="500px"
                allowFullScreen
                style={{
                  width: "100%",
                  height: "100%",
                }}
                scrolling="no"
                allow="encrypted-media"
              ></iframe>
            ) : props.el.type === "facebook" ? (
              <ReactPlayer
                style={{ margin: "0 auto", display: "block" }}
                url={props.el.video}
                width="100%"
                height="100%"
                className="react-player"
              />
            ) : (
              <ReactPlayer
                style={{ margin: "0 auto", display: "block" }}
                url={props.el.video}
                width="100%"
                height="100%"
                className="react-player"
              />
            )}
          </Col>
        ) : props.el.image ? (
          <Col
            lg={5}
            style={{
              borderRadius: "15px",
              backgroundColor: "#e9e9e9",
            }}
          >
            <img
              src={props.el.image}
              style={{
                maxWidth: "inherit",
                maxHeight: "250px",
                margin: "0 auto",
                display: "block",
              }}
            />
          </Col>
        ) : null}
        <Col lg={7}>
          <p className="texto">{props.el.text}</p>
        </Col>
      </>

      <Col lg={12} className="redes">
        <div className="contenedor-redes">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "inherit",
              flexGrow: "1",
            }}
          >
            {props.el.upload_type === "manual" && (
              <MdOutlineUploadFile className="manual" />
            )}
            {props.el.type === "fbpost" && (
              <>
                <MdOutlineFacebook className="fb" />
                <span id={`app-title-likes-${props.index}`}>
                  <MdFavorite style={{ fontSize: "15px" }} />{" "}
                  {numberFormatter(props.el.metrics.likes, 2)}
                </span>
                <span id={`app-title-share-${props.index}`}>
                  <MdOutlineReply style={{ fontSize: "15px" }} />{" "}
                  {numberFormatter(props.el.metrics.shares, 2)}
                </span>
              </>
            )}
            {props.el.type === "tweet" && (
              <>
                <FaTwitter className="twitter" />
                <span id={`app-title-likes-${props.index}`}>
                  <MdFavorite style={{ fontSize: "15px" }} />{" "}
                  {numberFormatter(props.el.metrics.likes, 2)}
                </span>
                <span id={`app-title-share-${props.index}`}>
                  <MdOutlineReply style={{ fontSize: "15px" }} />{" "}
                  {numberFormatter(props.el.metrics.shares, 2)}
                </span>
                <span id={`app-title-views-${props.index}`}>
                  <MdRemoveRedEye style={{ fontSize: "15px" }} />{" "}
                  {numberFormatter(props.el.metrics.views, 2)}
                </span>
              </>
            )}
            {props.el.type === "instagram" && (
              <>
                <FaInstagram
                  className="instagram"
                  style={{ fill: "rgb(255, 77, 125)" }}
                />
                <span id={`app-title-likes-${props.index}`}>
                  <MdFavorite style={{ fontSize: "15px" }} />{" "}
                  {numberFormatter(props.el.metrics.likes, 2)}
                </span>
              </>
            )}
            {props.el.type === "youtube" && (
              <>
                <FaYoutube className="yt" />
                <span id={`app-title-likes-${props.index}`}>
                  <MdFavorite style={{ fontSize: "15px" }} />{" "}
                  {numberFormatter(props.el.metrics.likes, 2)}
                </span>
                <span id={`app-title-share-${props.index}`}>
                  <MdRemoveRedEye style={{ fontSize: "15px" }} />{" "}
                  {numberFormatter(props.el.metrics.views, 2)}
                </span>
              </>
            )}
            {props.el.type === "telegram" && (
              <>
                <FaTelegramPlane className="telegram" />
                <span id={`app-title-likes-${props.index}`}>
                  <MdFavorite style={{ fontSize: "15px" }} />{" "}
                  {numberFormatter(props.el.metrics.likes, 2)}
                </span>
                <span id={`app-title-share-${props.index}`}>
                  <MdOutlineReply style={{ fontSize: "15px" }} />{" "}
                  {numberFormatter(props.el.metrics.shares, 2)}
                </span>
              </>
            )}
            {props.el.type === "tiktok" && (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  fill="#000"
                  className="bi bi-tiktok"
                  viewBox="0 0 15 15"
                >
                  {" "}
                  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />{" "}
                </svg>
                <span id={`app-title-likes-${props.index}`}>
                  {/* <FavoriteIcon /> */}{" "}
                  {numberFormatter(props.el.metrics.likes, 2)}
                </span>
                <span id={`app-title-views-${props.index}`}>
                  <MdRemoveRedEye />{" "}
                  {numberFormatter(props.el.metrics.views, 2)}
                </span>
              </>
            )}
            {props.el.type === "manual" && (
              <MdOutlineUploadFile className="manual" />
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span className="mas-info">
              <a
                href={props.el.external_link}
                target="_blank"
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  textDecoration: "none",
                  color: "grey",
                  fontWeight: "500",
                }}
              >
                &#62; Ver posteo
              </a>
            </span>
            <div key={props.el.id}>
              <>
                {props.isSaved ? (
                  <button
                    className="btn-unreg"
                    onClick={() => unLink(props.el.id)}
                  >
                    Desvincular
                  </button>
                ) : props.el.cards.length > 0 ? (
                  <>
                    {props.el.cards.length === 1 ? (
                      <button
                        className="btn-reg"
                        onClick={() =>
                          seeDetail(props.el.cards.map((el) => el.id))
                        }
                      >
                        Guardado
                      </button>
                    ) : (
                      <button
                        className="btn-reg"
                        onClick={() =>
                          handleOpen(props.el.cards.map((card) => card))
                        }
                      >
                        Guardado
                      </button>
                    )}

                    <button
                      style={{
                        border: "#354052",
                        background: "#354052",
                        borderRadius: "0px 12px 12px 0px",
                        padding: "5px",
                      }}
                      onClick={guardarTarjeta}
                    >
                      <FaPlus style={{ border: "blue", fill: "white" }} />
                    </button>

                    {showModal && (
                      <Modal show={showModal} onClose={handleClose}>
                        <Modal.Header>
                          <Modal.Title style={{ width: "inherit" }}>
                            Este contenido esta asociado a multiples tarjetas
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div style={{ textAlign: "left" }}>
                            <p>selecciona la que quieras visitar</p>
                            <ul className="modal-asociados">
                              {props.el.cards.map((el, index) => (
                                <li
                                  key={el.id}
                                  onClick={() => seeDetail(el.id)}
                                >
                                  {el.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            variant="secondary"
                            className="cancelar"
                            onClick={handleClose}
                          >
                            Cancelar
                          </button>
                        </Modal.Footer>
                      </Modal>
                    )}
                  </>
                ) : (
                  <button className="btn-no-reg" onClick={guardarTarjeta}>
                    Guardar
                  </button>
                )}
              </>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    registro_status: state.registro_status,
    el: ownProps.el,
    index: ownProps.index,
    isSaved: ownProps.isSaved,
    cardId: ownProps.cardId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetRegistroStatus: () => dispatch(resetRegistroStatus()),
    toggleForm: (openForm, openFormId, publication_id) =>
      dispatch(monitoreoToggleForm(openForm, openFormId, publication_id)),
    getRegistroID: (id) => dispatch(getRegistroID(id)),
    postMonitoreoAsociar: (cards, publication_id) =>
      dispatch(postMonitoreoAsociar(cards, publication_id)),
    getRegistroIDdata: (id) => dispatch(getRegistroIDdata(id)),
    getDataCard: (id) => dispatch(getDataCard(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentCard);
