import React  from "react";
import Form from "react-bootstrap/Form";
/* Styled */
import { StyledResults, StyledMenuCard } from "./styled";
import { getCardsRequestDefault, getOrder } from "src/store";
import { connect } from "react-redux";
import { BorderBottom } from "@material-ui/icons";

function MenuCard(props) {


  const handleSelect = (e) => {
    const value = e.target.value;
    props.getOrder(value);
    props.getCardsRequestDefault({
      media_source: props.media_source,
      days: props.days,
      order: value,
      page: props.page,
      category: props.category,
      search: props.search,
      speaker: props.search_name,
      title: props.search_title
    });
  };

  return (
    <StyledMenuCard>
      <div
        className="navbar navbar-expand-lg navbar-light bg-light"
        style={{ padding: "24px 0px", borderBottom: "none"}}
      >
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent1"
          aria-controls="navbarSupportedContent1"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent1">
          <ul className="navbar-nav mr-auto">
            {/* <li className="nav-item active">
              <a className="nav-link" href="#">
                Todos <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Audivisuales
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#">
                Radio
              </a>
            </li> */}
          </ul>
          <StyledResults>{props.cards.total} resultados</StyledResults>
          <div key={props.category ? props.category : "all"}>
            <Form>
              <Form.Group
                controlId="exampleForm.SelectCustom"
                style={{ marginBottom: "0px" }}
              >
                <Form.Control
                  as="select"
                  custom
                  onChange={(e) => handleSelect(e)}
                >
                  <option value="-relevance_score">Más relevantes</option>
                  <option value="-date">Más recientes</option>
                  <option value="date">Más antiguos</option>
                  {props.category === "YT" || props.category === "TW" ? (
                    <option value="-viralidad_score">Más virales</option>
                  ) : null}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </StyledMenuCard>
  );
}

const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    days: state.days,
    media_source: state.media_source,
    order: state.order,
    page: state.page,
    search: state.search,
    category: state.category,
    search_name: state.search_name,
    search_title: state.search_title
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCardsRequestDefault: (arg) => dispatch(getCardsRequestDefault(arg)),
    getOrder: (order) => dispatch(getOrder(order)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuCard);
