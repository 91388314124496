import React from "react";

import { LuAlertTriangle } from "react-icons/lu";

function FormErrorMessage({message}) {

  return (
    <div>
        {message ? (
            <p
            style={{
                marginRight: "5px",
                color: "#F72D54",
                fontsize: "12px",
                fontWeight: "bold"
            }}
            >
            <LuAlertTriangle
                style={{
                marginRight: "5px",
                color: "#F72D54",
                fontsize: "14px",
                verticalalign: "text-top",
                }}
            />
            {message}
        </p>
        ) : null}
    </div>
  );
}


export default FormErrorMessage;
