import React from "react";

export default function Logo({ fillColor, backgroundColor }) {
    return (

       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="24" height="24"
            fill={fillColor}>
            <circle cx="100" cy="100" r="100" fill={backgroundColor} />
            <ellipse
                className="cls-1"
                cx="140.49"
                cy="114.16"
                rx="6.7"
                ry="6.77"
            ></ellipse>
           <path className="cls-1" d="M142.49,98.24c2.3,0,4.17-1.87,4.17-4.17v-47.53c0-2.3-1.87-4.17-4.17-4.17s-4.17,1.87-4.17,4.17v4.43h-36.63c-16.96,0-30.75,13.8-30.75,30.75v62.39l-1.29-.38c-5.94-1.77-11.81-4.32-17.47-7.59-2.15-1.24-4.91-.5-6.15,1.65-1.24,2.15-.5,4.91,1.65,6.15,13.67,7.9,28.08,11.9,42.82,11.9,24.96,0,42.51-11.57,43.25-12.06,1-.67,1.68-1.69,1.91-2.87.23-1.18,0-2.38-.68-3.38-1.38-2.06-4.18-2.61-6.24-1.24-.74.49-7.55,4.89-18.47,7.86l-1.26.34v-53.98c0-.73.59-1.32,1.32-1.32h27.98v4.87c0,2.3,1.87,4.17,4.17,4.17ZM110.34,80.2c-5.69,0-10.32,4.63-10.32,10.32v55.69l-.88.1c-2.84.33-5.69.5-8.53.5-3.28,0-6.56-.22-9.8-.66l-.87-.12v-64.31c0-11.99,9.76-21.75,21.75-21.75h36.63v20.22h-27.98Z"/>
        </svg>
    );
}