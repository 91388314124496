import styled from "styled-components";
import Form from "react-bootstrap/Form";

export const StyleWrapperForm = styled.div`
background: #e6ecf5;
padding: 15px;
margin-top: 15px;
.clean-button {
  font-size: 16px;
  width: 100%;
  background: #e6ecf5;
  border: none;
  font-weight: bold;
  color: rgb(53, 64, 82);
}

.clean-button:hover {
  font-size: 16px;
  width: 100%;
  background: #e6ecf5;
  border: none;
  font-weight: bold;
  color: rgb(53, 64, 82);
}

.clean-button:active {
  font-size: 16px;
  width: 100%;
  background: #e6ecf5 !important;
  border: none !important;
  font-weight: bold;
  color: rgb(53, 64, 82) !important;
  outline: 0 !important;

}

.clean-button:focus {
  font-size: 16px;
  width: 100%;
  background: #e6ecf5 !important;
  border: none !important;
  font-weight: bold;
  color: rgb(53, 64, 82) !important;
  outline: 0 !important;

}

`
export const StyledFormSearch = styled(Form)`
.form-control::placeholder {
  font-size: 12px;
}
`

export const StyledForm = styled(Form)`


  label {
    margin-bottom: 0px;
    font-size: 14px;
  }

  select {
    margin-bottom: 15px;
  }

  .filterArea p {
    margin: 0;
    background: white;
    padding: 5px;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: min-content;
    align-items: center;
    margin: 0 10px 5px 0px;
  }

  .filterArea p span {
    margin-left: 10px;
    cursor: pointer;
    color: #ff3370;
  }

`;


