import React from "react";
import { connect } from "react-redux";
import { StyledText } from "./styled";
import { MdCancelScheduleSend } from "react-icons/md";
import { VscSend } from "react-icons/vsc";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

function TablaCampanas(props) {
 console.log("props camapnas", props);

  return (
    <StyledText>
      <Row>
        <Col lg={3}>
          <h1>Listado de campañas</h1>
        </Col>
      </Row>
      <Row className="encabezado">
        <Col lg={4}>
          <p>Título</p>
        </Col>
        <Col lg={1}>
          <p>Fecha</p>
        </Col>
        <Col lg={1}>
          <p>Estado</p>
        </Col>
        <Col lg={1}>
          <p>Usuario</p>
        </Col>
        <Col lg={1}>
          <p>Notificaciones</p>
        </Col>
        <Col lg={1}>
          <p>Clicks</p>
        </Col>
        <Col lg={1}>
          <p>Organización</p>
        </Col>
      </Row>
      {/* <Row className="test" style={{ marginBotton: "15px" }}>
        <>
          <Col lg={1}>
            <img
              src="https://img2.rtve.es/i/?w=1600&i=1447940118890.jpg"
              width={90}
            />
          </Col>
          <Col lg={3}>
            <h3>Texto texto texto texto texto texto texto texto texto</h3>
          </Col>
          <Col lg={1} style={{ textAlign: "center" }}>
            <p className="fecha">dd/mm/aaaa</p>
             <p className="fecha">{dayjs(el.created_at).format("D-MM-YYYY")}</p> 
          </Col>
          <Col lg={1} style={{ textAlign: "center" }}>
            <p className="status-green">
              <MdCancelScheduleSend style={{ fill: "#73BD82" }} />
              Enviado
            </p>
             <p
              className={
                el.status.value == "Sent" ? "status-green" : "status-red"
              }
            >
              {el.status.value}
            </p> 
          </Col>
          <Col lg={1} style={{ textAlign: "center" }}>
            <p className="usuario">User name</p>
          </Col>
          <Col lg={1} style={{ textAlign: "center" }}>
            <p className="notificaciones " style={{ color: "#73BD82" }}>
              <VscSend /> 11111111111
            </p>
          </Col>
          <Col lg={1} style={{ textAlign: "center" }}>
            <p className="click" style={{ color: "#73BD82" }}>
              20 K
            </p>
          </Col>
          <Col lg={2} style={{ textAlign: "left" }}>
            <p className="orgnaizacion">Nombre de la organización</p>
          </Col>
        </>
      </Row> */}
      {props?.data
        ?.sort((a, b) => (a.date >= b.date ? 1 : -1))
        .map((el) => (
          <Row className="test" style={{ marginBotton: "15px" }}>
            <>
              <Col lg={1}>
                <img src={el.image_url} width={90} alt="Preview of notification"/>
              </Col>
              <Col lg={3}>
                <h3>{el.body}</h3>
              </Col>
              <Col lg={1} style={{ textAlign: "center" }}>
                <p className="fecha">
                  {dayjs(el.created_at).format("D/MM/YYYY")}
                </p>
              </Col>
              <Col lg={1} style={{ textAlign: "center" }}>
                {el.status.value === "Sending" && (
                  <p
                    style={{
                      color: "orange",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <VscSend style={{ fill: "orange", marginRight: "5px" }} />
                    Enviando
                  </p>
                )}
                {el.status.value === "Sent" && (
                  <p
                    style={{
                      color: "green",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <VscSend style={{ fill: "#73BD82", marginRight: "5px" }} />
                    Enviado{" "}
                  </p>
                )}
                {el.status.value === "Error" && (
                  <p
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MdCancelScheduleSend
                      style={{ fill: "red", marginRight: "5px" }}
                    />
                    Error{" "}
                  </p>
                )}
              </Col>
              <Col lg={1} style={{ textAlign: "center" }}>
                <p className="usuario">{el.user.username}</p>
              </Col>
              <Col lg={1} style={{ textAlign: "center" }}>
                <p className="notificaciones" style={{ color: "#73BD82" }}>
                  {el.total_of_tokens}
                </p>
              </Col>
              <Col lg={1} style={{ textAlign: "center" }}>
                <p className="click" style={{ color: "#73BD82" }}>
                  {el.total_clicks
}
                </p>
              </Col>
              <Col lg={2} style={{ textAlign: "left" }}>
                <p className="orgnaizacion">{el.app.domain}</p>
              </Col>
            </>
          </Row>
        ))}
    </StyledText>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaCampanas);
