import React, { useState, useEffect } from "react";
import { getTextos } from "../../store";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getFirstPass } from "../../store";
import { useHistory } from "react-router-dom";
import { FormNewCode } from "../styled";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function CreatePassword(props) {
  const [analisis, setanalisis] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  const [queryFirstPass, setFirstPass] = useState("");

  useEffect(() => {
    if (props.first_password_status === "success") {
      history.push("/login");
    } else {
      if (props.first_password_status === "error") {
        setError(true);
      }
    }
  }, [props.first_password_status]);

  const handleSubmitData = (e) => {
    e.preventDefault();
    props.getFirstPass({
      password: queryFirstPass,
      confirm_password: queryFirstPass,
    });
  };

  const handlePasswordFisrtTime = (e) => {
    e.preventDefault();
    setFirstPass(e.target.value);
  };

  return (
    <FormNewCode>
      <Form onSubmit={handleSubmitData} style={{ marginBottom: "25px" }}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Ingrese nueva contrasena:</Form.Label>
          <div>
            <Form.Control
              type="password"
              name="pass"
              value={queryFirstPass}
              style={{ width: "70%" }}
              required
              minLength="8"
              onChange={handlePasswordFisrtTime}
            />
          </div>
        </Form.Group>
        {error ? (
          <Row>
            <Col lg={12}>
              <p
                style={{
                  padding: "26px 5px",
                  color: "red",
                  borderRadius: "5px",
                }}
              >
                La contraseña es demasiado corta. Debe contener por lo menos 8
                caracteres.
              </p>
            </Col>
          </Row>
        ) : null}
        <button variant="primary" type="submit" className="btn-guardar">
          Guardar
        </button>
      </Form>
    </FormNewCode>
  );
}

const mapStateToProps = (state) => {
  return {
    textos: state.textos,
    code_new_user: state.code_new_user,
    first_password_status: state.first_password_status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFirstPass: (data) => dispatch(getFirstPass(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
