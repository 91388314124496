import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import TablaCampanas from "src/components/Push/TablaCampanas";
import "../style.css";


function Historial(props) {



  return (
    <>
      {props.campanas.length > 0 ? (
        <TablaCampanas data={props.campanas} />
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
     campanas: state.campanas
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Historial);
