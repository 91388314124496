import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { StyledBackground } from "./styled";
import robot_solo from "src/assets/img/chequeabot.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { connect } from "react-redux";

function VentaChequeabot(props) {
  const svgStyle = {
    fill: "#fff",
    strokeWidth: "0px",
  };

  return (
    <StyledBackground $login={props.login}>
      <Row>
        <Col lg={12}>
          <div className="contenedor_presentacion mx-auto">
            <img src={robot_solo} alt="logo" />
            <h1 className="pl-3">
              Bienvenido a<br /> Chequeabot
            </h1>
          </div>
        </Col>
        <Row>
          <Col lg={6} className="descripcion">
            <div>
              <svg
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 200 200"
                width="28" // Establece el ancho a 24
                height="28" // Establece la altura a 24
              >
                <path
                  style={svgStyle}
                  d="m127.86,46.93h20.24c2.93,0,5.3,2.38,5.3,5.3v20.24c0,2.53,2.05,4.58,4.58,4.58h0c2.53,0,4.58-2.05,4.58-4.58v-20.24c0-7.99-6.47-14.46-14.46-14.46h-20.24c-2.53,0-4.58,2.05-4.58,4.58h0c0,2.53,2.05,4.58,4.58,4.58Z"
                />
                <path
                  style={svgStyle}
                  d="m46.6,72.47v-20.24c0-2.93,2.38-5.3,5.3-5.3h20.24c2.53,0,4.58-2.05,4.58-4.58h0c0-2.53-2.05-4.58-4.58-4.58h-20.24c-7.99,0-14.46,6.47-14.46,14.46v20.24c0,2.53,2.05,4.58,4.58,4.58h0c2.53,0,4.58-2.05,4.58-4.58Z"
                />
                <path
                  style={svgStyle}
                  d="m72.14,153.73h-20.24c-2.93,0-5.3-2.38-5.3-5.3v-20.24c0-2.53-2.05-4.58-4.58-4.58h0c-2.53,0-4.58,2.05-4.58,4.58v20.24c0,7.99,6.47,14.46,14.46,14.46h20.24c2.53,0,4.58-2.05,4.58-4.58h0c0-2.53-2.05-4.58-4.58-4.58Z"
                />
                <path
                  style={svgStyle}
                  d="m153.4,128.18v20.24c0,2.93-2.38,5.3-5.3,5.3h-20.24c-2.53,0-4.58,2.05-4.58,4.58h0c0,2.53,2.05,4.58,4.58,4.58h20.24c7.99,0,14.46-6.47,14.46-14.46v-20.24c0-2.53-2.05-4.58-4.58-4.58h0c-2.53,0-4.58,2.05-4.58,4.58Z"
                />
                <rect
                  style={svgStyle}
                  x="62.99"
                  y="76.64"
                  width="74.02"
                  height="9.48"
                  rx="4.58"
                  ry="4.58"
                />
                <rect
                  style={svgStyle}
                  x="62.99"
                  y="95.59"
                  width="74.02"
                  height="9.48"
                  rx="4.58"
                  ry="4.58"
                />
                <rect
                  style={svgStyle}
                  x="62.99"
                  y="114.54"
                  width="46"
                  height="9.48"
                  rx="4.58"
                  ry="4.58"
                />
              </svg>
              <p className="titulo">Análisis de textos</p>
            </div>
            <p className="bajada">
              Chequeabot detecta automáticamente frases chequeables en cualquier
              discurso, artículo o contenido.
            </p>
            <div>
              <svg
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 200 200"
                width="28"
                height="28"
              >
                <ellipse
                  className="cls-1"
                  cx="140.49"
                  cy="114.16"
                  rx="6.7"
                  ry="6.77"
                />
                <path
                  style={svgStyle}
                  className="cls-1"
                  d="M142.49,98.24c2.3,0,4.17-1.87,4.17-4.17v-47.53c0-2.3-1.87-4.17-4.17-4.17s-4.17,1.87-4.17,4.17v4.43h-36.63c-16.96,0-30.75,13.8-30.75,30.75v62.39l-1.29-.38c-5.94-1.77-11.81-4.32-17.47-7.59-2.15-1.24-4.91-.5-6.15,1.65-1.24,2.15-.5,4.91,1.65,6.15,13.67,7.9,28.08,11.9,42.82,11.9,24.96,0,42.51-11.57,43.25-12.06,1-.67,1.68-1.69,1.91-2.87.23-1.18,0-2.38-.68-3.38-1.38-2.06-4.18-2.61-6.24-1.24-.74.49-7.55,4.89-18.47,7.86l-1.26.34v-53.98c0-.73.59-1.32,1.32-1.32h27.98v4.87c0,2.3,1.87,4.17,4.17,4.17ZM110.34,80.2c-5.69,0-10.32,4.63-10.32,10.32v55.69l-.88.1c-2.84.33-5.69.5-8.53.5-3.28,0-6.56-.22-9.8-.66l-.87-.12v-64.31c0-11.99,9.76-21.75,21.75-21.75h36.63v20.22h-27.98Z"
                />
              </svg>
              <p className="titulo">Periscopio</p>
            </div>
            <p className="bajada">
              Chequeabot te permite conocer qué circula en múltiples redes
              sociales al mismo tiempo para facilitar el proceso de monitoreo de
              desinformación.
            </p>
            <div>
              <svg
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 200 200"
                width="28"
                height="28"
              >
                <path
                  style={svgStyle}
                  d="m160.19,74.28v-.32c0-2.53-2.05-4.58-4.58-4.58h-22.95c-2.14-3.61-4.76-6.98-7.85-10.01-9.46-9.26-21.96-14.35-35.2-14.35s-25.74,5.1-35.2,14.36c-9.43,9.28-14.62,21.62-14.62,34.75s5.19,25.47,14.62,34.75c18.36,17.96,46.94,19.26,66.83,3.18l21.95,21.54c.91.9,2.12,1.39,3.4,1.39h.05c1.29-.01,2.51-.53,3.42-1.45,1.87-1.91,1.84-4.99-.06-6.86l-21.84-21.43c2.21-2.64,4.11-5.48,5.69-8.48h21.76c2.53,0,4.58-2.05,4.58-4.58v-.32c0-2.53-2.05-4.58-4.58-4.58h-17.98c1.19-4.25,1.82-8.68,1.81-13.16,0-5.27-.85-10.4-2.46-15.27h18.63c2.53,0,4.58-2.05,4.58-4.58Zm-30.45,19.39c.05,4.71-.73,9.3-2.28,13.61h-36.71c-2.53,0-4.58,2.05-4.58,4.58v.32c0,2.53,2.05,4.58,4.58,4.58h31.73c-1.15,1.6-2.42,3.13-3.83,4.56-.21.22-.42.43-.64.64-15.66,15.41-41.13,15.41-56.79,0h0c-7.44-7.27-11.6-16.99-11.72-27.38s3.81-20.21,11.08-27.65c.21-.22.42-.43.64-.64,7.96-7.77,18.18-11.65,28.4-11.65s20.44,3.88,28.4,11.65c1.01.99,1.95,2.02,2.84,3.09h-30.11c-2.53,0-4.58,2.05-4.58,4.58v.32c0,2.53,2.05,4.58,4.58,4.58h35.89c1.97,4.62,3.04,9.64,3.1,14.81Z"
                />
                <rect
                  className="cls-1"
                  x="86.17"
                  y="88.33"
                  width="39.3"
                  height="9.48"
                  rx="4.58"
                  ry="4.58"
                />
              </svg>
              <p className="titulo">¿Qué se chequea hoy?</p>
            </div>
            <p className="bajada">
              Una plataforma para encontrar frases de políticos y figuras
              públicas para chequear.
            </p>
          </Col>
          <Col lg={6} className="descripcion">
            <div>
              <svg
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 200 200"
                width="28"
                height="28"
              >
                <path
                  style={svgStyle}
                  className="cls-1"
                  d="m160.6,109.94v-12.15c0-33.46-27.13-60.59-60.59-60.59h0c-33.46,0-60.59,27.13-60.59,60.59v12.14c-6.31,1.22-11.08,6.77-11.08,13.43v16.91c0,7.56,6.13,13.69,13.69,13.69h2.81c2.52,0,4.55-2.04,4.55-4.55v-34.56h.03v-16.22c0-27.7,21.92-50.9,49.61-51.43,28.34-.54,51.57,22.36,51.57,50.58v41.18h.01v10.44c0,2.52,2.04,4.55,4.55,4.55h2.81c7.56,0,13.69-6.13,13.69-13.69v-16.91c0-6.66-4.76-12.21-11.07-13.43Z"
                />
                <path
                  style={svgStyle}
                  d="m65.27,107.43c-2.86,0-5.17,2.32-5.17,5.17v8.46c0,2.86,2.32,5.17,5.17,5.17s5.17-2.32,5.17-5.17v-8.46c0-2.86-2.32-5.17-5.17-5.17Z"
                />
                <path
                  style={svgStyle}
                  d="m88.42,87.81c-2.86,0-5.17,2.32-5.17,5.17v47.7c0,2.86,2.32,5.17,5.17,5.17s5.17-2.32,5.17-5.17v-47.7c0-2.86-2.32-5.17-5.17-5.17Z"
                />
                <path
                  style={svgStyle}
                  d="m111.58,103.7c-2.86,0-5.17,2.32-5.17,5.17v15.92c0,2.86,2.32,5.17,5.17,5.17s5.17-2.32,5.17-5.17v-15.92c0-2.86-2.32-5.17-5.17-5.17Z"
                />
                <path
                  style={svgStyle}
                  d="m134.73,95.9c-2.86,0-5.17,2.32-5.17,5.17v31.52c0,2.86,2.32,5.17,5.17,5.17s5.17-2.32,5.17-5.17v-31.52c0-2.86-2.32-5.17-5.17-5.17Z"
                />
              </svg>
              <p className="titulo">Desgrabador</p>
            </div>
            <p className="bajada">
              La versión mejorada de nuestro Desgrabador te permite pasar videos
              y audios a texto.
            </p>
            <div>
              <svg
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 200 200"
                width="28"
                height="28"
              >
                <circle
                  className="cls-1"
                  cx="121.66"
                  cy="54.49"
                  r="11.1"
                  style={svgStyle}
                />
                <path
                  style={svgStyle}
                  className="cls-1"
                  d="m149.24,120.28c-3.37-2.34-8.94-9.97-8.94-35.61,0-4.87-.57-9.18-1.57-13.01-.37-2.02-2.13-3.55-4.26-3.55-2.39,0-4.33,1.94-4.33,4.33,0,.54.1,1.05.28,1.53,0,0,0,0,0,0,.79,3.06,1.23,6.52,1.23,10.4,0,11.63,1.07,20.99,3.32,28.2.33,1.03,2.41,7.32,5.54,11.08h.08c1.17,1.47,2.49,2.64,3.81,3.52,1.03.73,1.76,1.91,1.76,3.08,0,2.05-1.61,3.66-3.66,3.66H57.64c-2.05,0-3.66-1.61-3.66-3.66,0-.88.59-2.05,1.61-2.78,8.5-5.86,12.75-20.23,12.75-42.94,0-21.25,13.19-29.46,24.18-32.54l.29-.15s3.08-1.03,7.33-1.03h0s.08,0,.12-.01c2.18-.14,3.91-1.95,3.91-4.17s-1.66-3.96-3.78-4.16c-.1-.01-.2-.02-.31-.02h-.04s-.03,0-.04,0c0,0,0,0-.01,0,0,0-.01,0-.02,0-5.12,0-10.01.99-14.5,2.77,0,0,0,.02,0,.03-11.29,4.4-25.79,14.8-25.79,39.42s-5.57,33.27-8.94,35.61c-3.52,2.34-5.57,6.3-5.57,10.41,0,6.89,5.57,12.46,12.46,12.46h24.48c1.76,9.23,9.97,15.97,19.35,15.97s17.59-6.74,19.35-15.97h21.54c6.89,0,12.46-5.57,12.46-12.46,0-4.1-2.05-8.06-5.57-10.41Zm-47.78,30.04c-3.52,0-6.74-1.76-8.79-4.4h17.59c-2.05,2.64-5.28,4.4-8.79,4.4Z"
                />
              </svg>
              <p className="titulo">Notificaciones Push</p>
            </div>
            <p className="bajada">
              Nuestro servicio de notificaciones te permite llegar a tu
              audiencia con tu contenido más reciente a un costo muy accesible.
            </p>

            <p
              className="bajada"
              style={{
                border: "1px solid white",
                padding: "10px",
                borderRadius: "15px",
                fontSize: "12px",
                wordBreak: "break-word",
                /* box-shadow: 0px 0px 10px 4px transparent; */
              }}
            >
              ¿Querés obtener acceso a alguna de estas herramientas? Escribinos
              a <b>innovacion@chequeado.com</b>
            </p>
          </Col>
        </Row>
      </Row>
    </StyledBackground>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    login: ownProps.login,
  };
};


export default connect(mapStateToProps)(VentaChequeabot);
