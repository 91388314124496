import React, { useEffect, useState } from "react";

// Redux
import { connect } from "react-redux";
import {
    monitoreoToggleForm, postMonitoreoAsociar,
    monitoreoCreateNewcard, resetRegistroStatus,
    monitoreoSetLoadPublicationCardSelectedID, postMonitoreoNuevoEvento
} from "src/store";

// Bootstrap and styles
import { Row, Col, Form } from "react-bootstrap";
import { StyledForm, StyledSidePanelBackground, StyledSidePanel } from "../styled";
import "../style.css"
import { MdClose } from "react-icons/md";

// Components
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { event } from "jquery";
import FormErrorMessage from "../FormErrorMessage";

const FormCreateCard = (props) => {

    const [creatingCard, setCreatingCard] = useState(false);

    // Form handling
    const defaultInputs = { topics: [], subtopics: {} };

    const [inputs, setInputs] = useState(defaultInputs);
    const [formMessages, setFormMessages] = useState({});

    // When normal fields change, update Inputs state
    function handleFormChange(event) {
        var target_name = event.target.name;
        var value = event.target.value;

        if (event.target.type == "checkbox")
            value = event.target.checked; 

        setInputs(values => ({ ...values, [target_name]: value }))
    }

    // For select fields we run a special version,
    // as the name comes in a separate parameter
    function handleSelectChange(event, action) {
        var target_name = action.name;
        var value = event;
        setInputs(values => ({ ...values, [target_name]: value }))        
        
        if (target_name == "topics"){
            // Check that all subtopics that don't have a topic are deleted
            let subtopics = { ...inputs.subtopics }; //create a new copy
            for (var subtopic in subtopics){
                if (subtopic != inputs.topics[0]?.id && subtopic != inputs.topics[1]?.id){
                    delete subtopics[subtopic];
                }    
            }

            // Update subtopics
            setInputs(values => ({ ...values, ["subtopics"]: subtopics }));
        }
    }

    // Special version for subtopics
    function handleSubtopicsSelectChange(event, action) {
        var topic_id = action.name;

        // Create new "bar" object, cloning existing bar into new bar 
        // and updating test key with value "123"
        let subtopics = { ...inputs.subtopics }; //create a new copy
        subtopics[topic_id] = event;
        setInputs(values => ({ ...values, ["subtopics"]: subtopics }))
    }

    // Handling topics and subtopics, to show subtopics only when proper topic
    const [selectedTopic1, setSelectedTopic1] = React.useState();
    const [selectedTopic2, setSelectedTopic2] = React.useState();

    // According to the selected topic, 
    // render corresponding subtopic select
    useEffect(() => {

        if (inputs.topics) {
            setSelectedTopic1(null);
            setSelectedTopic2(null);

            if (inputs.topics.length >= 1)
                setSelectedTopic1(inputs.topics[0]);

            if (inputs.topics.length === 2)
                setSelectedTopic2(inputs.topics[1]);         
           
        }
    }, [inputs.topics])

    // Subtopics dinamic component
    function SubtopicsSelect(topic) {
        if (topic !== null && topic !== undefined) {
            // Create empty list if it doesn't exist in inputs
            if (!inputs.subtopics[topic.id]) inputs.subtopics[topic.id] = [];
            
            return (
                <div className="subtopics-select">
                    <Form.Label>Subtemas - {topic.name}<span className="mandatory-indicator"> *</span></Form.Label>
                    <Select
                        className="select-container"
                        classNamePrefix="select-container"
                        placeholder="Seleccioná 1 o 2 submtemas"
                        options={props.availableSubtopics[topic.id]}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        name={topic.id}
                        value={inputs.subtopics[topic.id] || []}
                        onChange={handleSubtopicsSelectChange}
                        isOptionDisabled={() => inputs.subtopics[topic.id]?.length >= 2}
                        isMulti
                        maxMenuHeight="200px"
                    />
                </div>
            );

        }

        return null;
    }

    // Close this panel
    const closeForm = () => {
        props.toggleForm(false, props.id, 0);
    }

    // This function checks if all inputs are correct and form is valid
    function handleValidation() {
        let errors = {};
        let formIsValid = true;

        // Title
        if (!inputs["title"] || inputs["title"].length === 0) {
            formIsValid = false;
            errors["title"] = "El Título es obligatorio.";
        }

        // Topics and subtopics
        if (!inputs["topics"]) {
            formIsValid = false;
            errors["topics"] = "Selecciona al menos un Tema.";
        } else if (inputs["topics"].length > 2) {
            formIsValid = false;
            errors["topics"] = "El máximo es de dos temas.";
        } else if (inputs["topics"].length === 0) {
            formIsValid = false;
            errors["topics"] = "Selecciona al menos un Tema.";
        } else {
            for (var topic in inputs["subtopics"]) {
                var subtopic = inputs["subtopics"][topic];
                if (!subtopic || subtopic.length === 0) {
                    formIsValid = false;
                    errors["subtopics"] = "Selecciona al menos un Subtema para cada Tema";
                }
            }
        }
        setFormMessages(errors);

        return formIsValid;
    }

    const savePublicationToNewCard = async () => {
        // If we are not creating new prop
        if (props.id === "create_card") {
            //await props.postMonitoreoAsociar({ cards: [props.newCardId] }, props.idOfPublicationToSave);
            
            setCreatingCard(true);
            
            // TODO: refactor this
            const contentToUpdate = props.monitoreo.results.find(
                (content) => content.id === props.idOfPublicationToSave
            );

            if (contentToUpdate) {
                const existingCards = (contentToUpdate.cards || []).map(
                    (card) => card.id
                );
                const updatedCards = [...existingCards, props.newCardId];

                await props.postMonitoreoAsociar(
                    { cards: updatedCards },
                    props.idOfPublicationToSave
                );
            } else {
                console.error("No se encontró el contenido con el ID especificado");
            }
        } else if (props.id === "create_card_for_new_publication") {
            // Go to Upload page for Publication, setting this new card ID in Store
            await props.setLoadPublicationCardSelectedID(props.newCardId);
            setCreatingCard(false);
            props.toggleForm(true, 'create_publication', -1);
        }
    }
        
    // This triggers after a correct register
    useEffect(() => {
        if (props.registro_status === "success" && creatingCard) {
            setTimeout(function () {
                setCreatingCard(false);
                props.resetRegistroStatus();
                closeForm();
            }, 1200);
        }
    }, [props.registro_status]);

    // When a new Card is created, this variable changes
    // If it was this form that was doing the creation, then we need
    // to asociate the card to the corresponding publication
    useEffect(() => {
        if (creatingCard) {
            savePublicationToNewCard();
        }
    }, [props.newCardId]);


    // Create new card if form is ok
    const handleCardCreation = (e) => {
        // Prevent the browser from reloading the page
        e.preventDefault();

        if (handleValidation()) {

            // For subtopics, we need to submit a list of subtopics ID 
            // we don't care about the topic, the back handles that connection
            const subtopics_ids = [];
            for (var topic in inputs.subtopics) {
                var subtopics = inputs.subtopics[topic];
                for (var subtopic in subtopics) {
                    subtopics_ids.push(subtopics[subtopic].id);
                }
            }

            let newCard = {
                name: inputs.title,
                description: inputs.description,
                event: inputs.event ? inputs.event.id : null,
                subtopics: subtopics_ids,
                extra_info : inputs.extra_info,
                is_public_discourse_or_phrase: inputs.is_public_discourse_or_phrase,
                refers_to_another_country: inputs.refers_to_another_country,
            }

            // Create new card
            setCreatingCard(true);
            props.monitoreoCreateNewcard(newCard);
            setInputs(defaultInputs);
        } else {
            // alert("Invalid form!");
            // console.log(formMessages);
        }
    }

    const handleCreateEvent = (inputValue) => {
        props.postMonitoreoNuevoEvento(inputValue);
    };

    useEffect(() => {
        setInputs({ ...inputs, event: props.newEvent });
    }, [props.newEvent])

    // If the boolean openForm is true and we are the ID of the 
    // openFormId then show, if not, hide
    const showForm = () => {
        return (props.openForm && props.openFormId === props.id);
    }

    return (
        <StyledSidePanel className={showForm() ? 'show-form' : 'hide'}>
            <StyledSidePanelBackground className={creatingCard ? 'loading' : ''}>
                <Row>
                    <Col lg={10}>
                        <h1>{props.cardTitle}</h1>
                        <p className="mandatory-indicator">* Campos obligatorios</p>
                        <p> {props.cardDescription} </p>
                    </Col>
                    <Col md={2}>
                        <MdClose className="close-icon" onClick={closeForm} />
                    </Col>
                </Row>
                <StyledForm>
                    <Row>
                        <Form onSubmit={handleCardCreation}>
                            <Form.Group
                                controlId="createNewTarjetaForm.ControlInput1"
                            >
                                <Form.Label>Título <span className="mandatory-indicator">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Título descriptivo para la nueva Tarjeta"
                                    className="text-field"
                                    name="title"
                                    value={inputs.title || ""}
                                    onChange={handleFormChange}
                                />
                                <FormErrorMessage message={formMessages.title} />

                                <Form.Label>Descripción</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Descripción del contenido de la tarjeta. Puede incluir el copy de la publicación"
                                    className="text-field"
                                    name="description"
                                    value={inputs.description || ""}
                                    onChange={handleFormChange}
                                />
                                <Form.Label>Tema <span className="mandatory-indicator">*</span></Form.Label>
                                <Select id="tema-select"
                                    className="select-container"
                                    classNamePrefix="select-container"
                                    placeholder="Seleccioná 1 o 2 temas"
                                    options={props.availableTopics}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    isMulti
                                    isOptionDisabled={() => inputs.topics.length >= 2}
                                    name="topics"
                                    value={inputs.topics || []}
                                    onChange={handleSelectChange}
                                    maxMenuHeight="200px"
                                />
                                <FormErrorMessage message={formMessages.topics} />
                                {SubtopicsSelect(selectedTopic1)}
                                {SubtopicsSelect(selectedTopic2)}
                                <FormErrorMessage message={formMessages.subtopics} />

                                <div key="inline-checkbox" className="my-3">
                                    <Form.Check
                                        inline
                                        label="Corresponde a otro/s país/es"
                                        name="refers_to_another_country"
                                        type="checkbox"
                                        id="inline-checkbox-1"
                                        checked={inputs.refers_to_another_country || false}
                                        onChange={handleFormChange}
                                    />
                                    <Form.Check
                                        inline
                                        label="Es discurso público o frase"
                                        name="is_public_discourse_or_phrase"
                                        type="checkbox"
                                        id="inline-checkbox-2"
                                        checked={inputs.is_public_discourse_or_phrase || false}
                                        onChange={handleFormChange}
                                    />
                                </div>

                                <Form.Label id="event-label">Evento</Form.Label>
                                <CreatableSelect id="event-select"
                                    onCreateOption={handleCreateEvent}
                                    formatCreateLabel={userInput => `Crear evento "${userInput}"`}
                                    className="select-container"
                                    classNamePrefix="select-container"
                                    placeholder="Seleccioná un event o crea uno nuevo"
                                    options={props.availableEvents}
                                    name="event"
                                    value={inputs.event || null}
                                    onChange={handleSelectChange}
                                    maxMenuHeight="200px"
                                    isClearable
                                />

                                <Form.Label>Información extra</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Observaciones, links de referencia, fuentes, expertos o cualquier otra información que sirva para profundizar sobre esta tema"
                                    className="text-field"
                                    name="extra_info"
                                    value={inputs.extra_info || ""}
                                    onChange={handleFormChange}
                                />
                            </Form.Group>
                            <button type="submit" className="primario">
                                Crear nueva tarjeta
                            </button>
                        </Form>
                    </Row>
                </StyledForm>
            </StyledSidePanelBackground>
        </StyledSidePanel>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        monitoreo: state.monitoreo,
        openForm: state.monitoreo__current_form_is_open,
        openFormId: state.monitoreo__current_form_id,
        idOfPublicationToSave: state.monitoreo__current_form_publication_id,
        registro_status: state.registro_status,
        newCardId: state.nuevo_registro,
        availableTopics: state.monitoreo__topics,
        availableSubtopics: state.monitoreo__subtopics,
        availableEvents: state.monitoreo__events,
        newEvent: state.monitoreo__new_event,
        cardTitle: ownProps.cardTitle,
        cardDescription: ownProps.cardDescription,
        id: ownProps.id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleForm: (openForm, openFormId, publication_id) => dispatch(monitoreoToggleForm(openForm, openFormId, publication_id)),
        postMonitoreoAsociar: (cards, publication_id) =>
            dispatch(postMonitoreoAsociar(cards, publication_id)),
        monitoreoCreateNewcard: (newCardContent) =>
            dispatch(monitoreoCreateNewcard(newCardContent)),
        resetRegistroStatus: () => dispatch(resetRegistroStatus()),
        setLoadPublicationCardSelectedID: (cardSelectId) =>
            dispatch(monitoreoSetLoadPublicationCardSelectedID(cardSelectId)),
        postMonitoreoNuevoEvento: (nuevoEvento) => dispatch(postMonitoreoNuevoEvento(nuevoEvento))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormCreateCard);
