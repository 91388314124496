import styled from "styled-components";
import Col from "react-bootstrap/Col";

export const StyledUsuarios = styled(Col)`
  h2 span {
    color: #354052;
    font-size: 20px;
    line-height: 24px;
  }
  .h3 {
    font-size: 18px;
    line-height: 20px;
    color: #354052;
    margin-top: 35px;
    margin-bottom: 11px;
  }
  .title {
    font-size: 16px;
    line-height: 20px;
    color: #354052;
  }
  .tablecontainer {
    &::-webkit-scrollbar {
      width: 3px;
    }
    
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background:#cac5c5;
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background:#cac5c5;
    }
    table th {
      font-size: 17px;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 11px;
      background: #354052;
      color: white;
      text-align: center;
    }
    table tr {
      font-size: 12px;
    }
  }

  form {
    margin-top: 80px;
    background: #e6ecf5;
    padding-top: 40px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 40px;

    label {
      font-size: 16px;
      line-height: 20px;
      color: #354052;
    }

    button {
      background: #354052;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      padding: 13px 22px;
      border: 1px solid #354052;
      &:hover {
        background: #354052;
      }
    }
  }
`;
