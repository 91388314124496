import React, { useState } from "react";
/* Bootstrap Components */
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { StyledWrapperAgenda } from "./styled";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import HistorialPush from "src/pages/Push/HistorialPush";
import { getData } from "src/store";

function CamposPush(props) {
  const [titulo, setTitulo] = useState("");
  const [bajada, setBajada] = useState("");
  const [imagen, setImagen] = useState("");
  const [nota, setNota] = useState("");
  const [message, setMessage] = useState(false);
  const history = useHistory();

  const handleForm = (e) => {
    e.preventDefault();
    props.getData({
      click_action: nota,
      body: bajada,
      title: titulo,
      image: imagen,
    });
    setTitulo("");
    setBajada("");
    setImagen("");
    setNota("");
    setMessage(true);
  };

  const handleTitulo = (e) => {
    e.preventDefault();
    setTitulo(e.target.value);
  };

  const handleBajada = (e) => {
    e.preventDefault();
    setBajada(e.target.value);
  };

  const handleImagen = (e) => {
    e.preventDefault();
    setImagen(e.target.value);
  };

  const handleNota = (e) => {
    e.preventDefault();
    setNota(e.target.value);
  };

  const clearForm = (e) => {
    setTitulo("");
    setBajada("");
    setImagen("");
    setNota("");
    setMessage(false);
  };


  return (
    <Row id="agenda" style={{ paddingLeft: "50px", marginTop: "40px" }}>
      <StyledWrapperAgenda lg={6}>
        <Form onSubmit={handleForm}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label
              as={Col}
              xs={12}
              sm={12}
              md={4}
              lg={5}
              xl="5"
              htmlFor="formBasicEmail"
            >
              <h1>Titulo</h1>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="ingresa titulo"
              value={titulo}
              id="titulo"
              onChange={handleTitulo}
              required
              htmlFor="formTitulo"
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicEmail"
            style={{ marginTop: "35px" }}
          >
            <Form.Label as={Col} xs={12} sm={12} md={4} lg={5} xl="5">
              <h1>Bajada</h1>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="ingresa bajada"
              value={bajada}
              onChange={handleBajada}
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicEmail"
            style={{ marginTop: "35px" }}
          >
            <Form.Label
              as={Col}
              xs={12}
              sm={12}
              md={4}
              lg={5}
              xl="5"
              htmlFor="formBasicEmail"
            >
              <h1>Imagen (url)</h1>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="ingresa url de imagen"
              value={imagen}
              onChange={handleImagen}
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicEmail"
            style={{ marginTop: "35px" }}
          >
            <Form.Label as={Col} xs={12} sm={12} md={4} lg={5} xl="5">
              <h1>Nota (url)</h1>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="ingresa nota"
              value={nota}
              onChange={handleNota}
              required
            />
          </Form.Group>

          <div className="wrapperbuttons">
            <button variant="primary" type="submit" className="enter">
              Enviar
            </button>
            <button
              type="reset"
              variant="primary"
              onClick={clearForm}
              className="borrar"
            >
              Borrar
            </button>
          </div>
        </Form>
         {props.data === "sucess" && message ? (
            <p style={{ marginTop: "25px", color: "green" }}>
              La información fue enviada con éxito.
            </p>
          ) : props.data === "error" ? (
            <p style={{ marginTop: "25px", color: "red" }}>
              Se produjo un error. Intenté nuevamente.
            </p>
          ) : null}
      </StyledWrapperAgenda>

      <Col lg={6}>
        <div
          style={{
            height: "480px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {nota.length > 0 ||
          titulo.length > 0 ||
          imagen.length > 0 ||
          bajada.length > 0 ? (
            <Row>
              <StyledWrapperAgenda
                xs={12}
                sm={12}
                lg={8}
                xl="8"
                md={{ span: 3, offset: 3 }}
              >
                <h1>Previsualización web</h1>

                {titulo !== "" ? (
                  <div>
                    {imagen !== "" ? (
                      <img
                        src={imagen}
                        className="img-responsive"
                        style={{ maxWidth: "100%" }}
                        alt="Preview"
                      />
                    ) : null}
                    <div
                      style={{
                        padding: "14px",
                        background: "#535151",
                      }}
                    >
                      {titulo !== "" ? (
                        <p
                          style={{
                            color: "white",
                            marginBottom: "5px",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          {titulo}
                        </p>
                      ) : null}
                      {titulo !== "" ? (
                        <p style={{ color: "white", marginBottom: "8px" }}>
                          {bajada}
                        </p>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </StyledWrapperAgenda>
            </Row>
          ) : null}
        </div>
      </Col>
      <HistorialPush />
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    data: state.data,
    loading: state.loading,
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData: (arg) => dispatch(getData(arg)),
   /*  getLogin: (status) => dispatch(getLogin(status)), */
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CamposPush);
