import React from "react";

export default function Logo({ fillColor, backgroundColor }) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        width="24"
        height="24"
        fill={fillColor}
      >
        <circle cx="100" cy="100" r="100" fill={backgroundColor} />
        <path d="m160.19,74.28v-.32c0-2.53-2.05-4.58-4.58-4.58h-22.95c-2.14-3.61-4.76-6.98-7.85-10.01-9.46-9.26-21.96-14.35-35.2-14.35s-25.74,5.1-35.2,14.36c-9.43,9.28-14.62,21.62-14.62,34.75s5.19,25.47,14.62,34.75c18.36,17.96,46.94,19.26,66.83,3.18l21.95,21.54c.91.9,2.12,1.39,3.4,1.39h.05c1.29-.01,2.51-.53,3.42-1.45,1.87-1.91,1.84-4.99-.06-6.86l-21.84-21.43c2.21-2.64,4.11-5.48,5.69-8.48h21.76c2.53,0,4.58-2.05,4.58-4.58v-.32c0-2.53-2.05-4.58-4.58-4.58h-17.98c1.19-4.25,1.82-8.68,1.81-13.16,0-5.27-.85-10.4-2.46-15.27h18.63c2.53,0,4.58-2.05,4.58-4.58Zm-30.45,19.39c.05,4.71-.73,9.3-2.28,13.61h-36.71c-2.53,0-4.58,2.05-4.58,4.58v.32c0,2.53,2.05,4.58,4.58,4.58h31.73c-1.15,1.6-2.42,3.13-3.83,4.56-.21.22-.42.43-.64.64-15.66,15.41-41.13,15.41-56.79,0h0c-7.44-7.27-11.6-16.99-11.72-27.38s3.81-20.21,11.08-27.65c.21-.22.42-.43.64-.64,7.96-7.77,18.18-11.65,28.4-11.65s20.44,3.88,28.4,11.65c1.01.99,1.95,2.02,2.84,3.09h-30.11c-2.53,0-4.58,2.05-4.58,4.58v.32c0,2.53,2.05,4.58,4.58,4.58h35.89c1.97,4.62,3.04,9.64,3.1,14.81Z" />
        <rect
            className="cls-1"
            x="86.17"
            y="88.33"
            width="39.3"
            height="9.48"
            rx="4.58"
            ry="4.58"
        />
        </svg>
    );
  }
